import cx from 'classnames';

import useKeyboardEvent from 'hooks/useKeyboardEvent.js';

import React from 'react';
import { normalizeContext } from 'util/contextUtils';

import { observer } from 'mobx-react';
import CommentInput from 'components/CommentInput';

const CauzeUpdateModal = ({
  onToggleClose,
  isOpen,
  commentProps,
  profileStore,
  uiStore,
  eventStore,
}) => {
  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });

  return (
    <>
      <div
        className={cx('modal share-modal', {
          'is-active': isOpen,
        })}
      >
        <div
          className="modal-background"
          onClick={() => {
            onToggleClose(false);
          }}
        />
        <div className="modal-card ">
          <section className="modal-card-body">
            <div className="share-link-x-container flex-row flex-justify-end">
              <button
                className="delete"
                aria-label="close"
                onClick={onToggleClose}
              />
            </div>
            <div>
              {(commentProps.event.currentEntityIsHost ||
                commentProps.isRecipientCharity) && (
                <div className="w-full">
                  <CommentInput
                    avatar={profileStore.data.avatar}
                    store={eventStore}
                    userContext={normalizeContext(commentProps.userContext)}
                    uiStore={uiStore}
                    onToggleClose={onToggleClose}
                    eventId={commentProps.event?.id}
                    purchaseId={commentProps.purchaseId}
                  />
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default observer(CauzeUpdateModal);
