import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import Currency from 'components/Currency/Currency';

const MatchStatus = ({ value, original, className }) => {
  let displayString, displayClass;
  if (value === 'live') {
    displayString = 'Live';
    displayClass = 'active';
  }

  if (value === 'ended') {
    displayString = 'Ended';
    displayClass = 'ended';
  }

  if (value === 'future') {
    displayString = moment(original.startDate).format('M/D/Y');
    displayClass = 'future';
  }

  if (value === 'disabled') {
    displayString = 'Disabled';
    displayClass = 'disabled';
  }

  return (
    <div className={`${className} flex-column flex-align-center`}>
      <div className={classnames(`match-status status-${displayClass}`)}>
        <span>{displayString}</span>
      </div>
      {original.startDate && original.endDate && (
        <div className="flex-row match-dates">
          <div className="match-expanded-text text-light">
            {moment(original.startDate).format('M/D/Y')} -{' '}
            {moment(original.endDate).format('M/D/Y')}
          </div>
        </div>
      )}
    </div>
  );
};

const renderCharityNames = (charityNames) =>
  charityNames.map((charityName, index) => {
    if (index === charityNames.length - 1) {
      return charityName;
    } else {
      return `${charityName}, `;
    }
  });

const MatchName = ({ value, original, isExpanded }) => (
  <div className="flex-row col-user-container">
    <div className="flex-column flex-expand min-width-reset">
      <div className="flex-row">
        <div className="flex-column flex-expand">
          <div className="match-name">{value}</div>
          <div className="flex-row match-dates">
            <div className="match-expanded-text text-light flex-row">
              {renderCharityNames(original.charityNames)}
            </div>
          </div>
          <MatchStatus
            className="is-hidden-desktop is-hidden-tablet-only"
            value={original.matchStatus}
            original={original}
          />
        </div>
      </div>
      {isExpanded && (
        <div className="is-hidden-desktop is-expanded flex-column">
          <div className="flex-column">
            <div className="match-expanded-text flex-expand">
              Eligible Charities:
            </div>
            <div className="match-expanded-text flex-expand expanded-charities">
              <div className="text-light">
                {original.charityNames.map((charity, index) => (
                  <em
                    key={`charity-${index}`}
                    className="eligible-charity-name"
                  >
                    {charity}
                  </em>
                ))}
              </div>
            </div>
          </div>
          <div className="flex-row flex-expand">
            <div className="match-expanded-text flex-expand">
              Eligible Givers:
            </div>
            <div className="match-expanded-text flex-justify-end">
              <span>
                {original.matchType === 'EVENT' ? 'Anyone' : 'Employees'}
              </span>
            </div>
          </div>
          <div className="flex-row flex-expand">
            <div className="match-expanded-text flex-expand">
              Max Per Giver:
            </div>
            <div className="match-expanded-text flex-justify-end">
              <span>
                <Currency amount={original.userMatchLimit} />
              </span>
            </div>
          </div>
          <div className="flex-row flex-expand">
            <div className="match-expanded-text flex-expand">
              Max Total Amount:
            </div>
            <div className="match-expanded-text flex-justify-end">
              <span>
                <Currency amount={original.matchTotal} />
              </span>
            </div>
          </div>
          {original.startDate && (
            <div className="flex-row flex-expand">
              <div className="match-expanded-text flex-expand">Start Date:</div>
              <div className="match-expanded-text flex-justify-end">
                <span>{moment(original.startDate).format('M/D/Y')}</span>
              </div>
            </div>
          )}
          {original.endDate && (
            <div className="flex-row flex-expand">
              <div className="match-expanded-text flex-expand">End Date:</div>
              <div className="match-expanded-text flex-justify-end">
                <span>{moment(original.endDate).format('M/D/Y')}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  </div>
);

export { MatchStatus, MatchName };
