import { useState, useEffect } from 'react';

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(document.body.clientWidth <= 460);
  const [isTablet, setIsTablet] = useState(document.body.clientWidth <= 768);
  const [isLaptop, setIsLaptop] = useState(document.body.clientWidth <= 1024);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 460 && !isMobile) {
        setIsMobile(true);
      } else if (window.innerWidth > 460 && isMobile) {
        setIsMobile(false);
      }

      if (window.innerWidth <= 768 && !isTablet) {
        setIsTablet(true);
      } else if (window.innerWidth > 768 && isTablet) {
        setIsTablet(false);
      }

      if (window.innerWidth <= 1024 && !isLaptop) {
        setIsLaptop(true);
      } else if (window.innerWidth > 1024 && isLaptop) {
        setIsLaptop(false);
      }
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile, isTablet, isLaptop]);

  return {
    isMobile,
    isTablet,
    isLaptop,
  };
};

export default useMobile;
