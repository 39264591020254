import React, { useRef, useState } from 'react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { navigate } from '@reach/router';
import 'swiper/css';
import 'swiper/css/navigation';

import TopicPill from 'components/TopicPill/TopicPill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import useMobile from 'hooks/useMobile';

const TopicListItem = ({ topic, loading, isSelected }) => {
  return (
    <TopicPill
      isSelected={isSelected}
      topic={topic}
      loading={loading}
      onClick={() => {
        if (loading) return;

        let _topic = topic;

        if (isSelected) {
          _topic = '';
        }

        navigate('/donate/search', { state: { topic: _topic } });
      }}
    />
  );
};

const TopicList = ({
  topics,
  activeTopics = [],
  loading,
  title,
  className = '',
  titleClasses = '',
}) => {
  // Handle slide change to hide/show arrows
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleSlideChange = (swiper) => {
    setIsAtBeginning(swiper.isBeginning);

    setIsAtEnd(swiper.isEnd);
  };
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);
  const { isMobile } = useMobile();
  return (
    <>
      <div
        className={`flex justify-between items-center font-agenda-bold text-[22px] leading-none mt-3  mb-5 ${className}`}
      >
        <h2
          className={cx(titleClasses, {
            'text-[22px] leading-none': !titleClasses,
          })}
        >
          {title}
        </h2>
        {swiperRef && swiperRef.allowSlideNext && !isMobile && (
          <div className="flex gap-2 items-center justify-center">
            {/* Custom Navigation Buttons */}
            <div
              ref={prevRef}
              onClick={() => swiperRef.slidePrev()}
              className={`py-1.5 px-2.5  flex items-center justify-center border border-solid border-[#adadb6] cursor-pointer rounded-full  swiper-arrows-above-button-prev ${
                isAtBeginning ? '!cursor-default opacity-40' : ''
              }`}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                size={'xs'}
                style={{ color: '#2a2a33', transform: 'translateX(-1px)' }}
              />
            </div>
            <div
              ref={nextRef}
              onClick={() => {
                swiperRef.slideNext();
              }}
              className={`py-1.5 px-2.5  flex items-center justify-center border border-solid border-[#adadb6] cursor-pointer rounded-full  swiper-arrows-above-button-next ${
                isAtEnd ? '!cursor-default opacity-40' : ''
              }`}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                size={'xs'}
                style={{ color: '#2a2a33', transform: 'translateX(1px)' }}
              />
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <div className="flex gap-x-2">
          {['', '', '', ''].map((topic) => (
            <TopicListItem key={topic} loading />
          ))}
        </div>
      ) : (
        <Swiper
          className={'event-list'}
          modules={[Navigation]}
          spaceBetween={8}
          slidesPerView={'auto'}
          onSlideChange={handleSlideChange}
          navigation={{
            prevEl: 'swiper-arrows-above-button-prev',
            nextEl: 'swiper-arrows-above-button-next',
          }}
          onInit={(swiper) => {
            setSwiperRef(swiper);
            swiper.prevEl = prevRef.current;
            swiper.nextEl = nextRef.current;
            swiper.navigation.update();
          }}
          onSwiper={setSwiperRef}
        >
          {topics.map((topic) => (
            <SwiperSlide
              className="my-auto"
              key={topic}
              style={{ width: 'auto' }}
            >
              <TopicListItem
                topic={topic}
                isSelected={activeTopics.includes(topic)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

export default TopicList;
