import { useMemo, useRef, useState } from 'react';
import { navigate } from '@reach/router';

import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import pinDrop from 'assets/images/icons/pin/pin-drop.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import { CharityListItem } from 'components/CharityList/CharityList';
import useMobile from 'hooks/useMobile';

export const PopularNonprofitsSection = ({
  parsedLocationString,
  user,
  userLoading,
  localEntities,
  localLoading,
  onApply,
}) => {
  const locationDisplay = parsedLocationString || user?.zip;
  const [zip, setZip] = useState('');
  const { isMobile } = useMobile();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleSlideChange = (swiper) => {
    setIsAtBeginning(swiper.isBeginning);

    setIsAtEnd(swiper.isEnd);
  };
  const selected = [];
  const renderContent = useMemo(() => {
    if (userLoading && !user?.id) {
      return <CauzeSpinner />;
    }

    if (localLoading) {
      return (
        <div className="flex gap-x-2">
          <div className="h-[170px] w-[160px] rounded-[10px] bg-lightgray-e8e animate-pulse" />
          <div className="h-[170px] w-[160px] rounded-[10px] bg-lightgray-e8e animate-pulse" />
          <div className="h-[170px] w-[160px] rounded-[10px] bg-lightgray-e8e animate-pulse" />
          <div className="h-[170px] w-[160px] rounded-[10px] bg-lightgray-e8e animate-pulse" />
        </div>
      );
    }

    if (!user?.zip) {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onApply?.(zip);
            return false;
          }}
          className="flex flex-col gap-y-3 bg-lightgray-f7f rounded-[0.625rem] px-8 pt-4 pb-8 text-center font-agenda"
        >
          <img
            src={pinDrop}
            className="w-12 mx-auto rounded-full bg-white p-2"
          />
          <div className="font-agenda-bold text-[26px]">
            Where in the world are you?
          </div>
          <div className="text-lg">
            Find local cauzes, and help your friends find you.
          </div>
          <input
            className="p-2 rounded-[10px] border border-lightgray-e0e border-solid"
            placeholder="Enter Zip"
            value={zip}
            onChange={(e) => {
              setZip(e.target.value);
            }}
          />
          <button className="cz-btn-brand mt-8" type="submit">
            Apply
          </button>
        </form>
      );
    }

    return (
      <div className="discover-charity-list">
        <Swiper
          className={'charity-list'}
          modules={[Navigation]}
          spaceBetween={8}
          slidesPerView={isMobile ? 2.1 : 3.5}
          onSlideChange={handleSlideChange}
          navigation={{
            prevEl: 'swiper-arrows-above-button-prev',
            nextEl: 'swiper-arrows-above-button-next',
          }}
          onInit={(swiper) => {
            setSwiperRef(swiper);
            swiper.prevEl = prevRef.current;
            swiper.nextEl = nextRef.current;
            swiper.navigation.update();
          }}
          onSwiper={setSwiperRef}
        >
          {localEntities.map((charity) => (
            <SwiperSlide key={charity.id}>
              <CharityListItem
                charity={charity}
                onSelect={(charityId) => {
                  navigate(`/charity/${charityId}`);
                }}
                isSelected={selected.includes(charity.id)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }, [localEntities, userLoading, user, localLoading, zip]);

  if (!user) return <></>;

  return (
    <>
      <div className="text-[22px] leading-none mt-8 mb-5 flex justify-between">
        <div className="flex flex-col lg:flex-row items-center gap-x-1">
          <span className="font-agenda-bold">Popular Local Nonprofits </span>
          {Boolean(locationDisplay) && (
            <span className="font-agenda text-left w-full lg:w-auto">
              ({parsedLocationString || user?.zip})
            </span>
          )}
        </div>
        {swiperRef && swiperRef.allowSlideNext && !isMobile && (
          <div className="flex gap-2 items-center justify-center">
            {/* Custom Navigation Buttons */}
            <div
              ref={prevRef}
              onClick={() => swiperRef.slidePrev()}
              className={`py-1.5 px-2.5  flex items-center justify-center border border-solid border-[#adadb6] cursor-pointer rounded-full  swiper-arrows-above-button-prev ${
                isAtBeginning ? '!cursor-default opacity-40' : ''
              }`}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                size={'xs'}
                style={{ color: '#2a2a33', transform: 'translateX(-1px)' }}
              />
            </div>
            <div
              ref={nextRef}
              onClick={() => {
                swiperRef.slideNext();
              }}
              className={`py-1.5 px-2.5  flex items-center justify-center border border-solid border-[#adadb6] cursor-pointer rounded-full  swiper-arrows-above-button-next ${
                isAtEnd ? '!cursor-default opacity-40' : ''
              }`}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                size={'xs'}
                style={{ color: '#2a2a33', transform: 'translateX(1px)' }}
              />
            </div>
          </div>
        )}
      </div>
      {renderContent}
    </>
  );
};
