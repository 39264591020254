import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import stripeOptions from '../../config/stripe';
import useCheckoutStore from 'stores/CheckoutStoreV2';

const ElementsWrapper = ({ children, elementOptions }) => {
  const [stripe, setStripe] = useState();
  const checkoutStore = useCheckoutStore();
  const stripeKey = global.STRIPE_KEY;

  useEffect(() => {
    if (!stripe) {
      setStripe(loadStripe(stripeKey));
    }
  }, []);

  if (!stripe) return <></>;

  return (
    <Elements
      stripe={stripe}
      options={{
        ...stripeOptions.elements,
        mode: 'payment',
        amount: checkoutStore.splitAmount?.total ?? 2500,
        currency: 'usd',
        setupFutureUsage: 'on_session',
        payment_method_types: ['card'],
        appearance: {
          variables: {
            gridRowSpacing: '4px',
          },
          rules: {
            '.Label': {
              fontSize: '0px',
              marginBottom: '0px',
            },
          },
        },
        ...elementOptions,
      }}
    >
      {children}
    </Elements>
  );
};

export default ElementsWrapper;
