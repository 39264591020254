/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';
import { client as apolloClient } from '../util/apolloClient';
import { client as api2Client } from '../util/api2Client';
import moment from 'moment';
import axios from 'axios';

import uiStore from './UiStore';

import config from '../config/';
import { getToken } from '../util/storage';

import {
  companyUsersByIdQuery,
  listCompanyUnredeemedInvitesQuery,
  revokeUserTokensMutation,
  removeCompanyUsersMutation,
  matchListForCompanyQuery,
  resendCompanyUserTokens,
  updateCompanyInvitesMutation,
  sendGiftsMutation,
  listPayrollForCompanyQuery,
  sendGroupDonationReminderMutation,
  updatePayrollRowMutation,
} from '../graphql/companyAdmin';

import {
  updateCompanyMutation,
  companyByIdQuery,
  getCompanyEmployeeCsvExportQuery,
} from '../graphql/company';

import {
  createAnnualMatchMutation,
  editCompanyMatchMutation,
  createDownloadTokenMutation,
} from '../graphql/companyMatching';
import { navigate } from '@reach/router';
import downloadLink from 'util/downloadLink';

const UPLOAD_URL = config.UPLOAD_ROOT;
const PAYROLL_KEY = 'payroll';
const MATCH_TOTAL_DEFAULT = 100000000;
const COMPANY_LOGO_FORM_KEY = `avatar`;
const COMPANY_HERO_FORM_KEY = `hero`;

const matchStatus = (startDate, endDate, active) => {
  const now = moment();

  if (!active) {
    return 'disabled';
  }

  if (startDate) {
    if (moment(now).isBetween(startDate, endDate)) {
      // active
      return 'live';
    } else {
      if (moment(now).isAfter(endDate)) {
        // ended
        return 'ended';
      }
      if (moment(startDate).isAfter(now)) {
        // future
        if (active) return 'live';
        return 'future';
      }
    }
  }

  return 'live';
};

class CompanyAdminStore {
  @observable loading = true;
  @observable isError = false;
  @observable isUploadingCSV = false;
  @observable csvUploaded = false;
  @observable csvUploadError;
  @observable id = '';
  @observable users = [];
  @observable pending = [];
  @observable matchList = [];
  @observable totalUsersBalance = 0;
  @observable companyRemainingMatch;
  @observable companyEligibleMatch;
  @observable companyUserMatchLimit;
  @observable showMatchReminder = false;
  @observable totalDonationCount = 0;
  @observable totalGiftBalance = 0;
  @observable totalGroupDonationCount = 0;
  @observable totalGroupDonationAmount = 0;
  @observable payrollStatusReport = [];
  @observable csvFilename = '';
  @observable data = {};

  @observable bio = '';
  @observable bioTextHasChanged = false;
  @observable companyLogoUrl = '';
  @observable companyLogoFile = {};
  @observable companyLogoHasChanged = false;
  @observable companyHeroUrl = '';
  @observable companyHeroFile = {};
  @observable companyHeroHasChanged = false;
  @observable isUploadingCompanyLogo = false;
  @observable isUploadingCompanyHero = false;
  @observable imageUploadError = false;
  @observable isDownloading = false;

  getInitial = (companyId) => {
    const idPayload = { id: companyId };
    this.getCompany(idPayload);
    this.getUsers(idPayload);
    this.getCompanyMatchDetails(idPayload);
    this.id = companyId;
  };

  exportToCSV = async () => {
    this.isDownloading = true;
    const options = {
      variables: {},
      query: getCompanyEmployeeCsvExportQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const result = await api2Client.query(options);
      const blob = new Blob([result.data.exportEmployeesCsv.csv], {
        type: 'text/csv;charset=utf-8;',
      });
      const url = window.URL.createObjectURL(blob);
      downloadLink(url, `${this.data?.name}_Employees_${Date.now()}.csv`);
    } catch (err) {
      uiStore.showNotification({
        body: 'Unable to download csv. Contact support.',
        type: 'ERROR',
      });
    } finally {
      this.isDownloading = false;
    }
  };

  @action getCompany = async ({ id = null, userContext }) => {
    this.loading = true;
    const options = {
      variables: { id, userContext },
      query: companyByIdQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const result = await apolloClient.query(options);
      this.data = result.data.data;
      this.companyLogoUrl = result.data.data.avatar?.md;
      this.companyHeroUrl = result.data.data.hero?.md;
      this.bio = result.data.data.description;

      this.loading = false;
      this.isError = false;
    } catch (err) {
      this.loading = false;
      this.isError = true;
      this.error = err;
    }
  };

  @action getUsers = async ({ id }) => {
    let usersBalance = 0;
    let donationCount = 0;
    let giftBalances = 0;
    let totalGroupDonationCount = 0;
    let totalGroupDonationAmount = 0;
    this.loading = true;

    const getOptions = (query) => ({
      query: query,
      variables: { id },
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    });

    try {
      const userResult = await apolloClient
        .query(getOptions(companyUsersByIdQuery))
        .then((res) => {
          if (res.data?.companyUsersById) {
            return res.data.companyUsersById.map((user) => {
              usersBalance += user.employerGiftTotal;
              donationCount += user.donationCount;
              giftBalances += user.employerGiftRemaining;
              totalGroupDonationCount += user.groupDonationCount;
              totalGroupDonationAmount += user.groupDonationTotal;

              return {
                ...user,
                id: parseInt(user.id),
                redeemed: true,
                status: 'Account Created',
                giftAmount: user.employerGiftTotal,
                fullName: `${user.firstName} ${user.lastName}`,
              };
            });
          }
          return [];
        });

      const unredeemedResult = await apolloClient
        .query(getOptions(listCompanyUnredeemedInvitesQuery))
        .then((res) => {
          if (res.data?.listCompanyUnredeemedInvites) {
            return res.data.listCompanyUnredeemedInvites
              .filter((user) => !user.revoked)
              .map((user) => ({
                ...user,
                email: user.email,
                username: '',
                fullName: '',
                balance: {
                  total: 0,
                },
                giftAmount:
                  user.gift && user.gift.amount > 0 ? -user.gift.amount : 0,
                firstName: null,
                lastName: null,
                remainingMatch: 0,
                redeemedDate: null,
                eligibleMatch: 0,
                tokenId: user.id,
                id: `unredeemed-${user.id}`,
                invitedDate: user.updatedAt,
                redeemed: false,
                status: user.pending ? 'Pending' : 'Waiting',
              }));
          }
          return [];
        });
      this.users = userResult.concat(
        unredeemedResult.filter((user) => user.status === 'Waiting'),
      );
      this.totalUsersBalance = usersBalance;
      this.totalDonationCount = donationCount;
      this.totalGiftBalance = giftBalances;

      this.totalGroupDonationCount = totalGroupDonationCount;
      this.totalGroupDonationAmount = totalGroupDonationAmount;

      this.pending = unredeemedResult.filter(
        (user) => user.status === 'Pending',
      );
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.isError = true;
      this.error = err;
    }
  };

  @action getCompanyMatchDetails = async ({ id }) => {
    try {
      const companyMatchResult = await apolloClient
        .query({
          query: matchListForCompanyQuery,
          variables: { id },
          fetchPolicy: 'network-only',
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        })
        .then((res) =>
          res.data.matchListForCompany.map((match) => ({
            ...match,
            matchStatus: matchStatus(
              match.startDate,
              match.endDate,
              match.active,
            ),
          })),
        );
      this.matchList = companyMatchResult;

      const annualMatch = companyMatchResult.find(
        (match) =>
          match.active &&
          match.matchType === 'COMPANY' &&
          match.charityNames[0] === 'All',
      );

      if (annualMatch) {
        this.companyEligibleMatch = annualMatch.matchTotal;
        this.companyRemainingMatch = annualMatch.sponsorMatchRemaining;
        this.companyUserMatchLimit = annualMatch.userMatchLimit;
      } else {
        this.showMatchReminder = true;
      }
    } catch (err) {
      this.isError = true;
      console.log(err);
    }
  };

  @action revokeUserTokens = async ({ ids, revokeAll = false }) => {
    try {
      await apolloClient.mutate({
        variables: { ids, revokeAll: revokeAll },
        mutation: revokeUserTokensMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
      this.users = this.users.filter((user) => !ids.includes(user.tokenId));
      this.pending = this.pending.filter((user) => !ids.includes(user.tokenId));
    } catch (err) {
      console.log(err);
    }
  };

  @action removeCompanyUsers = async ({ userIds, companyId }) => {
    try {
      await apolloClient.mutate({
        variables: { userIds, companyId },
        mutation: removeCompanyUsersMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
      this.users = this.users.filter(
        (user) => !userIds.includes(user.id.toString()),
      );
    } catch (err) {
      console.log(err);
    }
  };

  @action sendCompanyGifts = async ({
    userIds,
    comment,
    companyId,
    amount,
  }) => {
    try {
      await apolloClient.mutate({
        variables: {
          amount,
          comment,
          userIds,
          paymentType: 'BALANCE',
          userContext: { companyId },
          giftType: 'EMPLOYEE_GIFT',
          pending: false,
        },
        mutation: sendGiftsMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
    } catch (err) {
      console.log(err);
    }
  };

  @action sendCompanyInvites = async ({
    emails,
    pending,
    comment,
    companyId,
    amount,
  }) => {
    try {
      const res = await apolloClient.mutate({
        variables: {
          emails,
          pending,
          comment,
          amount,
          giftType: 'EMPLOYEE_GIFT',
          paymentType: 'BALANCE',
          UserContext: { companyId },
        },
        mutation: sendGiftsMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
      this.getUsers({ id: companyId });

      if (res.errors) {
        throw res.errors;
      }
    } catch (errs) {
      errs.map(
        (err) =>
          err.message.includes('Insufficient funds') &&
          uiStore.showNotification({
            body: err.message,
            type: 'ERROR',
          }),
      );
      console.log(errs);
    }
  };

  @action sendGroupDonationReminders = async ({ companyId, userIds }) => {
    try {
      const res = await apolloClient.mutate({
        variables: {
          userIds,
          companyId,
        },
        mutation: sendGroupDonationReminderMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      uiStore.showNotification({
        body: `Group donation reminder${userIds.length > 1 ? 's' : ''} sent.`,
        type: 'SUCCESS',
      });

      if (res.errors) {
        throw res.errors;
      }
    } catch (errs) {
      errs.map(
        (err) =>
          err.message.includes('Insufficient funds') &&
          uiStore.showNotification({
            body: err.message,
            type: 'ERROR',
          }),
      );
    }
  };

  @action resendCompanyInvites = async ({ ids, companyId }) => {
    try {
      await apolloClient
        .mutate({
          variables: { ids },
          mutation: resendCompanyUserTokens,
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        })
        .then(() => this.getUsers({ id: companyId }));
    } catch (err) {
      console.log(err);
    }
  };

  @action updateCompanyInvites = async ({ ids, amount, comment }) => {
    try {
      await apolloClient
        .mutate({
          variables: { ids, amount, comment },
          mutation: updateCompanyInvitesMutation,
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        })
        .then(() => {
          this.pending = this.pending.map((user) => {
            if (ids.includes(user.tokenId)) {
              return {
                ...user,
                giftAmount: amount,
              };
            }
            return user;
          });
        });
    } catch (err) {
      console.log(err);
    }
  };

  @action updatePayrollRow = async ({ payrollId, userId }) => {
    try {
      await apolloClient.mutate({
        variables: { payrollId, userId },
        mutation: updatePayrollRowMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
    } catch (err) {
      console.log(err);
    }
  };

  @action createAnnualMatch = async ({
    companyId,
    startDate,
    endDate,
    matchLimit,
  }) => {
    try {
      await apolloClient.mutate({
        variables: {
          companyId,
          startDate,
          endDate,
          matchLimit,
          matchTotal: MATCH_TOTAL_DEFAULT,
        },
        mutation: createAnnualMatchMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      this.getCompanyMatchDetails({ id: companyId });
      this.showMatchReminder = false;
    } catch (err) {
      console.log(err);
    }
  };

  @action
  toggleActive = (matchId, active) => {
    this.matchList = this.matchList.map((match) => {
      if (match.id === matchId) {
        const newMatch = {
          ...match,
          active,
          matchStatus: matchStatus(match.startDate, match.endDate, active),
        };
        return newMatch;
      }
      return match;
    });
  };

  @action disableMatch = async (matchId, active) => {
    const variables = {
      id: matchId,
      active: !active,
    };

    try {
      apolloClient
        .mutate({
          variables,
          mutation: editCompanyMatchMutation,
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        })
        .catch((err) => {
          console.log(err);
          this.toggleActive(matchId, active);
        });

      this.toggleActive(matchId, !active);
    } catch (err) {
      console.log(err);
    }
  };

  @action getPayrollReport = async () => {
    const options = {
      query: listPayrollForCompanyQuery,
      variables: { companyId: this.id },
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const result = await apolloClient.query(options);
      this.payrollStatusReport = result.data.listPayrollForCompany;
    } catch (err) {
      this.error = err;
    }
  };

  @action uploadPayrollCSV = async (file) => {
    if (file) {
      this.csvFilename = file.name;
      try {
        this.isUploadingCSV = true;

        await getToken().then((token) => {
          const url = `${UPLOAD_URL}/company/${this.id}/payroll`;
          const data = new FormData();
          data.append(PAYROLL_KEY, file);

          axios.put(url, data, {
            headers: {
              Authorization: token ? `Bearer ${token}` : null,
            },
          });

          // This is because the upload put is so fast the user can't see
          // the progress spinner, made them think it didn't work sometimes
          setTimeout(() => {
            this.isUploadingCSV = false;
            this.csvUploaded = true;
            this.getPayrollReport();
          }, 2000);
        });
      } catch (err) {
        this.isUploadingHeroImage = false;
        this.csvUploadError = true;
      }
    }
  };

  @action getMatchDownloadToken = async ({ handlerName, companyId }) => {
    const options = {
      mutation: createDownloadTokenMutation,
      variables: { handlerName, userContext: { companyId } },
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const token = await apolloClient
        .mutate(options)
        .then((result) => result.data.createDownloadToken.token);

      return token;
    } catch (err) {
      this.error = err;
    }
  };

  @action downloadMatchLedger = ({ matchId, companyId }) => {
    this.getMatchDownloadToken({
      handlerName: 'match/ledger',
      companyId,
    }).then((token) =>
      navigate(`${config.API_ROOT}/match/${matchId}/ledger/${token}`),
    );
  };

  @action setBioText = (text) => {
    this.bio = text;
    this.bioTextHasChanged = true;
  };

  @action setCompanyLogoUrl = (imgURL) => {
    this.companyLogoUrl = imgURL;
  };

  @action setCompanyLogoFile = (img) => {
    this.companyLogoFile = img;
    this.companyLogoHasChanged = true;
  };

  @action setCompanyHeroUrl = (imgURL) => {
    this.companyHeroUrl = imgURL;
  };

  @action setCompanyHeroFile = (img) => {
    this.companyHeroFile = img;
    this.companyHeroHasChanged = true;
  };

  @action submitCompanyLogo = async () => {
    if (this.companyLogoFile) {
      try {
        this.isUploadingCompanyLogo = true;

        const token = await getToken();
        const url = `${UPLOAD_URL}/company/${this.data.id}/${COMPANY_LOGO_FORM_KEY}`;
        const data = new FormData();
        data.append(COMPANY_LOGO_FORM_KEY, this.companyLogoFile);

        await axios.put(url, data, {
          headers: {
            Authorization: token ? `Bearer ${token}` : null,
          },
        });

        this.isUploadingCompanyLogo = false;
        this.companyLogoHasChanged = false;
      } catch (err) {
        this.isUploadingCompanyLogo = false;
        this.imageUploadError = true;
      }
    }
  };

  @action updateBio = async () => {
    this.updatingBioText = true;

    try {
      await apolloClient.mutate({
        mutation: updateCompanyMutation,
        variables: {
          id: this.data.id,
          description: this.bio,
        },
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      this.updatingBioText = false;
      this.bioTextHasChanged = false;
    } catch (err) {
      this.error = err;
    }
  };

  @action submitCompanyHero = async () => {
    if (this.companyHeroFile) {
      try {
        this.isUploadingCompanyHero = true;

        const token = await getToken();
        const url = `${UPLOAD_URL}/company/${this.data.id}/${COMPANY_HERO_FORM_KEY}`;
        const data = new FormData();
        data.append(COMPANY_HERO_FORM_KEY, this.companyHeroFile);

        await axios.put(url, data, {
          headers: {
            Authorization: token ? `Bearer ${token}` : null,
          },
        });

        this.isUploadingCompanyHero = false;
        this.companyHeroHasChanged = false;
      } catch (err) {
        this.isUploadingCompanyHero = false;
        this.imageUploadError = true;
      }
    }
  };

  @action toggleShowMatchReminder = () => {
    this.showMatchReminder = !this.showMatchReminder;
  };

  @action onUnmount = () => {
    this.feedQuery = null;
    this.isError = false;
    this.users = [];
    this.pending = [];
    this.totalUsersBalance = 0;
    this.companyRemainingMatch = 0;
    this.companyEligibleMatch = 0;
    this.companyUserMatchLimit = 0;
    this.totalGroupDonationCount = 0;
    this.totalGroupDonationAmount = 0;
    this.showMatchReminder = false;
  };

  constructor() {
    makeObservable(this);
  }
}

const companyAdminStore = new CompanyAdminStore();

export default companyAdminStore;
