import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';
import config from '../../config';

import useCharities from 'hooks/useCharities';
import withAuthRequired from 'behaviors/withAuthRequired';
import withUserContext from 'behaviors/withUserContext';
import useDashboardStore from 'stores/DashboardStore';

import Button from 'components/Button/Button';
import CharitiesTable from 'components/Admin/CharitiesTable';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const CharitiesView = ({ charitiesStore, uiStore }) => {
  const gotoCharity = (_state, rowInfo, _column, _instance) => ({
    onClick: (_e) => navigate(`/admin/charity/${rowInfo.original.id}`),
  });

  useCharities();
  const { setProps } = useDashboardStore();

  useEffect(() => {
    setProps({
      size: 'screen',
      title: 'Charities',
      actions: (
        <div className="flex gap-2.5 items-stretch flex-wrap">
          <Button
            style={{ paddingLeft: '.5em', paddingRight: '.5em' }}
            className="is-small !font-agenda-semibold flex justify-center items-center  leading-none !rounded !text-[14px] h-full"
            spanClassName="translate-y-[1px]"
            onClick={() => {
              charitiesStore
                .getAdminDownloadToken('grants/all')
                .then((token) =>
                  navigate(`${config.API_ROOT}/grants/all/${token}`),
                );
            }}
          >
            Download All Grants
          </Button>
          <Button
            style={{ paddingLeft: '.5em', paddingRight: '.5em' }}
            className="is-small !font-agenda-semibold flex justify-center items-center  leading-none !rounded !text-[14px] h-full"
            spanClassName="translate-y-[1px]"
            disabled={charitiesStore.showLastMonthGrantProgress}
            onClick={() => charitiesStore.generatePendingGrants(true)}
          >
            {charitiesStore.showLastMonthGrantProgress && (
              <span>
                Downloading Pending Grants,{' '}
                {charitiesStore.lastMonthGrantProgress * 100}%
              </span>
            )}
            {!charitiesStore.showLastMonthGrantProgress && (
              <span>Download Pending Grants (through last month)</span>
            )}
          </Button>
          <Button
            style={{ paddingLeft: '.5em', paddingRight: '.5em' }}
            className="is-small !font-agenda-semibold flex justify-center items-center  leading-none !rounded !text-[14px] h-full"
            spanClassName="translate-y-[1px]"
            disabled={charitiesStore.showTodayGrantProgress}
            onClick={() => charitiesStore.generatePendingGrants(false)}
          >
            {charitiesStore.showTodayGrantProgress && (
              <span>
                Downloading Pending Grants,{' '}
                {charitiesStore.todayGrantProgress * 100}%
              </span>
            )}
            {!charitiesStore.showTodayGrantProgress && (
              <span>Download Pending Grants (through today)</span>
            )}
          </Button>
          <Button
            style={{ paddingLeft: '.5em', paddingRight: '.5em' }}
            className="is-small !font-agenda-semibold flex justify-center items-center  leading-none !rounded !text-[14px] h-full"
            spanClassName="translate-y-[1px]"
            onClick={() =>
              uiStore.openModal('CONFIRM', {
                message: 'This action will mark all donations as granted',
                action: () => charitiesStore.processPendingGrants(),
              })
            }
          >
            Process Pending Grants (End of Month)
          </Button>
          <Button
            style={{ paddingLeft: '.5em', paddingRight: '.5em' }}
            className="is-small !font-agenda-semibold flex justify-center items-center  leading-none !rounded !text-[14px] h-full"
            spanClassName="translate-y-[1px]"
            onClick={() => {
              charitiesStore
                .getAdminDownloadToken('grants/pending_prepays')
                .then((token) =>
                  navigate(
                    `${config.API_ROOT}/grants/pending_prepays/${token}`,
                  ),
                );
            }}
          >
            Download Company Prepay Report
          </Button>
        </div>
      ),
    });
  }, []);

  if (charitiesStore.loading) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div className="company-reporting flex-column">
      <div>
        <section>
          <div className=" flex-justify-center">
            {charitiesStore.charities.length > 0 && (
              <CharitiesTable onGotoCharity={gotoCharity} />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(
    inject(
      'profileStore',
      'charitiesStore',
      'uiStore',
    )(observer(CharitiesView)),
  ),
  { entityType: 'ADMIN' },
);
