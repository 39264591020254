import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer, inject } from 'mobx-react';

import withUserContext from 'behaviors/withUserContext';
import EntityCauzesView from './EntityCauzesView';
import useProfileById from 'hooks/useProfileById';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const UserCauzesView = ({
  authStore,
  uiStore,
  userProfileStore,
  profileStore,
  activeEntity,
  matchStore,
}) => {
  const profileId = activeEntity?.id;
  useProfileById({ id: profileId, isLanding: true });

  const [isCauzesLoading, setIsCauzesLoading] = useState(false);
  const cauzes = profileStore.cauzes || [];
  const joinedEvents = userProfileStore.joinedEvents.get(+profileId);

  const activeCauzes = useMemo(() => {
    return cauzes.filter((cauze, index) => {
      cauze.index = index;
      return cauze.eventState === 'ACTIVE';
    });
  }, [cauzes, JSON.stringify(cauzes)]);

  const endedCauzes = useMemo(() => {
    return cauzes.filter((cauze, index) => {
      cauze.index = index;
      return cauze.eventState === 'INACTIVE';
    });
  }, [cauzes, JSON.stringify(cauzes)]);

  const draftCauzes = useMemo(() => {
    return cauzes.filter((cauze, index) => {
      cauze.index = index;
      return cauze.eventState === 'UNPUBLISHED';
    });
  }, [cauzes, JSON.stringify(cauzes)]);

  const fetchCauzes = useCallback(() => {
    setIsCauzesLoading(true);
    profileStore.getCauzes().finally(() => {
      setIsCauzesLoading(false);
    });
  }, []);

  useEffect(() => {
    if (profileId) {
      userProfileStore.getUser({
        id: profileId,
        userContext: { userId: profileId },
      });
      fetchCauzes();
    }
  }, [profileId]);

  return (
    <>
      {isCauzesLoading ? (
        <div className="h-full">
          <CauzeSpinner marginTop="80px" />
        </div>
      ) : (
        <EntityCauzesView
          loading={profileStore.loading || userProfileStore.joinedEventsLoading}
          isAuthenticated={authStore.isAuthenticated}
          profileStore={profileStore}
          matchStore={matchStore}
          activeCauzes={activeCauzes}
          endedCauzes={endedCauzes}
          draftCauzes={draftCauzes}
          joinedCauzes={joinedEvents}
          uiStore={uiStore}
          updatePlannedEventState={profileStore.updatePlannedEventState}
          userContext={activeEntity?.userContext}
        />
      )}
    </>
  );
};

export default withUserContext(
  inject(
    'authStore',
    'userProfileStore',
    'uiStore',
    'matchStore',
    'profileStore',
  )(observer(UserCauzesView)),
);
