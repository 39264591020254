import React, { useState } from 'react';
import cx from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

const ConfirmationPopover = ({ children, message, onConfirm, className }) => {
  const [visible, setVisible] = useState(false);

  const { getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    placement: 'bottom',
    trigger: 'click',
    interactive: true,
    closeOnTriggerHidden: true,
    visible,
    onVisibleChange: (isVisible) => {
      setVisible(isVisible);
    },
  });

  const _className = cx('inline-block', {
    [className]: className,
  });

  return (
    <>
      <div ref={setTriggerRef} className={_className}>
        {children}
      </div>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              'tooltip-container tooltip-container-checkout-summary !p-0 !py-2 !border !border-solid !border-lightgray-e8e',
          })}
        >
          <div className="tooltip-body">
            <div className="text-sm p-2 text-center leading-none">
              {message}
            </div>
            <div className="flex gap-x-1 justify-evenly">
              <button
                className="cz-btn-outline black pt-2 pb-1"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Cancel
              </button>
              <button
                className="cz-btn-brand pt-2 pb-1"
                onClick={async () => {
                  await onConfirm?.();
                  setVisible(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationPopover;
