import colors from 'styles/colors';

export const GlobalCharityAsyncSelectStyles = {
  multiValueLabel: (provided, _state) => ({
    ...provided,
    border: 'none',
    whiteSpace: 'normal',
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 380px)': {
      fontSize: '13px',
    },
  }),
  control: (provided, state) => ({
    ...provided,
    height: '100%',
    border: 'none !important',
    boxShadow: 'none',
    outline: 'none',
    fontSize: '16px',
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 380px)': {
      fontSize: '13px',
    },

    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:focus': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:active': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    '&:focus-within': {
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    },
    ...(state.isFocused && {
      border: 'none !important',
      boxShadow: 'none',
      outline: 'none',
    }),
  }),
  container: (provided, _state) => ({
    ...provided,
    height: '100%',
    border: 'none',
    width: '100%',
  }),
  input: (provided, _state) => ({
    ...provided,
    color: '#1a1a1a',
    outline: 'none',
    boxShadow: 'none',
    border: 'none',
    fontSize: '16px',
    width: '100%',
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 380px)': {
      fontSize: '13px',
    },
    input: {
      opacity: '1 !important',
      width: '100% !important',
    },
  }),
  a11yText: (provided, _state) => ({
    ...provided,
  }),
  valueContainer: (provided, _state) => ({
    ...provided,
    padding: '0',
  }),
  singleValue: (provided, _state) => ({
    ...provided,
    color: '#1a1a1a',
    fontSize: '16px',
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 380px)': {
      fontSize: '13px',
    },
  }),
  placeholder: (provided, _state) => ({
    ...provided,
    color: '#94a3b8',
    fontSize: '16px',
    '@media (max-width: 768px)': {
      fontSize: '14px',
    },
    '@media (max-width: 380px)': {
      fontSize: '13px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
    border: 'none',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 999,
    width: '100%',
    border: 'none',
    top: '40px',
    left: '0%',
    boxShadow:
      '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    position: 'absolute',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.baseColor : 'white',
    color: state.isSelected ? 'white' : '#1a1a1a',
    border: 'none',
    cursor: 'pointer',
    '&:active': {
      backgroundColor: colors.baseColor,
      color: 'white',
    },
    '&:hover': {
      '@media (min-width: 460px)': {
        backgroundColor: colors.baseColor,
        color: 'white',
      },
    },
  }),
};
