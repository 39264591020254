import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';

import useDashboardStore from 'stores/DashboardStore';
import { ReactComponent as CauzeLogo } from 'assets/images/brand/cauze-logo.svg';
import CauzeButton from 'components/CauzeButton/CauzeButton';

const SubmittedContent = () => {
  return (
    <>
      <div className="signup-view-logos">
        <CauzeLogo />
      </div>
      <div className="flex flex-col gap-y-3 items-center">
        <div className="text-center mb-3">
          <h3 className="font-agenda-bold text-xl">
            Thank You for Reaching Out!
          </h3>
          We appreciate you taking the time to contact us. Your message has been
          successfully sent, and we'll respond as soon as possible.
        </div>
        <a href="/donate" className="cz-btn-brand">
          Return to Home
        </a>
      </div>
    </>
  );
};

const ContactView = ({ profileStore }) => {
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState(profileStore.activeEntity?.name);
  const [email, setEmail] = useState(profileStore.data?.email);
  const [message, setMessage] = useState('');
  const { setProps } = useDashboardStore();

  useEffect(() => {
    setProps({
      noSidebar: true,
      noPadding: true,
      bodyClassName: 'bg-lightgray-e0e',
      navbarProps: {
        hideLoginPrompt: false,
      },
    });
  }, []);

  const submitForm = async () => {
    setSubmitted(true);
    profileStore.sendContactMessage({
      name,
      email,
      message,
    });
  };

  return (
    <div className="signup-view-container md:py-4">
      <div className="signup-view">
        <div className="background-image" />
        <div className="auth-form-body flex flex-col h-full">
          {submitted ? (
            <SubmittedContent />
          ) : (
            <>
              <div>
                <div className="signup-view-logos mb-3">
                  <CauzeLogo />
                </div>
                <h1 className="signup-view-title">contact us</h1>
              </div>
              <form
                className="signup-view-form flex-1"
                onSubmit={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
              >
                <fieldset className="p-0 mb-3">
                  <legend>contact cauze</legend>
                  <div className="form-row create-account-email">
                    <label for="name" className="font-agenda-bold">
                      Name
                    </label>
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-row create-account-password">
                    <label className="font-agenda-bold">Email *</label>
                    <input
                      required
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-row create-account-password">
                    <label className="font-agenda-bold">Message *</label>
                    <textarea
                      required
                      className="block w-full pt-3"
                      id="message"
                      rows={8}
                      placeholder="I'm contacting you because..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </fieldset>
                <CauzeButton type="submit">SEND</CauzeButton>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default inject('profileStore')(observer(ContactView));
