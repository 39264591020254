import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';
import config from '../../../config';

import useCompanyReporting from 'hooks/useCompanyReporting';
import withUserContext from 'behaviors/withUserContext';
import withAuthRequired from 'behaviors/withAuthRequired';

import Charts from './Charts';

import Button from 'components/Button/Button';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import SupportedCharitiesTable from 'components/CharityTable/SupportedCharitiesTable';
import useDashboardStore from 'stores/DashboardStore';

const CompanyReportingView = ({
  companyReportingStore,
  profileStore,
  companyId,
}) => {
  const { setProps } = useDashboardStore();
  useCompanyReporting(companyId);

  useEffect(() => {
    setProps({
      size: 'screen',
    });
  }, []);

  return (
    <div className="company-reporting flex-column">
      <div style={{ minHeight: '100vmax' }}>
        <section>
          <div className="flex justify-between items-center pb-4 ">
            <h1 className="  capitalize font-agenda-bold text-2xl  leading-none ">
              Reporting
            </h1>

            <Button
              style={{ marginLeft: 20 }}
              className="is-small !font-agenda-semibold flex justify-center items-center  leading-none !rounded !text-[14px]"
              spanClassName="translate-y-[1px]"
              onClick={() => {
                companyReportingStore
                  .getDownloadToken(companyId, 'company/donations')
                  .then((token) =>
                    navigate(
                      `${config.API_ROOT}/company/${companyId}/donations/${token}`,
                    ),
                  );
              }}
            >
              Download Donation Report
            </Button>
          </div>
          {companyReportingStore.chartsLoading ? (
            <CauzeSpinner />
          ) : (
            <div className="dashboard-container">
              <div className="dashboard flex-row flex-space-around">
                <Charts
                  engagementData={companyReportingStore.engagementData}
                  categoryData={companyReportingStore.categoryData}
                  isGroup={profileStore.activeEntity.groupType !== 'COMPANY'}
                />
              </div>
            </div>
          )}
          <div className="dashboard flex-row flex-space-around"></div>
        </section>
        <section>
          {companyReportingStore.supportedCharitiesLoading ? (
            <CauzeSpinner />
          ) : (
            <div className="">
              {companyReportingStore.supportedCharities.length > 0 ? (
                <SupportedCharitiesTable
                  charities={companyReportingStore.supportedCharities}
                  totalGood={companyReportingStore.totalGood}
                  totalMatchedPortion={
                    companyReportingStore.totalMatchedPortion
                  }
                  totalDonations={companyReportingStore.totalDonations}
                />
              ) : (
                <p>No Donations</p>
              )}
            </div>
          )}
        </section>
      </div>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(
    inject(
      'profileStore',
      'companyReportingStore',
    )(observer(CompanyReportingView)),
  ),
  { entityType: 'COMPANY' },
);
