import React, { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import useMobile from 'hooks/useMobile';
import classNames from 'classnames';

const Toast = ({
  children,
  show,
  id,
  onClose,
  timeout = 5000,
  duration = 100,
}) => {
  const toastRef = useRef();
  const [enabled, setEnabled] = useState(false);
  const { isMobile } = useMobile();

  const [styleProps] = useSpring(
    () => ({
      from: { bottom: -toastRef.current?.clientHeight || 0 },
      to: { bottom: isMobile ? 16 : 40 },
      reverse: !show,
      config: {
        duration,
      },
      immediate: !show && !enabled,
    }),
    [toastRef.current, show, isMobile],
  );

  useEffect(() => {
    let timer;

    if (show) {
      setEnabled(true);
    } else {
      setTimeout(() => {
        setEnabled(false);
      }, duration);
    }

    if (show && timeout) {
      timer = setTimeout(() => {
        onClose?.();
      }, timeout);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [show, id]);

  return (
    <animated.div
      style={styleProps}
      ref={toastRef}
      className={classNames('absolute left-[2.5vw] sm:left-10 z-[99]', {})}
    >
      {children}
    </animated.div>
  );
};

export default Toast;
