import React from 'react';

import {
  EventWithMatchCreated,
  MatchSponsorAdded,
  EventCreated,
  JoinEvent,
  LegacyNotifications,
  RecurringDeposit,
  DonationReferral,
  DonationReward,
  GiftRefund,
  AdminGift,
  Deposit,
  Payroll,
  CauzeLike,
  Mention,
  CommentPost,
} from './FeedItemTypes';
import useFeedNotificationStore from 'stores/FeedNotificationsStore';

const ActivityItem = ({ item, navigation, index, onToggleOpen, uiStore }) => {
  const feedNotificationsStore = useFeedNotificationStore();

  const markRead = () => {
    feedNotificationsStore.markNotificationRead(item.id, index);
  };

  const sharedProps = {
    item,
    navigation,
    markRead,
    onToggleOpen,
    uiStore,
  };

  switch (item.type) {
    case 'MENTION':
      return <Mention {...sharedProps} />;
    case 'RECURRING_DEPOSIT':
      return <RecurringDeposit {...sharedProps} />;
    case 'EVENT_WITH_MATCH_CREATED':
      return <EventWithMatchCreated {...sharedProps} />;
    case 'EVENT_CREATED':
      return <EventCreated {...sharedProps} />;
    case 'MATCH_SPONSOR_ADDED':
      return <MatchSponsorAdded {...sharedProps} />;
    case 'JOIN_EVENT':
      return <JoinEvent {...sharedProps} />;
    case 'DONATION_REFERRAL':
      return <DonationReferral {...sharedProps} />;
    case 'DONATION_REWARD':
      return <DonationReward {...sharedProps} />;
    case 'GIFT_REFUND':
      return <GiftRefund {...sharedProps} />;
    case 'ADMIN_GIFT':
      return <AdminGift {...sharedProps} />;
    case 'DEPOSIT':
      return <Deposit {...sharedProps} />;
    case 'PAYROLL':
      return <Payroll {...sharedProps} />;
    case 'CAUZE_LIKE':
      return <CauzeLike {...sharedProps} />;

    case 'CAUZE_COMMENT':
      if (
        item.subject?.type === 'PURCHASE' &&
        item.subject?.id === item.verbMeta.purchaseId
      ) {
        return <CommentPost {...sharedProps} />;
      }
      return <LegacyNotifications {...sharedProps} />;
    case 'CAUZE_PURCHASE':
      return <LegacyNotifications {...sharedProps} />;
    case 'EMPLOYEE_GIFT':
      return <LegacyNotifications {...sharedProps} />;
    case 'REWARD':
      return <LegacyNotifications {...sharedProps} />;
    case 'USER_FOLLOWER':
      return <LegacyNotifications {...sharedProps} />;
    case 'USER_GIFT':
      return <LegacyNotifications {...sharedProps} />;
    default:
      return null;
  }
};

export default ActivityItem;
