import { create } from 'zustand';

const initialState = {
  props: {},
  toast: null,
  toastProps: {},
};

const useDashboardStore = create((set, get) => ({
  ...initialState,
  setProps: (props) =>
    set({
      props: {
        ...props,
      },
    }),
  updateProps: (props) => {
    const state = get();
    set({
      props: {
        loadingScreen: state.props.loadingScreen,
        loadingScreenProps: state.props.loadingScreenProps,
        ...props,
      },
    });
  },
  showLoadingScreen: (loadingScreen, loadingScreenProps) => {
    const state = get();
    state.setProps({ ...state.props, loadingScreen, loadingScreenProps });
  },
  showToast: (toast, toastProps = {}) => {
    set({ toast, toastProps });
  },
}));

export default useDashboardStore;
