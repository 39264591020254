import React from 'react';
import Currency from '../Currency/Currency';
import classnames from 'classnames';

const ColumnHeader = ({ title, subtitle, amount, classNames }) => (
  <div className={classnames('flex-column', classNames)}>
    <div className="flex-row flex-center">{title}</div>
    <div className="col-total flex-row flex-center font-agenda-bold !text-[12px]">
      {amount && <Currency amount={amount} />}
      {subtitle}
    </div>
  </div>
);

export default ColumnHeader;
