import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { navigate } from '@reach/router';

import useInfluencers from 'hooks/useInfluencers';
import withAuthRequired from 'behaviors/withAuthRequired';
import withUserContext from 'behaviors/withUserContext';

import Button from 'components/Button/Button';

import InfluencersTable from 'components/Admin/InfluencersTable';
import useDashboardStore from 'stores/DashboardStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const InfluencersView = ({ influencersStore, uiStore }) => {
  const { setProps } = useDashboardStore();
  const goToUser = (_state, rowInfo, _column, _instance) => ({
    onClick: (_e, _handleOriginal) => {
      navigate(`/admin/influencer/${rowInfo.original.id}`);
    },
  });

  useInfluencers();

  useEffect(() => {
    setProps({
      size: 'screen',
      title: 'Influencers',
      actions: (
        <Button
          className="is-small !font-agenda-semibold flex justify-center items-center  leading-none !rounded !text-[14px]"
          spanClassName="translate-y-[1px]"
          onClick={() =>
            uiStore.openModal('USER_SEARCH', {
              title: 'Add an Influencer',
              buttonText: 'Add Influencer',
              onConfirm: ({ email, userId }) =>
                influencersStore.addInfluencer({ email, userId }),
            })
          }
        >
          Add Influencer
        </Button>
      ),
    });
  }, []);

  if (influencersStore.loading) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div className="company-reporting flex-column">
      <div>
        <section>
          <div className=" flex-justify-center">
            {influencersStore.influencers.length > 0 && (
              <InfluencersTable goToUser={goToUser} />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(
    inject(
      'profileStore',
      'influencersStore',
      'uiStore',
    )(observer(InfluencersView)),
  ),
  { entityType: 'ADMIN' },
);
