import React, { useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import classnames from 'classnames';

import 'react-image-crop/src/ReactCrop.scss';
import Button from 'components/Button/Button';
import ErrorModal from '../ErrorModal/ErrorModal';
import { centerAspectCrop } from 'util/image';
import useCheckoutStore from 'stores/CheckoutStoreV2';

const CommentInputImageUploadModal = ({
  onToggleClose,
  isActive,
  updateImageUrl,
  setUploadingImage,
  noPlaceholder,
  cropRatio = [16, 9],
  upImg,
  setUpImg,
  isChanged,
  setIsChanged,
  showFileError,
  setShowFileError,
}) => {
  const checkoutStore = useCheckoutStore();
  const imgRef = useRef(null);
  const inputRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 100,
    height: 100,
    aspect: cropRatio[0] / cropRatio[1],
  });
  const [finalCrop, setFinalCrop] = useState({
    unit: '%',
    width: 100,
    height: 100,
    aspect: cropRatio[0] / cropRatio[1],
  });

  function onLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, cropRatio[0] / cropRatio[1]));
    setFinalCrop(centerAspectCrop(width, height, cropRatio[0] / cropRatio[1]));
  }

  const updateFinalCrop = async (crop, percentCrop) => {
    if (imgRef.current && crop.width && crop.height) {
      setFinalCrop(percentCrop);
      setIsChanged(true);
    }
  };

  const uploadImage = async () => {
    if (!isChanged) {
      onToggleClose();
      return;
    }
    const image = imgRef.current;
    const fileName = 'uploadedImage.jpg';
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const scaleX = image.naturalWidth * (finalCrop.width / 100);
    const scaleY = image.naturalHeight * (finalCrop.height / 100);
    canvas.width = scaleX;
    canvas.height = scaleY;

    ctx.drawImage(
      image,
      (finalCrop.x / 100) * image.naturalWidth,
      (finalCrop.y / 100) * image.naturalHeight,
      scaleX,
      scaleY,
      0,
      0,
      scaleX,
      scaleY,
    );

    setUploadingImage(true);

    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        file.name = fileName;
        updateImageUrl(window.URL.createObjectURL(file));
        checkoutStore
          .uploadImage(new File([file], fileName, { lastModified: new Date() }))
          .then(({ imageId, previewUrl }) => {
            checkoutStore.update({
              commentImageId: imageId,
              previewUrl,
            });
            checkoutStore.updateCheckout();
            setUploadingImage(false);
            setIsChanged(false);
            onToggleClose();
            resolve(file);
          });
      }, 'image/jpeg');
    });
  };

  return (
    <div
      className={classnames('modal image-upload-modal', {
        'is-active': isActive,
      })}
    >
      <div onClick={onToggleClose} className="modal-background"></div>
      <div style={{ backgroundColor: 'white' }} className="modal-card">
        <header className="modal-card-head flex-center">
          <button
            className="delete"
            aria-label="close"
            onClick={onToggleClose}
          ></button>
          <div className="flex-column flex-center">
            <div className="text-center text-bold">Comment Image</div>
            <p className="text-center">
              Comment images are {cropRatio[0]}:{cropRatio[1]} ratio. Adjust the
              crop below:
            </p>
            <ErrorModal
              onToggleClose={() => setShowFileError(false)}
              errorText={showFileError || ''}
              isOpen={!!showFileError}
            />
          </div>
        </header>
        <section className="modal-card-body">
          <div className="flex-row flex-center">
            <ReactCrop
              src={upImg}
              ruleOfThirds
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={updateFinalCrop}
              aspect={cropRatio[0] / cropRatio[1]}
            >
              <img ref={imgRef} src={upImg} onLoad={onLoad} />
            </ReactCrop>
          </div>
        </section>
        <footer
          style={{ marginTop: '2rem', flexWrap: 'unset' }}
          className="modal-card-foot flex-row"
        >
          <Button
            outline
            className="is-medium is-fullwidth"
            type="submit"
            style={{ marginRight: '0.5rem' }}
            onClick={() => inputRef.current.click()}
          >
            {noPlaceholder ? 'Upload Image' : 'Upload New Image'}
          </Button>
          <Button
            style={{ marginLeft: '0.5rem' }}
            disabled={noPlaceholder && !isChanged}
            className="is-medium is-fullwidth"
            onClick={(e) => {
              e.preventDefault();
              uploadImage();
              onToggleClose();
            }}
          >
            Save
          </Button>
        </footer>
      </div>
    </div>
  );
};

export default CommentInputImageUploadModal;
