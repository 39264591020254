import { useState } from 'react';
import moment from 'moment';
import cx from 'classnames';

import SortableTable from 'components/SortableTable/SortableTable';
import Currency from 'components/Currency/Currency';

import { ReactComponent as ChevronDown } from 'assets/images/icons/chevron/down-chevron.svg';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const COLUMNS = [
  {
    id: 'donation_date',
    name: 'Donation Date',
    sortable: true,
  },
  {
    id: 'donor',
    name: 'Donor',
    sortable: true,
    style: { minWidth: '160px' },
  },
  {
    id: 'source',
    name: 'Source',
    sortable: true,
    style: { minWidth: '100px' },
  },
  {
    id: 'comments',
    name: 'Comments',
    sortable: false,
    style: { minWidth: '180px' },
  },
  {
    id: 'net_donation',
    name: 'Net Donation',
    sortable: true,
  },
  {
    id: 'granted',
    name: 'Granted',
    sortable: true,
  },
  {
    id: 'thanked',
    name: 'Thanked',
    sortable: true,
  },
  {
    id: 'match_sponsor',
    name: 'Match Sponsor',
    sortable: true,
  },
  {
    id: 'expand',
    name: '',
  },
];

const RowItem = ({ row, onThank }) => {
  const [expanded, setExpanded] = useState(false);
  const feeAmount = row.donationAmount - row.netAmount;
  const rowClassName = 'sortable-table-row clickable';

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const getGrantTypeDisplay = (_row) => {
    let grantType = _row.grantType;

    if (!grantType) return '';

    if (_row.giftId) {
      grantType = grantType.replace('from_balance', 'from_gifted_balance');
    } else {
      grantType = grantType.replace('from_balance', 'from_personal_balance');
    }

    return grantType?.replace(/_/gm, ' ');
  };

  return (
    <>
      <div className={rowClassName} onClick={handleClick}>
        <div className="donation-date">
          {moment(row.donationDate).format('MM/DD/YYYY')}
        </div>
      </div>
      <div className={rowClassName} onClick={handleClick}>
        <div className="donor-name">{row.actorName || 'Giver'}</div>
        <div className="donor-email">{row.email}</div>
      </div>
      <div className={rowClassName} onClick={handleClick}>
        <div className="source">
          {row.eventId ? (
            <a
              target="_blank"
              href={`/event/${row.eventId}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Event {row.eventId}
            </a>
          ) : (
            'Direct Donation'
          )}
        </div>
      </div>
      <div className={rowClassName} onClick={handleClick}>
        <div className="comment">{row.comment}</div>
      </div>
      <div className={rowClassName} onClick={handleClick}>
        <div className="net-donation">
          <Currency showCents showDollarSign amount={row.netAmount} />
        </div>
      </div>
      <div className={rowClassName} onClick={handleClick}>
        <div className="granted">{row.granted ? 'Granted' : 'Pending'}</div>
      </div>
      <div className={rowClassName} onClick={handleClick}>
        <div className="thanked">
          {row.charityCommented ? (
            'Yes'
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onThank([row.purchaseId]);
              }}
            >
              THANK
            </button>
          )}
        </div>
      </div>
      <div className={rowClassName} onClick={handleClick}>
        <div className="match-sponsor">{row?.sponsorName}</div>
      </div>
      <div
        onClick={handleClick}
        className={cx('sortable-table-expand', { expanded: expanded })}
      >
        <ChevronDown />
      </div>
      <div
        className={cx('sortable-table-expanded-row full-comment', {
          open: expanded,
        })}
      >
        {Boolean(row.comment) && (
          <>
            <div className="full-comment-title">Full Comment</div>
            <div className="full-comment-comment">{row.comment}</div>
          </>
        )}
      </div>
      <div
        className={cx('sortable-table-expanded-row net-donation-expanded', {
          open: expanded,
        })}
      >
        <div>
          <div className="net-donation-name">Donation</div>
          <div className="net-donation-amount">
            <Currency showDollarSign showCents amount={row.userAmount} />
          </div>
        </div>
        {Boolean(row.matchAmount) && (
          <div>
            <div className="net-donation-name">Match</div>
            <div className="net-donation-amount">
              <Currency showDollarSign showCents amount={row.matchAmount} />
            </div>
          </div>
        )}
        <div>
          {Boolean(feeAmount) && (
            <>
              <div className="net-donation-name">Cauze Fee</div>
              <div className="net-donation-amount">
                -
                <Currency showDollarSign showCents amount={feeAmount} />
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={cx('sortable-table-expanded-row grant-type', {
          open: expanded,
        })}
      >
        <>
          <div>Grant Type</div>
          <div className="grant-type-type">{getGrantTypeDisplay(row)}</div>
        </>
      </div>
    </>
  );
};

const generateRows = (rowData, onThank) => {
  return rowData.map((row) => (
    <RowItem key={row.id} row={row} onThank={onThank} />
  ));
};

const CharityAdminLedgerTable = ({
  rowData,
  onThank,
  canLoadMore,
  loadMore,
  loading,
  loadingMore,
  sort,
  setSort,
}) => {
  if (loading) {
    return (
      <div>
        <CauzeSpinner />
      </div>
    );
  }

  return (
    <div className="charity-admin-ledger-table">
      <SortableTable
        columns={COLUMNS}
        rows={generateRows(rowData, onThank)}
        canLoadMore={canLoadMore}
        loadMore={loadMore}
        loadingMore={loadingMore}
        sort={sort}
        setSort={setSort}
      />
    </div>
  );
};

export default CharityAdminLedgerTable;
