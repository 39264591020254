export async function shareImage({ imageUrl, url, text, title }) {
  const fetchedImage = await fetch(imageUrl, {
    headers: { 'Cache-Control': 'no-cache' },
  });
  const blobImage = await fetchedImage.blob();
  const fileName = imageUrl.split('/').pop();
  const filesArray = [
    new File([blobImage], fileName, {
      type: 'image/jpeg',
      lastModified: Date.now(),
    }),
  ];

  const shareData = {
    title: title,
    files: filesArray,
    url: url,
    text,
  };

  if (navigator.canShare && navigator.canShare(shareData)) {
    await navigator.share(shareData);
  }
}
