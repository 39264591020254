import React, { useState, useEffect } from 'react';
import Avatar from 'components/Avatar/Avatar';
import Currency from '../../Currency/Currency';
import { shorten } from '../../../util/stringUtils';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import useKeyboardEvent from '../../../hooks/useKeyboardEvent.js';
import dompurify from 'dompurify';

import classnames from 'classnames';

const CharityDetailsModal = ({
  onToggleClose,
  isOpen,
  charity,
  mini = false,
}) => {
  const [learnMoreVisible, setLearnMoreVisible] = useState(false);

  useKeyboardEvent('Escape', () => {
    onToggleClose(false);
  });
  const sanitizer = dompurify.sanitize;

  useEffect(() => {
    if (charity?.description?.length > 275) {
      setLearnMoreVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMoreInfo = () => {
    setLearnMoreVisible(false);
  };

  const hideMoreInfo = () => {
    setLearnMoreVisible(true);
  };

  if (mini) {
    return (
      <div
        className={classnames('modal modal-mini', {
          'is-active': isOpen,
        })}
      >
        <div
          onClick={(ev) => {
            ev.preventDefault();
            onToggleClose(false);
          }}
          className="modal-background"
        ></div>
        <div className="modal-card charity-details">
          <div className="flex-column hero">
            <div className="flex-column charity-avatar">
              <Avatar
                md
                entityType="CHARITY"
                avatar={charity.avatar || charity.avatarUrls}
                noBorder
              />
            </div>
            <div className="charity-header flex-1">
              <div className="charity-name">{charity.name}</div>
              <div className="flex-row flex-wrap">
                <div className="charity-location mr-3.5">
                  {charity.city}
                  {charity.state ? `, ${charity.state}` : ''}
                </div>
                <div className="charity-details">EIN: {charity.ein}</div>
              </div>
            </div>
            <div>
              <div style={{ width: '16px', height: '16px', color: 'white' }}>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={onToggleClose}
                ></button>
              </div>
            </div>
          </div>
          <section className="modal-card-body-mini hide-scrollbar">
            <div className="flex-column body-text">
              {charity.description && (
                <div
                  className="charity-description text-bold"
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(
                      shorten(
                        charity.description,
                        learnMoreVisible ? 275 : 10000,
                      ),
                      {
                        ADD_ATTR: ['target'],
                      },
                    ),
                  }}
                />
              )}
            </div>
            <CauzeButton
              className={`charity-learn-more ${
                !learnMoreVisible && 'is-hidden'
              }`}
              noBorder
              white
              onClick={getMoreInfo}
            >
              Learn More...
            </CauzeButton>
          </section>
          <div className="modal-footer pt-4">
            <CauzeButton
              black
              blackBorder
              className="bg-black footer-button"
              onClick={() => {
                onToggleClose();
                hideMoreInfo();
              }}
            >
              Close
            </CauzeButton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classnames('modal', {
        'is-active': isOpen,
      })}
    >
      <div onClick={onToggleClose} className="modal-background"></div>
      <div className="modal-card charity-details">
        <header className="modal-card-head">
          <p className="modal-card-title">Non-Profit Details</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onToggleClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="flex-row">
            {charity.avatar && (
              <div className="flex-column charity-avatar">
                <Avatar xl square avatar={charity.avatar} />
                <a className="text-align-center" href={charity.avatar.xl}>
                  Download Logo
                </a>
              </div>
            )}
            <div className="flex-column">
              <div className="charity-header">
                <div className="charity-name">{charity.name}</div>
                <div className="charity-details">
                  {charity.category && `${charity.category} | `}
                  EIN: {charity.ein}
                </div>
              </div>
              <p>{charity.description}</p>
            </div>
          </div>
          <div className="charity-stats-container">
            <div className="charity-stat">
              <p className="charity-stat-label">Total Donated</p>
              <p>
                <Currency amount={charity.donationAmount} />
              </p>
            </div>
            <div className="charity-stat">
              <p className="charity-stat-label">Matched</p>
              <p>
                <Currency amount={charity.matchAmount} />
              </p>
            </div>
            <div className="charity-stat">
              <p className="charity-stat-label">Donations</p>
              <p>{charity.donationCount}</p>
            </div>
          </div>
          <div className="charity-contact">
            <p className="contact-label">Contact Information</p>
            <div className="contact-row">
              <div className="contact-column flex-column">
                <p>{charity.street}</p>
                <p>
                  {charity.city}, {charity.state} {charity.zip}
                </p>
              </div>
              <div className="contact-column flex-column">
                <p className="contact-url">
                  <a href={charity.webUrl}>{charity.webUrl}</a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button   is-small  !rounded !font-agenda-semibold !text-[14px] leading-none flex justify-center items-center "
            onClick={() => {
              onToggleClose();
            }}
          >
            <span className="translate-y-[1px]">close</span>
          </button>
        </footer>
      </div>
    </div>
  );
};

export default CharityDetailsModal;
