/* eslint-disable no-console */
import { observable, action, makeObservable } from 'mobx';
import { debounce } from 'lodash';

import { client as apolloClient } from '../util/apolloClient';
import { client as api2Client } from '../util/api2Client';
import config from '../config';

import {
  charityByIdQuery,
  charityLedgerV2,
  addPurchaseCommentMutation,
  updateCharityMutation,
  getCharityLedgerCsvExportQuery,
} from '../graphql/charity';

import { createDownloadTokenMutation } from '../graphql/companyReporting';
import {
  sanitizeAccounts,
  validateAccounts,
} from '../util/formFieldValidators';

import {
  getToken,
  getHasSeenThanksReminder,
  setHasSeenThanksReminder,
} from '../util/storage';

import axios from 'axios';
import downloadLink from 'util/downloadLink';
import uiStore from './UiStore';

const UPLOAD_URL = `${config.UPLOAD_ROOT}/project`;
const CHARITY_LOGO_FORM_KEY = `avatar`;
const CHARITY_HERO_FORM_KEY = `hero`;
const LEDGER_PAGE_SIZE = 100;

class CharityAdminStore {
  // Donations View
  @observable loading = true;
  @observable ledgerLoading = true;
  @observable ledgerLoadingMore = false;
  @observable ledgerCanLoadMore = true;
  @observable isError = false;
  @observable data;
  @observable ledgerData = [];
  @observable ledgerNetAmount = 0;
  @observable hasSeenThanksReminder = true;
  @observable sendingComments = false;
  @observable purchaseIdsToThankCount = 0; // this is set when selecting manually from the table
  @observable outstandingThanksPurchaseIds = [];
  @observable isClearingOutstanding = false;
  @observable sayThanksText = '';
  // Profile View
  @observable missionText = '';
  @observable socialAccounts = {
    webUrl: '',
    facebookName: '',
    instagramName: '',
    twitterName: '',
    youtubeUrl: '',
  };
  @observable socialAccountsValid = {
    webUrl: true,
    facebookName: true,
    instagramName: true,
    twitterName: true,
    youtubeUrl: true,
  };
  @observable charityLogoUrl = '';
  @observable charityLogoFile = {};
  @observable updatingSayThanksText = false;
  @observable charityHeroUrl = '';
  @observable charityHeroFile = {};
  @observable imageUploadError = false;
  @observable sayThanksTextHasChanged = false;
  // Profile Loading States
  @observable isUploadingDonationImage = false;
  @observable isUploadingCharityLogo = false;
  @observable isUploadingCharityHero = false;
  @observable updatingMissionText = false;
  @observable missionTextHasChanged = false;
  @observable socialAccountsHasChanged = false;
  @observable charityLogoHasChanged = false;
  @observable charityHeroHasChanged = false;
  @observable reportResponse = 'waiting';
  @observable updatingSocialAccounts = false;
  @observable ledgerSort = ['donation_date', 'desc'];
  @observable downloadingCSV = false;

  ledgerPage = 0;

  getInitial = (charityId) => {
    const idPayload = {
      id: charityId,
      userContext: { charityId },
    };
    this.getCharity(idPayload);
    this.getLedger({ id: charityId });
    this.getIsFirstView();
  };

  @action setLedgerSort = (newSort) => {
    this.ledgerSort = newSort;
  };

  validateSocialAccounts = debounce(
    () => (this.socialAccountsValid = validateAccounts(this.socialAccounts)),
    500,
    { leading: false, trailing: true },
  );

  @action getIsFirstView = () => {
    getHasSeenThanksReminder().then((value) => {
      this.hasSeenThanksReminder = value;
    });
  };

  @action setNotFirstView = () => {
    this.hasSeenThanksReminder = true;
    setHasSeenThanksReminder();
  };

  @action getCharity = async ({ id = null, userContext }) => {
    this.loading = true;
    const options = {
      variables: { id, userContext },
      query: charityByIdQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const result = await apolloClient.query(options);
      this.data = result.data.data;
      this.charityLogoUrl = result.data.data.avatar?.md;
      this.charityHeroUrl = result.data.data.hero?.md;
      this.missionText = result.data.data.bio;
      this.sayThanksText = result.data.data.defaultThanks;
      this.socialAccounts = {
        webUrl: result.data.data.webUrl,
        facebookName: result.data.data.facebookName,
        instagramName: result.data.data.instagramName,
        twitterName: result.data.data.twitterName,
        youtubeUrl: result.data.data.youtubeUrl,
      };

      this.loading = false;
      this.isError = false;
    } catch (err) {
      this.loading = false;
      this.isError = true;
      this.error = err;
    }
  };

  @action getLedger = async () => {
    this.ledgerLoading = true;
    this.ledgerPage = 0;
    const variables = { offset: 0, limit: LEDGER_PAGE_SIZE };

    if (this.ledgerSort.length > 0) {
      const [sort, sortDir] = this.ledgerSort;
      variables.sort = sort;
      variables.sort_direction = sortDir;
    }

    const options = {
      variables,
      query: charityLedgerV2,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const data = await api2Client.query(options).then((res) => res.data);

      this.ledgerData = data.charityLedger.items || [];
      this.ledgerNetAmount = data.charityLedger.netAmount || 0;
      this.outstandingThanksPurchaseIds = data.outstandingThanksPurchases.map(
        (p) => p.id,
      );

      this.ledgerLoading = false;
    } catch (err) {
      this.ledgerLoading = false;
      this.isError = true;
      this.error = err;
    }
  };

  @action loadMoreLedger = async () => {
    this.ledgerLoadingMore = true;
    this.ledgerPage += 1;
    const variables = {
      offset: this.ledgerPage * LEDGER_PAGE_SIZE,
      limit: LEDGER_PAGE_SIZE,
    };

    if (this.ledgerSort.length > 0) {
      const [sort, sortDir] = this.ledgerSort;
      variables.sort = sort;
      variables.sort_direction = sortDir;
    }

    const options = {
      variables,
      query: charityLedgerV2,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const ledgerData = await api2Client
        .query(options)
        .then((res) => res.data.charityLedger);

      if (ledgerData?.length) {
        this.ledgerData = this.ledgerData.concat(ledgerData);
      } else {
        this.ledgerCanLoadMore = false;
      }

      this.ledgerLoadingMore = false;
    } catch (err) {
      this.ledgerLoadingMore = false;
      this.isError = true;
      this.error = err;
    }
  };

  @action sendThankYouComments = ({ purchaseIds, body, userContext }) => {
    this.sendingComments = true;

    const variables = {
      purchaseIds: this.isClearingOutstanding
        ? this.outstandingThanksPurchaseIds
        : purchaseIds,
      body,
      userContext,
    };

    apolloClient.mutate({
      mutation: addPurchaseCommentMutation,
      variables,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    });

    if (this.isClearingOutstanding) {
      this.outstandingThanksPurchaseIds = [];
      this.outstandingThanksCount = 0;
      this.isClearingOutstanding = false;

      // refetch ledger when clearing outstanding
      setTimeout(() => this.getLedger({ id: this.data.id }), 3000);
      this.ledgerLoading = true;
    } else {
      // else update individual items (no refresh)
      this.ledgerData = this.ledgerData.map((ledgerItem) => {
        if (purchaseIds.includes(ledgerItem.purchaseId)) {
          ledgerItem.charityCommented = true;
        }

        return { ...ledgerItem };
      });

      this.outstandingThanksPurchaseIds =
        this.outstandingThanksPurchaseIds.filter(
          (pid) => !purchaseIds.includes(pid),
        );
    }

    this.sendingComments = false;
    this.purchaseIdsToThankCount = 0;
  };

  @action setSayThanksText = (text) => {
    this.sayThanksText = text;
    this.sayThanksTextHasChanged = true;
  };

  @action setPurchaseIdsToThankCount = (count) => {
    this.purchaseIdsToThankCount = count;
  };

  @action resetRequestReport = () => {
    this.reportRequested = false;
    this.reportRequestSuccess = false;
  };

  @action setIsClearingOutstanding = (isClearingOutstanding) => {
    this.isClearingOutstanding = isClearingOutstanding;
  };

  // We store the URLs for preview in client and files for upload seperately

  @action setMissionText = (text) => {
    this.missionText = text;
    this.missionTextHasChanged = true;
  };

  @action setSocialAccounts = (accounts) => {
    this.socialAccounts = { ...this.socialAccounts, ...accounts };
    this.validateSocialAccounts();
    this.socialAccountsHasChanged = true;
  };

  @action setCharityLogoUrl = (imgURL) => {
    this.charityLogoUrl = imgURL;
  };

  @action setCharityLogoFile = (img) => {
    this.charityLogoFile = img;
    this.charityLogoHasChanged = true;
  };

  @action setCharityHeroUrl = (imgURL) => {
    this.charityHeroUrl = imgURL;
  };

  @action setCharityHeroFile = (img) => {
    this.charityHeroFile = img;
    this.charityHeroHasChanged = true;
  };

  @action submitCharityLogo = async () => {
    if (this.charityLogoFile) {
      try {
        this.isUploadingCharityLogo = true;

        const token = await getToken();
        const url = `${UPLOAD_URL}/${this.data.id}/${CHARITY_LOGO_FORM_KEY}`;
        const data = new FormData();
        data.append(CHARITY_LOGO_FORM_KEY, this.charityLogoFile);

        await axios.put(url, data, {
          headers: {
            Authorization: token ? `Bearer ${token}` : null,
          },
        });

        this.isUploadingCharityLogo = false;
        this.charityLogoHasChanged = false;
      } catch (err) {
        this.isUploadingDonationImage = false;
        this.imageUploadError = true;
      }
    }
  };

  @action
  updateSayThanksText = async () => {
    this.updatingSayThanksText = true;

    try {
      await apolloClient.mutate({
        mutation: updateCharityMutation,
        variables: {
          charityId: this.data.id,
          defaultThanks: this.sayThanksText,
        },
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      this.updatingSayThanksText = false;
      this.sayThanksTextHasChanged = false;
    } catch (err) {
      console.error(err);
      this.updatingSayThanksText = false;
    }
  };

  @action submitCharityHero = async () => {
    if (this.charityHeroFile) {
      try {
        this.isUploadingCharityHero = true;

        const token = await getToken();
        const url = `${UPLOAD_URL}/${this.data.id}/${CHARITY_HERO_FORM_KEY}`;
        const data = new FormData();
        data.append(CHARITY_HERO_FORM_KEY, this.charityHeroFile);

        await axios.put(url, data, {
          headers: {
            Authorization: token ? `Bearer ${token}` : null,
          },
        });

        this.isUploadingCharityHero = false;
        this.charityHeroHasChanged = false;
      } catch (err) {
        this.isUploadingDonationImage = false;
        this.imageUploadError = true;
      }
    }
  };

  @action updateSocialAccounts = async () => {
    this.updatingSocialAccounts = true;

    try {
      await apolloClient.mutate({
        mutation: updateCharityMutation,
        variables: {
          charityId: this.data.id,
          ...sanitizeAccounts(this.socialAccounts),
        },
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      this.socialAccountsHasChanged = false;
    } finally {
      this.updatingSocialAccounts = false;
    }
  };

  @action
  updateMissionText = async () => {
    this.updatingMissionText = true;

    try {
      await apolloClient.mutate({
        mutation: updateCharityMutation,
        variables: {
          charityId: this.data.id,
          description: this.missionText,
        },
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });

      this.updatingMissionText = false;
      this.missionTextHasChanged = false;
    } catch (err) {
      console.error(err);
    }
  };

  @action getDownloadToken = async (charityId, handlerName) => {
    const options = {
      mutation: createDownloadTokenMutation,
      variables: { userContext: { charityId }, handlerName },
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const token = await apolloClient
        .mutate(options)
        .then((result) => result.data.createDownloadToken.token);

      return token;
    } catch (err) {
      this.error = err;
    }
  };

  @action exportToCSV = async ({ id }) => {
    this.downloadingCSV = true;
    const options = {
      variables: {
        projectId: +id,
      },
      query: getCharityLedgerCsvExportQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const result = await api2Client.query(options);
      const blob = new Blob([result.data.charityLedgerCsv.csv], {
        type: 'text/csv;charset=utf-8;',
      });
      const url = window.URL.createObjectURL(blob);
      downloadLink(url, `AllGrants${id}.csv`);
    } catch (err) {
      uiStore.showNotification({
        body: 'Unable to download csv. Contact support.',
        type: 'ERROR',
      });
    } finally {
      this.downloadingCSV = false;
    }
  };

  @action onUnmount = () => {
    this.isError = false;
    this.imageUploadError = false;

    this.isUploadingCharityLogo = false;
    this.isUploadingDonationImage = false;
    this.isUploadingCharityHero = false;

    this.sayThanksTextHasChanged = false;
    this.charityHeroHasChanged = false;
    this.missionTextHasChanged = false;
    this.updatingSayThanksText = false;
    this.socialAccountsHasChanged = false;
    this.updatingMissionText = false;
    this.outstandingThanksPurchaseIds = [];
    this.purchaseIdsToThankCount = 0;
    this.isClearingOutstanding = false;
    this.ledgerLoadingMore = false;
    this.ledgerCanLoadMore = true;

    this.reportRequested = false;
    this.reportResponse = 'waiting';
  };

  constructor() {
    makeObservable(this);
  }
}

const charityAdminStore = new CharityAdminStore();

export default charityAdminStore;
