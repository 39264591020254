import React from 'react';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';
import { Link } from '@reach/router';

import Currency from 'components/Currency/Currency';
import useCheckoutStore, { FRAME } from 'stores/CheckoutStoreV2';
import WrappedPaypalButton from 'components/WrappedPaypalButton';
import PaymentRequestButton from 'components/PaymentRequestButton/PaymentRequestButton';

const SubmitButton = ({
  isMobile,
  onSubmit,
  loading,
  authStore,
  uiStore,
  disabled,
}) => {
  const checkoutStore = useCheckoutStore();
  const paymentType = checkoutStore.getPaymentType().toLowerCase();
  const checkoutType = checkoutStore.checkoutType;
  const isAuthenticated = authStore.isAuthenticated;

  const getButtonContent = () => {
    if (checkoutStore.loading) {
      return 'LOADING...';
    }

    if (checkoutStore.frame === checkoutStore.FRAME.PAYMENT_METHOD) {
      return 'USE THIS PAYMENT METHOD';
    }

    if (checkoutStore.frame === checkoutStore.FRAME.ERROR) {
      return 'TRY AGAIN';
    }

    if (
      ![
        checkoutStore.FRAME.CHECKOUT_SUMMARY,
        checkoutStore.FRAME.GIFT_CHECKOUT,
        !(isMobile || !isAuthenticated) && checkoutStore.FRAME.CHECKOUT,
      ].includes(checkoutStore.frame)
    ) {
      return 'NEXT';
    }

    let message = 'CONFIRM ADD FUNDS';

    if (checkoutType === checkoutStore.CHECKOUT_TYPE.GIFT) {
      message = 'CONFIRM GIFT';

      if (checkoutStore.giftType === 'SELF_GIFT') {
        message = 'CONFIRM ADD FUNDS';
      }
    } else if (checkoutType === 'donation') {
      message = 'CONFIRM DONATION';
    }

    return (
      <>
        {message}{' '}
        <Currency
          showCents
          amount={
            checkoutStore.splitAmount?.total || checkoutStore?.amount || 0
          }
        />
      </>
    );
  };

  if (
    checkoutStore.frame === FRAME.CHECKOUT_SUMMARY ||
    checkoutStore.frame === FRAME.CHECKOUT
  ) {
    if (['apple_pay', 'google_pay'].includes(paymentType)) {
      return (
        <div className="w-full mt-2">
          <PaymentRequestButton store={checkoutStore} />
        </div>
      );
    }

    if (paymentType === 'paypal') {
      return (
        <div className="h-16 flex flex-col justify-end">
          <WrappedPaypalButton {...checkoutStore.getPaypalVars()} />
        </div>
      );
    }
  }

  return (
    <>
      <button
        disabled={loading || checkoutStore.loading || disabled}
        className={cx('cz-btn-brand w-full mt-2.5 md:mt-8', {
          'bg-lightgray-999': loading,
        })}
        onClick={onSubmit}
      >
        {getButtonContent()}
      </button>
      {!authStore.isAuthenticated &&
        ![
          checkoutStore.FRAME.CHECKOUT_SUMMARY,
          checkoutStore.FRAME.ERROR,
        ].includes(checkoutStore.frame) && (
          <div className="text-center mt-5 text-gray-1b2 font-agenda text-[12px]">
            Already have a Cauze account?{' '}
            <Link
              onClick={() => {
                uiStore.closeModal();
              }}
              to="/login"
              className="underline text-gray-1b2"
            >
              Login
            </Link>
          </div>
        )}
    </>
  );
};

export default inject('authStore', 'uiStore')(observer(SubmitButton));
