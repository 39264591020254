import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer, inject } from 'mobx-react';

import withUserContext from 'behaviors/withUserContext';
import EntityCauzesView from './EntityCauzesView';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const CharityCauzesView = ({
  authStore,
  uiStore,
  profileStore,
  activeEntity,
  matchStore,
  charityStore,
}) => {
  const charityId = activeEntity?.id;

  const [isCauzesLoading, setIsCauzesLoading] = useState(false);
  const cauzes = charityStore.events.get(+charityId) || [];
  const joinedEvents = charityStore.joinedEvents.get(+charityId) || [];

  const activeCauzes = useMemo(() => {
    return cauzes.filter((cauze, index) => {
      cauze.index = index;
      return cauze.eventState === 'ACTIVE';
    });
  }, [cauzes, JSON.stringify(cauzes)]);

  const endedCauzes = useMemo(() => {
    return cauzes.filter((cauze, index) => {
      cauze.index = index;
      return cauze.eventState === 'INACTIVE';
    });
  }, [cauzes, JSON.stringify(cauzes)]);

  const draftCauzes = useMemo(() => {
    return cauzes.filter((cauze, index) => {
      cauze.index = index;
      return cauze.eventState === 'UNPUBLISHED';
    });
  }, [cauzes, JSON.stringify(cauzes)]);

  const fetchCauzes = useCallback(
    (id) => {
      setIsCauzesLoading(true);
      Promise.all([
        charityStore.getEvents({
          id: id || activeEntity?.id,
          pinnedOnly: false,
          includeExternal: true,
          limit: 999,
        }),
        charityStore.getJoinedEvents({ id: id || activeEntity?.id }),
      ]).finally(() => {
        setIsCauzesLoading(false);
      });
    },
    [activeEntity],
  );

  useEffect(() => {
    fetchCauzes(activeEntity?.id);
  }, [activeEntity]);

  return (
    <>
      {isCauzesLoading ? (
        <div className="h-full">
          <CauzeSpinner marginTop="80px" />
        </div>
      ) : (
        <EntityCauzesView
          loading={
            charityStore.eventsLoading || charityStore.joinedEventsLoading
          }
          isAuthenticated={authStore.isAuthenticated}
          profileStore={profileStore}
          matchStore={matchStore}
          activeCauzes={activeCauzes}
          endedCauzes={endedCauzes}
          draftCauzes={draftCauzes}
          joinedCauzes={joinedEvents}
          uiStore={uiStore}
          updatePlannedEventState={charityStore.updatePlannedEventState}
          userContext={activeEntity?.userContext}
        />
      )}
    </>
  );
};

export default withUserContext(
  inject(
    'authStore',
    'matchStore',
    'uiStore',
    'charityStore',
  )(observer(CharityCauzesView)),
);
