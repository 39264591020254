import useCheckoutStore from 'stores/CheckoutStoreV2';
import Currency from 'components/Currency/Currency';
import Avatar from 'components/Avatar/Avatar';
import { isEmpty } from 'lodash';

const MatchList = ({ matches }) => {
  const checkoutStore = useCheckoutStore();

  const activeMatches = matches
    ?.filter((match) => match.active)
    ?.filter((match) => (checkoutStore.matchIds || []).includes(match.id));

  if (!activeMatches?.length) return <></>;

  const getMatchAmount = (match) => {
    let totalMatchAmount = checkoutStore.amount * match.multiplier;

    if (
      totalMatchAmount > match.currentEntityRemaining &&
      !isEmpty(checkoutStore.activeEntity)
    ) {
      totalMatchAmount = match.currentEntityRemaining;
    }

    return totalMatchAmount;
  };

  const getMatchSponsor = (match) => {
    return match.matchSponsors?.[0];
  };

  return (
    <div className="mt-5 flex flex-col gap-y-2.5">
      {activeMatches.map((match) => (
        <div
          key={match.id}
          className="py-[13px] px-2.5 gap-x-2.5 flex rounded-[10px] bg-pink-e1b bg-opacity-70 font-agenda text-sm"
        >
          <div className="my-auto">
            <Currency
              className="font-agenda-bold"
              amount={getMatchAmount(match)}
            />{' '}
            of your donation is being matched by{' '}
            <span className="font-agenda-bold">
              {getMatchSponsor(match)?.name}
            </span>
          </div>
          <Avatar
            className="my-auto"
            avatarUrls={getMatchSponsor(match)?.avatarUrls}
          />
        </div>
      ))}
    </div>
  );
};

export default MatchList;
