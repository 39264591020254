import gql from 'graphql-tag';

const companyByIdQuery = gql`
  query companyById($id: ID!, $user_context: UserContext) {
    data: companyById(id: $id, userContext: $user_context) {
      id
      name
      shareLink
      description
      isSelfFollowing
      isSelf
      followerCount
      followingCount
      groupType
      modules
      avatar {
        md
        lg
      }
      hero {
        full
        md
      }
      featuredProjects {
        id
        name
        state
        city
        ein
        description
        insertedAt
        followerCount
        avatar {
          md
        }
        hero {
          md
        }
      }
      featuredEvents {
        id
        image {
          md
        }
        name
        hosts {
          avatar {
            md
          }
          name
        }
        giverCount
      }
    }
  }
`;

const companyUsersByIdQuery = gql`
  query companyUsersById($id: ID!) {
    companyUsersById(id: $id) {
      id
      isSelfFollowing
      isSelf
      firstName
      lastName
      username
      avatar {
        sm
        md
      }
    }
  }
`;

const companyUsersByIdQueryV2 = gql`
  query companyUsers($companyId: Int!) {
    companyUsers(companyId: $companyId) {
      id
      firstName
      lastName
      username
      isSelfFollowing: currentEntityIsFollowing
      isSelf: isCurrentEntity
      avatarUrls {
        sm
        md
      }
    }
  }
`;

const updateCompanyMutation = gql`
  mutation updateCompany($id: ID!, $url: String, $description: String) {
    updateCompany(id: $id, url: $url, description: $description) {
      id
      description
      name
      url
    }
  }
`;

const createCompanyMutation = gql`
  mutation createCompany(
    $email: ID
    $url: String
    $description: String
    $name: String!
    $creator_role: String
    $user_context: UserContext
    $phone_number: String
  ) {
    createCompany(
      email: $email
      url: $url
      description: $description
      name: $name
      creatorRole: $creator_role
      userContext: $user_context
      phoneNumber: $phone_number
    ) {
      id
      description
      name
      url
    }
  }
`;

const getCompanyEmployeeCsvExportQuery = gql`
  query exportEmployeesCsv {
    exportEmployeesCsv {
      csv
    }
  }
`;

const companyActivityByIdQuery = gql`
  query companyActivityById(
    $companyID: Int!
    $offset: Int
    $limit: Int
    $date_from: String
    $date_to: String
    $balance_type: String
    $amount_from: Int
    $amount_to: Int
    $item_type: String
    $search: String
  ) {
    company(companyId: $companyID) {
      companyBalanceCreditsAndDebits(
        offset: $offset
        limit: $limit
        dateFrom: $date_from
        dateTo: $date_to
        balanceType: $balance_type
        amountFrom: $amount_from
        amountTo: $amount_to
        itemType: $item_type
        search: $search
      ) {
        id
        amount
        description
        endingBalance
        type
        createdAt
        eventId
        subscriptionChargeId
        giftType
        balanceType
      }
    }
  }
`;

export {
  companyByIdQuery,
  companyUsersByIdQuery,
  updateCompanyMutation,
  createCompanyMutation,
  companyUsersByIdQueryV2,
  getCompanyEmployeeCsvExportQuery,
  companyActivityByIdQuery,
};
