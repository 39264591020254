import React, { useState, useRef } from 'react';
import ReactTable from 'react-table';
import { observer, inject } from 'mobx-react';
import { debounce } from 'lodash';
import classnames from 'classnames';
import useKeyboardEvent from '../../hooks/useKeyboardEvent.js';
import columns from './UsersTableColumns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faTimes,
  faCaretUp,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

const UsersTable = ({ usersStore, goToUser }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(usersStore.users.length);
  const [mobileSortActive, setMobileSortActive] = useState(false);
  const [mobileSort, setMobileSort] = useState({
    id: 'name',
    desc: false,
  });

  const MAX_PAGE_SIZE = 100;

  let tableRef = useRef(null);
  let searchInputRef = useRef(null);

  const debouncedSetSearchTerm = debounce(setSearchTerm, 1000);

  useKeyboardEvent('Escape', () => {
    clearSearch();
  });

  const clearSearch = () => {
    setSearchTerm('');
  };

  const toggleMobileSort = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(true);
      return;
    }
    setMobileSortActive(false);
  };

  const tableColumns = columns({
    balanceTotal: usersStore.balanceTotal,
    followingCount: usersStore.followingCount,
    followersCount: usersStore.followersCount,
  });

  return (
    <div className="rt-container">
      <div className="action-bar flex-row">
        <div className="responsive-row">
          <div className={classnames('search-input translate-x-2.5')}>
            <div className="input-container">
              <div tabIndex="0" className="action-button-search">
                <FontAwesomeIcon
                  icon={faSearch}
                  className={classnames('', {
                    orange: searchTerm !== '',
                  })}
                />
              </div>
              <input
                tabIndex={0}
                className="input"
                placeholder="Search Users&#8230;"
                ref={searchInputRef}
                onChange={(e) => debouncedSetSearchTerm(e.target.value)}
              />
              <div
                alt="clear all"
                className="action-button close-button flex-column"
                onClick={clearSearch}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          </div>
        </div>
        <div className="responsive-row flex-expand">
          <div className="flex-row flex-expand is-hidden-tablet"></div>
          <div className="flex-row">
            <div
              className={classnames('sort-action flex-column is-hidden-tablet')}
              onClick={
                mobileSortActive
                  ? () =>
                      setMobileSort({ ...mobileSort, desc: !mobileSort.desc })
                  : () => {
                      setMobileSort({ ...mobileSort, desc: !mobileSort.desc });
                      toggleMobileSort(true);
                    }
              }
            >
              <FontAwesomeIcon
                icon={faCaretUp}
                className={classnames('flex items-end translate-y-[3px]', {
                  orange: mobileSort.desc,
                })}
              />
              <FontAwesomeIcon
                icon={faCaretDown}
                className={classnames('flex items-start translate-y-[-3px]', {
                  orange: !mobileSort.desc,
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <ReactTable
        filterable
        className="ledger-table"
        resizable={false}
        keyField={'id'}
        ref={tableRef}
        columns={tableColumns}
        data={usersStore.users}
        pageSize={MAX_PAGE_SIZE}
        minRows={0}
        getTdProps={goToUser}
        filtered={[{ id: 'user', value: searchTerm }]}
        defaultSorted={[{ id: 'user', desc: false }]}
        defaultPageSize={MAX_PAGE_SIZE}
        showPaginationBottom={pageSize > MAX_PAGE_SIZE}
        onFilterUpdateLength={(len) => setPageSize(len)}
        {...(mobileSortActive > 0 && { sorted: [mobileSort] })}
      />
    </div>
  );
};

export default inject('usersStore')(observer(UsersTable));
