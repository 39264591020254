/* eslint-disable no-console */

import React, { useEffect, useRef } from 'react';
import { Router, Redirect } from '@reach/router';
import ReactGA from 'react-ga';
import { observer, inject } from 'mobx-react';

import DonationLandingStore from './stores/DonationLandingStore';
import AuthStore from './stores/AuthStore';
import uiStore from './stores/UiStore';
import ProfileView from './views/ProfileView/ProfileView';
import CompanyProfileView from './views/CompanyProfileView/CompanyProfileView';
import CharityProfileView from './views/CharityProfileView/CharityProfileView';

import {
  CompanyAdminView,
  CompanyProfileAdminView,
  CompanyReportingView,
  CompanyMatchingView,
  CompanyGiftsView,
  PendingInvitesView,
  PayrollReportView,
} from './views/CompanyAdmin';
import CompaniesView from './views/Admin/CompaniesView';
import CharitiesView from './views/Admin/CharitiesView';
import InfluencersView from './views/Admin/InfluencersView';
import UsersView from './views/Admin/UsersView';
import WalletView from './views/Wallet/WalletView.js';
import WalletRedirectView from './views/Wallet/WalletRedirectView';

import {
  CharitySearchView,
  CharityDonationsView,
  CharityProfileAdminView,
  CharityFaqView,
} from './views/CharityAdmin';
import UserDetailsView from './views/UserProfileDetails';
import {
  ForgotPasswordView,
  ResetPasswordView,
  CreateAccountView,
  LoginView,
  SignupView,
} from './views/Login/';
import EventView from './views/EventView';
import NotFoundView from './views/NotFoundView/NotFoundView';
import {
  DonationCheckoutView,
  DonationSuccessView,
  DonationWithGiftSuccessView,
  DonationUserGiftCheckoutView,
  DonationGiftCheckoutSuccessView,
} from './views/DonationCheckout';
import DownloadAppView from './views/DownloadAppView/DownloadAppView';
import {
  // CharityCauzesView,
  CharityCreateCauzeView,
  CompanyCauzesView,
  CompanyCreateCauzeView,
  InfluencerCauzesView,
  InfluencerCreateCauzeView,
} from './views/Cauze/';

import { DonationLandingView, SearchView } from './views/Donate/';
import GiftLandingView from './views/GiftLandingView/GiftLandingView';
import InfluencerDashboardView from './views/Influencer/InfluencerDashboardView';
import InfluencerGiftsView from './views/Influencer/InfluencerGiftsView';
import SponsorInviteView from './views/SponsorInviteView/SponsorInviteView';
import MatchSuccessView from './views/SponsorInviteView/MatchSuccessView';
import EmailUnsubscribeView from './views/EmailUnsubscribeView/EmailUnsubscribeView';
import UserActivityView from 'views/UserActivityView/UserActivityView';
import DiscoverView from 'views/DiscoverView/DiscoverView';
// import UserCauzesView from 'views/Cauze/UserCauzesView';
import DashboardLayout from 'components/page/Layout/DashboardLayout';
import ActiveEntityCauzesView from 'views/Cauze/ActiveEntityCauzesView';
import CompanyActivityView from 'views/CompanyActivityView/CompanyActivityView';
import ContactView from 'views/ContactView/ContactView';
import LoadingView from 'views/LoadingView/LoadingView';

const LocationProvider = ({ children, location }) => {
  const bodyRef = useRef();
  useEffect(() => {
    var urlParams = new URLSearchParams(
      decodeURIComponent(location.search.replace(/\+/g, '%20')),
    );

    if (urlParams.has('referrer_user_id') || urlParams.has('user_id')) {
      const linkId = location.pathname.match(/\d+/)
        ? location.pathname.match(/\d+/)[0]
        : null;
      // saving this off to the uiStore will load the referrer context into the next instance
      // of the donationCheckoutMutation
      uiStore.saveUiState({
        checkoutReferrer: {
          referrerContext: {
            userId:
              urlParams.get('referrer_user_id') || urlParams.get('user_id'),
          },
          linkId,
          fromLink: true,
        },
      });
    }

    if (urlParams.has('referrer_company_id')) {
      const linkId = location.pathname.match(/\d+/)
        ? location.pathname.match(/\d+/)[0]
        : null;

      uiStore.saveUiState({
        checkoutReferrer: {
          referrerContext: { companyId: urlParams.get('referrer_company_id') },
          linkId,
          fromLink: true,
        },
      });
    }

    if (urlParams.has('token')) {
      const token = urlParams.get('token');
      global.IS_LOCAL_OR_DEV && console.log('token detected:', token);

      if (
        location.pathname.includes('signup') ||
        location.pathname.includes('auth') ||
        location.pathname.includes('login')
      ) {
        AuthStore.loadTokenData({ token });
      } else {
        DonationLandingStore.loadGiftData({ token });
      }
    }

    if (urlParams.has('gift_token')) {
      const token = urlParams.get('gift_token');

      if (location.pathname.includes('login')) {
        AuthStore.loadGiftTokenData({ token });
      }
    }

    ReactGA.pageview(location.pathname);

    bodyRef.current.scrollTo(0, 0);
  }, [location, location.pathname]);

  return <DashboardLayout bodyRef={bodyRef}>{children}</DashboardLayout>;
};

const CauzeRouter = () => (
  <Router primary={false} className="app" id="app">
    <LocationProvider path="/">
      <DonationLandingView path="donate" />
      <DiscoverView path="discover" />

      <LoginView path="login" />
      <LoadingView path="loading" />
      <ContactView path="contact" />
      <SignupView path="signup/" />
      <LoginView path="auth" />
      <LoginView path="admin" />
      {/* We deprecated the following URLs, but we need to route them to the default
      location because they interfere with the new urls for profiles */}
      <LoginView path="charity/admin" />
      <LoginView path="charity/profile" />
      <LoginView path="charity/faq" />
      <WalletRedirectView path="/wallet" />

      <ProfileView path="profile/:id" />
      <CompanyProfileView path="company/:id" />
      <CharityProfileView path="charity/:id" />
      <UserDetailsView path="admin/user/:userId/profile" />
      <WalletView path="admin/user/:userId/wallet" />
      <UserActivityView path="admin/user/:userId/activity" />
      <ActiveEntityCauzesView path="profile/cauzes" />

      <CharityDonationsView path="admin/charity/:charityId" />
      <CharityProfileAdminView path="admin/charity/:charityId/profile" />
      <CharityFaqView path="admin/charity/:charityId/faq" />

      <CharitySearchView path="search" />
      <DonationCheckoutView path="checkout/charities" />
      <DonationCheckoutView path="checkout/charity/:charityId" />
      <DonationCheckoutView path="checkout/event/:eventId" />
      <DonationCheckoutView path="checkout/event/:eventId/charity/:charityId" />
      <DonationUserGiftCheckoutView path="checkout/user/:userId" />
      <DonationSuccessView path="checkout/success" />
      <DonationWithGiftSuccessView path="checkout/gift-success" />
      <DonationGiftCheckoutSuccessView path="checkout/user-gift-success" />

      <EventView path="event/:eventId" />
      <EventView path="event/purchase/:purchaseId" />
      <CompaniesView path="admin/companies" />
      <CharitiesView path="admin/charities" />
      <InfluencersView path="admin/influencers" />
      <UsersView path="admin/users" />

      <CompanyAdminView path="admin/company/:companyId" />
      <CompanyProfileAdminView path="admin/company/:companyId/profile" />
      <CompanyReportingView path="admin/company/:companyId/reporting" />
      <CompanyMatchingView path="admin/company/:companyId/matching" />
      <CompanyGiftsView path="admin/company/:companyId/gifts" />
      <CompanyActivityView path="admin/company/:companyId/activity" />
      <PendingInvitesView path="admin/company/:companyId/pending" />
      <PayrollReportView path="admin/company/:companyId/payroll" />
      <WalletView path="admin/company/:companyId/wallet" />

      <CompanyCauzesView path="admin/company/:companyId/cauzes" />
      <CompanyCreateCauzeView path="admin/company/:companyId/cauzes/create" />
      <CompanyCreateCauzeView path="admin/company/:companyId/cauzes/:eventId/edit" />
      <CharityCreateCauzeView path="admin/charity/:charityId/cauzes/create" />
      <CharityCreateCauzeView path="admin/charity/:charityId/cauzes/:eventId/edit" />

      <InfluencerDashboardView path="admin/influencer/:influencerId" />
      <InfluencerCauzesView path="admin/influencer/:influencerId/cauzes" />
      <InfluencerCreateCauzeView path="admin/influencer/:influencerId/cauzes/create" />
      <InfluencerCreateCauzeView path="admin/influencer/:influencerId/cauzes/:eventId/edit" />
      <InfluencerGiftsView path="admin/influencer/:influencerId/gifts" />

      <Redirect
        from="admin/user/:influencerId"
        to="/admin/influencer/:influencerId"
        noThrow
      />
      <Redirect
        from="admin/user/:influencerId/cauzes"
        to="/admin/influencer/:influencerId/cauzes"
        noThrow
      />
      <Redirect
        from="admin/user/:influencerId/cauzes/create"
        to="/admin/influencer/:influencerId/cauzes/create"
        noThrow
      />
      <Redirect
        from="admin/user/:influencerId/cauzes/:eventId/edit"
        to="/admin/influencer/:influencerId//cauzes/:eventId/edit"
        noThrow
      />
      <Redirect
        from="admin/user/:influencerId/gifts"
        to="/admin/influencer/:influencerId/gifts"
        noThrow
      />

      <Redirect from="/" to="/discover" />
      {/* Redirect to homepage if there is no token provided, should not be able to access password reset without a valid token */}
      <Redirect from="reset-password" to="/" noThrow />

      <EmailUnsubscribeView path="emailunsubscription/:email" />
      <GiftLandingView path="redeem" />
      <SponsorInviteView path="match/:id/addsponsor" />
      <MatchSuccessView path="match/:id/success" />
      <SearchView path="donate/search" />
      <DownloadAppView path="download" />
      <CreateAccountView path="signup/:token" />
      <ForgotPasswordView path="resetpassword" />
      <ResetPasswordView path="reset-password/:token" />
      <NotFoundView path="notfound" default />
    </LocationProvider>
  </Router>
);

export default inject('profileStore', 'authStore')(observer(CauzeRouter));
