import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Avatar from '../Avatar/Avatar';
import ImageUploadModal from '../modals/ImageUploadModal/ImageUploadModal';
import ErrorModal from 'components/modals/ErrorModal/';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

const LoadingImage = () => (
  <div className="image-loading-area">
    <CauzeSpinner size="4rem" />
  </div>
);

const CommentInput = ({
  avatar,
  userContext,
  eventId,
  purchaseId,
  store,
  onToggleClose,
  uiStore,
}) => {
  const [previewImageUrl, setPreviewImageUrl] = useState('');
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [isPosting, setIsPosting] = useState(false);

  const showImagePreview =
    previewImageUrl !== '' && !store.uploadingCommentImage;

  const handleSuccess = () => {
    uiStore.showNotification({
      body: 'Your update has been posted successfully.',
      type: 'SUCCESS',
    });
    setPreviewImageUrl('');
    setIsPosting(false);
    onToggleClose(false);
  };

  const handlePost = (ev) => {
    if (store.commentText && store.uploadingCommentImage === false) {
      ev.preventDefault();
      setIsPosting(true);
      store
        .createComment({
          body: store.commentText,
          eventId,
          purchaseId,
          userContext,
          ...store.commentImageData,
        })
        .then(handleSuccess)
        .catch(() => setIsPosting(false));
    }
  };

  return (
    <div className="support-block comment-input flex-column flex-align-center">
      <ImageUploadModal
        updateImageUrl={setPreviewImageUrl}
        store={store}
        onSave={() => {}}
        isActive={showImageUploadModal}
        onToggleClose={() => setShowImageUploadModal(false)}
        cropRatio={[1, 1]}
        src=""
        noPlaceholder
      />
      <ErrorModal
        onToggleClose={store.resetImage}
        errorText="There was an issue uploading the image. Support has been notified."
        isOpen={store.imageUploadError}
      />
      <p className="text-bold comment-title">Update Your Joiners</p>
      <p className="comment-subtitle">
        Let everyone who has joined your cauze know how it’s coming along.
      </p>
      <div style={{ width: '100%' }} className="flex-column flex-space-between">
        <div className="comment-area-container">
          {store.uploadingCommentImage && <LoadingImage />}
          {showImagePreview && (
            <div className="image-container">
              <div className="remove-container">
                <img className="comment-image" src={previewImageUrl} />
                <p
                  onClick={(ev) => {
                    ev.preventDefault();
                    store.resetImage();
                    setPreviewImageUrl('');
                  }}
                  className="image-remove-icon"
                >
                  x
                </p>
              </div>
            </div>
          )}
          <textarea
            className="comment-area"
            value={store.commentText}
            onChange={(ev) => store.setCommentText(ev.target.value)}
            rows="5"
            placeholder="Share your thoughts..."
            cols="100"
          />
        </div>
        <div className="flex-row flex-align-center icon-container flex-space-between">
          <div className="flex-row flex-align-center">
            <>
              <Avatar className="comment-avatar" avatar={avatar} />
              <FontAwesomeIcon
                icon={faCamera}
                className="camera-icon"
                onClick={(ev) => {
                  ev.preventDefault();
                  setShowImageUploadModal(true);
                }}
              />
            </>
          </div>
          <button
            onClick={handlePost}
            disabled={!store.commentText || isPosting}
            className="cz-btn-brand"
          >
            {isPosting ? 'Posting...' : 'Post'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(CommentInput);
