import React from 'react';

import SubmitButton from './SubmitButton';

import useCheckoutStore from 'stores/CheckoutStoreV2';

const ErrorContent = ({ loading, isMobile }) => {
  const checkoutStore = useCheckoutStore();

  return (
    <div className="flex-1 pt-6 lg:pt-0 w-full px-5 flex flex-col justify-between">
      <div className="flex-1 flex-col text-center flex justify-center">
        <h6 className="font-agenda-bold lg:text-2xl text-black leading-none mb-1">
          Your Payment Could Not Be Processed
        </h6>
        <div className="font-agenda text-[15px] leading-none">
          There was an issue processing your transaction. Support has been
          notified.
        </div>
      </div>
      <SubmitButton
        isMobile={isMobile}
        onSubmit={() => {
          checkoutStore.setFrame(checkoutStore.FRAME.CHECKOUT);
        }}
        loading={loading}
      />
    </div>
  );
};

export default ErrorContent;
