import React from 'react';
import { observer, inject } from 'mobx-react';
import { Redirect } from '@reach/router';
import { redirectEntity } from '../util/navHelpers';
import { entitiesMatch, getEntity } from '../util/contextUtils';
import { isEmpty } from 'lodash';

const withAuthRequired = (WrappedComponent, _options) =>
  inject(
    'profileStore',
    'authStore',
  )(
    observer((props) => {
      const activeEntity = props.profileStore.getActiveEntity();
      const pathEntity = getEntity({
        companyId: props.companyId,
        charityId: props.charityId,
        userId: props.userId,
        influencerId: props.influencerId,
      });

      if (!props.authStore.isAuthenticated) {
        if (
          !location.pathname.includes('login') &&
          !location.pathname.includes('signup') &&
          !location.pathname.includes('auth')
        ) {
          props.authStore.stashPathDetails({
            path: location.pathname,
            companyId: props.companyId,
            charityId: props.charityId,
            userId: props.userId,
            influencerId: props.influencerId,
          });
        }
        return <Redirect to="/login" noThrow />;
      }

      if (
        location.pathname.includes('admin/companies') ||
        location.pathname.includes('admin/charities') ||
        location.pathname.includes('admin/influencers') ||
        location.pathname.includes('admin/users')
      ) {
        if (props.profileStore.data.sysAdmin) {
          if (!props.profileStore.activeEntity.sysAdmin) {
            props.profileStore.clearActiveEntity();
          }
          return <WrappedComponent {...props} />;
        }
        return redirectEntity(activeEntity);
      }

      if (entitiesMatch(activeEntity, pathEntity)) {
        return <WrappedComponent {...props} />;
      }

      let entityIx = props.profileStore.availableUserEntities.findIndex(
        (entity) => entitiesMatch(entity, pathEntity),
      );
      if (entityIx !== -1) {
        props.profileStore.setActiveEntity(entityIx);
        return <WrappedComponent {...props} />;
      }

      if (
        props.profileStore.data &&
        props.profileStore.data.sysAdmin &&
        !isEmpty(pathEntity)
      ) {
        props.profileStore.getAdminEntity(pathEntity);
        return null;
      }

      return <WrappedComponent {...props} />;
    }),
  );

export default withAuthRequired;
