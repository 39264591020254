import gql from 'graphql-tag';

const sendGiftsMutation = gql`
  mutation sendGifts(
    $amount: Int!
    $comment: String
    $emails: [String]
    $phone_numbers: [String]
    $user_ids: [ID]
    $company_ids: [ID]
    $gift_type: GiftType!
    $payment_type: PaymentType!
    $user_context: UserContext
    $recurring: Boolean
    $delay_balance_checkout: Boolean
    $pending: Boolean
    $card_brand: String
    $sender_first_name: String
    $sender_last_name: String
    $sender_email: String
  ) {
    sendGifts(
      amount: $amount
      comment: $comment
      emails: $emails
      phoneNumbers: $phone_numbers
      userIds: $user_ids
      companyIds: $company_ids
      giftType: $gift_type
      paymentType: $payment_type
      userContext: $user_context
      recurring: $recurring
      delayBalanceCheckout: $delay_balance_checkout
      pending: $pending
      cardBrand: $card_brand
      senderFirstName: $sender_first_name
      senderLastName: $sender_last_name
      senderEmail: $sender_email
    ) {
      amount
      comment
      token
      splitAmount {
        fee
        forCauze
        total
        prepayFee
      }
    }
  }
`;

const completeGiftCheckoutMutation = gql`
  mutation completeGiftCheckout(
    $card_id: ID
    $stripe_token: String
    $uuid: String!
    $nickname: String
    $paypal_data: PaypalDataInput
    $payment_data: PaymentDataInput
  ) {
    completeGiftCheckout(
      cardId: $card_id
      stripeToken: $stripe_token
      uuid: $uuid
      nickname: $nickname
      paypalData: $paypal_data
      paymentData: $payment_data
    ) {
      amount
      id
      total
    }
  }
`;

const completeGiftCheckoutMutationV2 = gql`
  mutation completeGiftCheckout(
    $uuid: String!
    $paymentData: PaymentDataInput
  ) {
    completeGiftCheckout(uuid: $uuid, paymentData: $paymentData) {
      amount
      id
      total
    }
  }
`;

const plaidLinkTokenQuery = gql`
  query plaidLinktoken {
    plaidLinkToken
  }
`;

export {
  sendGiftsMutation,
  completeGiftCheckoutMutation,
  plaidLinkTokenQuery,
  completeGiftCheckoutMutationV2,
};
