import React from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';

import mergeFeedItems from 'util/feed/mergeFeedItems';
import convertFeedToV1 from 'util/feed/convertFeedToV1';
import FeedItem from 'components/FeedItem/FeedItem';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const GlobalFeed = ({
  donationLandingStore,
  authStore,
  uiStore,
  activeEntity,
  setShowLoginModal,
  title,
  onCheckoutSuccess,
}) => {
  const onLike = ({ feedId }) => {
    if (!authStore.isAuthenticated) {
      uiStore.openModal('CONFIRM', {
        message: 'Please Login or Signup to like this post.',
        action: () => {
          setShowLoginModal(true);
        },
        confirmText: 'Login/Signup',
        cancelText: 'Not Now',
        bodyMessage:
          'Create your own Cauze account and join our community of givers.',
      });
      return;
    }

    const feed = donationLandingStore.recentFeedItems;
    const feedItem = feed?.find(
      (feedItem) => feedItem?.activityPurchase?.id === feedId,
    );

    if (feedItem) {
      donationLandingStore.like({
        like: !feedItem.currentEntityLiked,
        id: feedId,
        purchaseId: feedItem.activityPurchase?.id,
        userContext: activeEntity.userContext,
      });
    }
  };

  let feed = null;

  if (donationLandingStore.loadingFeedItems) {
    feed = (
      <div className="loading-feed-items">
        <CauzeSpinner />
      </div>
    );
  } else if (donationLandingStore.recentFeedItems.length > 0) {
    feed = (
      <div className="feed-wrapper">
        <div className="feed max-[380px]:px-2.5 max-md:px-4">
          {mergeFeedItems(donationLandingStore.recentFeedItems).map(
            (item, index) => (
              <FeedItem
                item={convertFeedToV1(item)}
                key={index}
                index={index}
                onLike={onLike}
                activeEntity={activeEntity}
                uiStore={uiStore}
                onCheckoutSuccess={onCheckoutSuccess}
              />
            ),
          )}
        </div>
        <div className="flex justify-center my-16">
          <CauzeButton
            isSubmitting={donationLandingStore.loadingMore}
            white
            onClick={donationLandingStore.loadMoreRecentFeedItems}
          >
            Load More Activity
          </CauzeButton>
        </div>
      </div>
    );
  }

  return (
    <>
      {Boolean(title) && (
        <div
          className={cx(
            'mb-[10px] font-agenda-bold text-xl lg:block px-4 lg:px-0',
            {
              hidden: authStore.isAuthenticated,
              block: !authStore.isAuthenticated,
            },
          )}
        >
          {title}
        </div>
      )}
      {feed}
    </>
  );
};

export default inject(
  'donationLandingStore',
  'authStore',
  'uiStore',
)(observer(GlobalFeed));
