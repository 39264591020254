const isSameEntity = (entityA, entityB) => {
  if (!entityA || !entityB) {
    return false;
  }

  if (+entityA?.id !== +entityB?.id) {
    return false;
  }

  let entityTypeA =
    entityA?.entityType?.toUpperCase() || entityA?.type?.toUpperCase();
  if (entityTypeA === 'INFLUENCER') {
    entityTypeA = 'USER';
  }

  let entityTypeB =
    entityB?.entityType?.toUpperCase() || entityB?.type?.toUpperCase();
  if (entityTypeB === 'INFLUENCER') {
    entityTypeB = 'USER';
  }

  return entityTypeA === entityTypeB;
};

export { isSameEntity };
