import React from 'react';
import { inject, observer } from 'mobx-react';
import scriptLoader from 'react-async-script-loader';

import PaymentMethodSelect from 'components/PaymentMethodSelect/PaymentMethodSelect';
import CheckoutSummary from 'components/checkout/CheckoutSummary';
import SubmitButton from './SubmitButton';

import useCheckoutStore from 'stores/CheckoutStoreV2';

const ConfirmationContentSummary = ({ loading, uiStore }) => {
  const checkoutStore = useCheckoutStore();

  return (
    <div>
      <PaymentMethodSelect
        store={checkoutStore}
        activePaymentMethod={checkoutStore.activePaymentMethod}
        onClick={() => {
          checkoutStore.setFrame(1);
        }}
        disabled
      />
      <hr className="my-2 h-[2px] bg-lightgray-b2b" />
      <CheckoutSummary
        store={checkoutStore}
        uiStore={uiStore}
        loading={loading}
        activePaymentMethod={checkoutStore.activePaymentMethod}
      />
    </div>
  );
};

const GiftCheckoutContent = ({ uiStore, loading, onSubmit }) => {
  const checkoutStore = useCheckoutStore();

  const labelClass = 'font-agenda-bold font-[16px] text-black leading-tight';
  const inputClass =
    'mt-1 w-full rounded-lg pl-2 text-[17px] font-agenda border-lightgray-c4c border-solid border-[1px] h-[45px]';

  return (
    <div className="flex-1 w-full px-5 flex flex-col justify-between">
      <div>
        <div className="flex justify-center w-full">
          <div className="font-agenda-bold text-[20px] mx-auto py-2">
            Checkout Summary
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-2.5 gap-y-4">
          <div>
            <label className={labelClass}>First Name</label>
            <input
              className={inputClass}
              value={checkoutStore.firstName}
              onChange={(e) => {
                checkoutStore.update({
                  firstName: e.target.value,
                });
              }}
            />
          </div>
          <div>
            <label className={labelClass}>Last Name</label>
            <input
              className={inputClass}
              value={checkoutStore.lastName}
              onChange={(e) => {
                checkoutStore.update({
                  lastName: e.target.value,
                });
              }}
            />
          </div>
          <div className="col-span-2">
            <label className={labelClass}>Email Address*</label>
            <input
              className={inputClass}
              value={checkoutStore.email}
              onChange={(e) => {
                checkoutStore.update({
                  email: e.target.value,
                });
              }}
            />
            <div className="text-[12px] mt-2 font-agenda leading-none mb-5 lg:mb-[30px]">
              We need this to send you your receipt we won’t send you marketing
              emails.
            </div>
          </div>
          <div className="col-span-2">
            <input
              style={{
                height: 'unset',
                marginRight: '1rem',
                marginLeft: '3px',
              }}
              type="checkbox"
              checked={!checkoutStore.emailIsPrivate}
              onChange={() =>
                checkoutStore.update({
                  emailIsPrivate: !checkoutStore.emailIsPrivate,
                })
              }
            />
            Share my email with{' '}
            {checkoutStore.charityIds.length > 1 ? 'these' : 'this'} nonprofit
            {checkoutStore.charityIds.length > 1 ? 's' : ''}.
          </div>
          <div className="col-span-2">
            <label className={labelClass}>Message</label>
            <textarea
              onInput={(input) =>
                checkoutStore.update({
                  comment: input.currentTarget.value,
                })
              }
              className={`${inputClass} p-2 !h-auto`}
              placeholder="Message"
              rows={3}
              cols="70"
            ></textarea>
          </div>
          <div></div>
        </div>
        <ConfirmationContentSummary loading={loading} uiStore={uiStore} />
        <p className="text-xs font-agenda">
          Your donation is being made to the Cauze Charitable Fund, a tax-exempt
          US 501c(3) charity that grants funds to charities on your behalf.
          <a href="https://www.cauze.com/terms-of-service"> SEE TERMS</a>.
        </p>
      </div>
      <div>
        <div>
          <SubmitButton onSubmit={onSubmit} isMobile />
        </div>
      </div>
    </div>
  );
};

export default scriptLoader(
  'https://www.google.com/recaptcha/api.js?render=6Lc-e08jAAAAAEZ7BSXi10cVWgb2G_K2FoxSGsBD',
)(inject('uiStore', 'authStore')(observer(GiftCheckoutContent)));
