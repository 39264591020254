import { PaymentElement } from '@stripe/react-stripe-js';
import { inject, observer } from 'mobx-react';

import stripeOptions from 'config/stripe';
import useCheckoutStore from 'stores/CheckoutStoreV2';

const AddCard = ({
  onAddCard,
  showAddCard,
  setShowAddCard,
  isCheckout,
  defaultOpen,
  authStore,
}) => {
  const checkoutStore = useCheckoutStore();

  return (
    <div>
      {!defaultOpen && (
        <button
          className="cz-btn-outline black w-full text-[13px] rounded-[5px] mb-2"
          type="button"
          onClick={onAddCard || (() => setShowAddCard(!showAddCard))}
        >
          {authStore.isAuthenticated ? 'ADD NEW CARD' : 'USE CREDIT CARD'}
        </button>
      )}
      {(showAddCard || defaultOpen) && (
        <>
          <PaymentElement
            options={stripeOptions.cardElement}
            onChange={(e) => {
              checkoutStore.update({
                addCardIsComplete: e.complete,
              });
            }}
          />
          {!isCheckout && (
            <button
              style={{ marginTop: '0.5rem', marginBottom: '2rem' }}
              onClick={onAddCard}
              className="cz-btn-black w-full"
            >
              ADD CARD
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default inject('authStore')(observer(AddCard));
