import React, { useMemo } from 'react';
import cx from 'classnames';

import { ReactComponent as WalletIcon } from 'assets/images/icons/wallet/wallet.svg';
import { ReactComponent as RightChevronIcon } from 'assets/images/icons/chevron/right-chevron.svg';
import Currency from 'components/Currency/Currency';
import useWalletStore from 'stores/WalletStoreV2';
import useCheckoutStore from 'stores/CheckoutStoreV2';

const PaymentMethodSelect = ({ onClick, activePaymentMethod, disabled }) => {
  const store = useCheckoutStore();
  const { balance } = useWalletStore();

  const containerClassName = cx(
    'flex justify-between font-agenda p-2 rounded-[10px]',
    {
      'hover:bg-lightgray-e8e cursor-pointer': !disabled,
      'text-lightgray-b2b cursor-progress': disabled,
    },
  );

  const paymentMethodName = useMemo(() => {
    const paymentType = store.getPaymentType().toUpperCase();

    if (paymentType === 'BALANCE') {
      return (
        <>
          Balance (<Currency showCents amount={balance} />)
        </>
      );
    }

    if (paymentType === 'APPLE_PAY') {
      return <>Apple Pay</>;
    }

    if (paymentType === 'GOOGLE_PAY') {
      return <>Google Pay</>;
    }

    if (paymentType === 'PAYPAL') {
      return <>Paypal/Venmo</>;
    }

    if (paymentType === 'GIFT_TOKEN') {
      return (
        <>
          Gift (<Currency showCents amount={store.amount} />)
        </>
      );
    }

    if (!activePaymentMethod) {
      return (
        <span className="font-agenda-bold text-[16px] text-cauze-dark">
          Required
        </span>
      );
    }

    return (
      <>
        {activePaymentMethod.brand} {activePaymentMethod.last4}
      </>
    );
  }, [store, activePaymentMethod]);

  return (
    <div
      className={containerClassName}
      onClick={disabled ? undefined : onClick}
    >
      <div className="flex gap-x-2.5">
        <span className="my-auto h-5">
          <WalletIcon />
        </span>
        <div className="leading-[100%] mt-auto">
          <span className="inline-block align-middle leading-none">
            Payment Method
          </span>
        </div>
      </div>
      <div className="flex gap-x-2.5 my-auto">
        <div className="font-agenda-bold capitalize leading-[100%] mt-auto">
          {paymentMethodName}
        </div>
        <div className="h-5">
          <RightChevronIcon />
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodSelect;
