import React, { useState } from 'react';

import AmountSelector from 'components/AmountSelector/AmountSelectorMini';
import Avatar from 'components/Avatar/Avatar';
import PaymentMethodSelect from 'components/PaymentMethodSelect/PaymentMethodSelect';
import CheckoutSummary from 'components/checkout/CheckoutSummary';
import SubmitButton from './SubmitButton';

import useCheckoutStore from 'stores/CheckoutStoreV2';
import CommentInput from './CommentInput';
import MatchList from './MatchList';
import EmployeeMatchToggle from './EmployeeMatchToggle';
import JoinedEntity from './JoinedEntity';
import EmailListInput from 'components/EmailListInput/EmailListInput';

const AddFundsContent = ({
  defaultAmount = 500,
  uiStore,
  authStore,
  loading,
  onSubmit,
  isMobile,
}) => {
  const [hasValidationError, setHasValidationError] = useState(true);
  const checkoutStore = useCheckoutStore();
  const isSelfGift = checkoutStore.giftType === 'SELF_GIFT';
  const isUserGift = checkoutStore.giftType === 'USER_GIFT';
  const isEmployeeGift = checkoutStore.giftType === 'EMPLOYEE_GIFT';
  const isInvite = isEmployeeGift && checkoutStore.isInviteFollowerExperience;
  const isEmails = checkoutStore.isEmails;

  const getTitle = () => {
    if (checkoutStore.checkoutType === 'donation') {
      return 'Your Donation';
    }

    if (isInvite) {
      return 'Add Employees';
    }

    if (isSelfGift) {
      return 'Add Funds';
    }

    return 'Send a Gift';
  };

  const showPaymentMethodSelect = () => {
    let show = authStore.isAuthenticated && !isMobile;
    return show;
  };

  return (
    <div className="flex-1 w-full px-5 flex flex-col justify-between">
      <div>
        <div className="flex justify-center w-full">
          <div className="font-agenda-bold text-[20px] mx-auto py-2">
            {getTitle()}
          </div>
        </div>
        {checkoutStore.checkoutType === 'gift' &&
          !isEmails &&
          checkoutStore.giftType !== 'SELF_GIFT' && (
            <div className="gift-avatar-container w-full flex flex-col items-center justify-center mt-3 mb-5">
              <Avatar
                md
                avatarUrls={checkoutStore.userProfileData?.avatarUrls}
              />
              <div className="font-agenda-semibold text-[14px] mt-2.5">
                {checkoutStore.userProfileData?.firstName}{' '}
                {checkoutStore.userProfileData?.lastName}
              </div>
            </div>
          )}
        {checkoutStore.checkoutType ===
          checkoutStore.CHECKOUT_TYPE.DONATION && (
          <div className="text-center font-agenda text-[15px] leading-tight pb-4">
            Only the nonprofit can see how much you’ve given.
          </div>
        )}
        {checkoutStore.isEmails && (
          <div className="mb-5">
            <label className="font-agenda-bold text-[16px] leading-none">
              Send a gift to any email address
            </label>
            <div className="h-2"></div>
            <EmailListInput
              setHasValidationError={(valid) => setHasValidationError(valid)}
              existingEmails={checkoutStore.existingEmails}
              updateEmails={(emails) => {
                checkoutStore.update({ emails });
              }}
              baseEmailInputText={checkoutStore.emails?.join(',')}
            />
          </div>
        )}
        <AmountSelector
          placeholder="$ custom amount"
          defaultAmount={checkoutStore.amount || defaultAmount || 0}
          amounts={[100, 500, 1000, 2000]}
          selectedAmount={checkoutStore.amount}
          onAmountUpdate={(amt) => {
            checkoutStore.update({
              amount: amt,
            });
            checkoutStore.updateCheckout();
          }}
          onEnter={isMobile ? onSubmit : null}
        />
        {checkoutStore.giftType !== 'SELF_GIFT' && <CommentInput />}
      </div>
      <div>
        <div>
          {showPaymentMethodSelect() && (
            <>
              <PaymentMethodSelect
                activePaymentMethod={checkoutStore.getActivePaymentMethod()}
                onClick={() => {
                  checkoutStore.setFrame(checkoutStore.FRAME.PAYMENT_METHOD);
                }}
                disabled={loading}
              />
              <EmployeeMatchToggle />
              {checkoutStore.getActivePaymentMethod() && (
                <>
                  <hr className="my-2 h-[2px] bg-lightgray-b2b" />
                  <div className="min-h-16 mb-5">
                    <CheckoutSummary
                      uiStore={uiStore}
                      loading={loading}
                      activePaymentMethod={checkoutStore.getActivePaymentMethod()}
                    />
                  </div>
                </>
              )}
            </>
          )}
          <SubmitButton
            isMobile={isMobile}
            onSubmit={onSubmit}
            loading={loading}
            disabled={hasValidationError && isEmails}
          />
          <MatchList matches={checkoutStore.matches} />
          <JoinedEntity entity={checkoutStore.joinedEntity} />
        </div>
      </div>
    </div>
  );
};

export default AddFundsContent;
