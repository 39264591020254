import { create } from 'zustand';
import { client as apolloClient } from 'util/apolloClient';
import { client as api2Client, activeEntityToHeaders } from 'util/api2Client';
import { isEqual } from 'lodash';

import {
  notificationsQuery,
  markNotificationReadMutation,
  unreadNotificationCountQuery,
  markAllNotificationsReadV2Mutation,
} from 'graphql/feedNotifications';
import authStore from './AuthStore';

const initialState = {
  notifications: {},
  unreadCount: {},
  loading: {},
  lastActiveEntity: {},
};

const useFeedNotificationStore = create((set, get) => ({
  ...initialState,
  setLastActiveEntity: (lastActiveEntity) => set({ lastActiveEntity }),
  getEntityKey: (entity) => {
    let entityType = entity?.entityType;

    if (entityType?.toUpperCase?.() === 'INFLUENCER') {
      entityType = 'USER';
    }

    return `${entityType}-${entity?.id}`;
  },
  getUnreadCount: () => {
    const state = get();
    const unreadCountSum = Object.values(state.unreadCount).reduce(
      (a, b) => a + b,
      0,
    );
    return unreadCountSum;
  },
  fetchUnreadCount: async (entity) => {
    const variables = {
      page: 1,
      pageSize: 50,
    };

    const options = {
      query: unreadNotificationCountQuery,
      variables,
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
      context: {
        headers: activeEntityToHeaders(entity),
      },
    };

    const queryData = await api2Client.query(options);

    return queryData.data.notificationFeed.unreadCount ?? 0;
  },
  getNotifications: async (activeEntity, skipCache = false) => {
    const state = get();
    const notifKey = state.getEntityKey(activeEntity);

    if (!isEqual(state.lastActiveEntity, activeEntity)) {
      set({
        unreadCount: {
          ...state.unreadCount,
        },
        lastActiveEntity: activeEntity,
      });
    }

    if (state.notifications[notifKey] && !skipCache) {
      return state.notifications[notifKey];
    }

    let userContext = { ...activeEntity?.userContext };

    if (!activeEntity?.userContext) {
      let key = 'userId';

      if (activeEntity?.entityType === 'COMPANY') {
        key = 'companyId';
      } else if (activeEntity?.entityType === 'CHARITY') {
        key = 'charityId';
      }

      userContext = {
        [key]: activeEntity.id,
      };
    }

    if (userContext.influencerId) {
      userContext.userId = userContext.influencerId;
      delete userContext.influencerId;
    }

    if (!authStore.isAuthenticated) {
      return;
    }

    set({
      loading: {
        ...state.loading,
        [notifKey]: true,
      },
    });

    const variables = {
      page: 1,
      pageSize: 50,
    };

    const options = {
      query: notificationsQuery,
      variables,
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
      context: {
        headers: activeEntityToHeaders(activeEntity),
      },
    };

    const queryData = await api2Client.query(options);

    const notifications = {
      ...state.notifications,
      [notifKey]: queryData.data.notificationFeed?.items || [],
    };

    const unreadCount = {
      ...state.unreadCount,
      [notifKey]: queryData.data.notificationFeed?.unreadCount || 0,
    };

    set({
      notifications,
      unreadCount,
      loading: {
        ...state.loading,
        [notifKey]: false,
      },
    });

    return notifications[notifKey];
  },
  getAllNotifications: async (entities) => {
    const state = get();

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      await state.getNotifications(entity);
    }

    return get().notifications;
  },
  markAllRead: (activeEntity) => {
    const state = get();
    const notifKey = state.getEntityKey(activeEntity);

    const readNotifications = state.notifications[notifKey].map(
      (notification) => ({
        ...notification,
        read: true,
      }),
    );

    set({
      notifications: {
        ...state.notifications,
        [notifKey]: readNotifications,
      },
      unreadCount: {
        ...state.unreadCount,
        [notifKey]: 0,
      },
    });

    const options = {
      mutation: markAllNotificationsReadV2Mutation,
      variables: {},
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
      context: {
        headers: activeEntityToHeaders(activeEntity),
      },
    };

    api2Client.mutate(options);
  },
  pollNotifications: async (entities) => {
    const state = get();

    const unreadCountMap = state.unreadCount;

    // for each entity, check if they have unread notifications
    // if so, update notifications for that entity
    // else do nothing
    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i];
      const notifKey = state.getEntityKey(entity);
      const unreadCount = unreadCountMap[notifKey];

      try {
        const count = await state.fetchUnreadCount(entity);

        if (unreadCount !== count) {
          unreadCountMap[notifKey] = count;
          await state.getNotifications(entity, true);
        }
      } catch (err) {
        console.log(err);
      }
    }

    set({ unreadCount: unreadCountMap });
  },
  markNotificationRead: (id, index) => {
    const state = get();
    const activeEntity = state.lastActiveEntity;

    let notifKey = state.getEntityKey(activeEntity);
    let notifications = state.notifications[notifKey];
    let unreadCount = state.unreadCount[notifKey];

    if (!notifications) {
      const allNotifications = Object.entries(state.notifications);

      for (let i = 0; i < allNotifications.length; i++) {
        const [key, notifs] = allNotifications[i];
        if (notifs[index]?.id === id) {
          notifKey = key;
          notifications = notifs;
          unreadCount = state.unreadCount[notifKey];
          break;
        }
      }
    }

    if (notifications[index].read) {
      return;
    }
    notifications[index].read = true;
    if (unreadCount > 0) unreadCount--;

    const options = {
      mutation: markNotificationReadMutation,
      variables: { id },
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options);

    set({
      notifications: {
        ...state.notifications,
        [notifKey]: notifications,
      },
      unreadCount: {
        ...state.unreadCount,
        [notifKey]: unreadCount,
      },
    });
  },
}));

export default useFeedNotificationStore;
