import { useEffect, useState } from 'react';
import cx from 'classnames';

import Avatar from 'components/Avatar/Avatar';
import LargeNumber from 'components/LargeNumber/LargeNumber';
import { EventListWithArrowsAbove } from 'components/EventList/EventList';

import { navigate, Link } from '@reach/router';
import useDashboardStore from 'stores/DashboardStore';
import { LOADING_SCREENS } from 'util/constants';

export const DashboardCard = ({
  className,
  children,
  title,
  titleSize,
  left,
}) => {
  return (
    <div
      className={cx(
        className,
        'p-5 flex flex-col gap-y-5 border border-solid border-lightgray-f1f rounded-[10px]',
      )}
    >
      <div className="flex">
        {left}
        <h3
          className={cx('font-agenda-bold text-2xl leading-none my-auto', {
            'text-2xl': !titleSize,
            [titleSize]: titleSize,
          })}
        >
          {title}
        </h3>
      </div>
      {children}
    </div>
  );
};

export const YourAccountsCard = ({
  avatar,
  name,
  entityType,
  id,
  userProfileStore,
  companyStore,
  charityStore,
  cauzeStore,
  amounts = [],
  userContext,
  activeEntity,
  index,
  profileStore,
  noSwitch = false,
}) => {
  const { showLoadingScreen } = useDashboardStore();
  const [balance, setBalance] = useState(amounts[0]);
  const [amountGiven, setAmountGiven] = useState(0);
  const [followerCount, setFollowerCount] = useState(0);

  const isUser = entityType === 'USER' || entityType === 'INFLUENCER';
  const isCompany = entityType === 'COMPANY';
  const isCharity = entityType === 'CHARITY';

  useEffect(() => {
    const asyncHandler = async () => {
      if (isUser) {
        let userData = userProfileStore.users.get(+id);

        if (!userData) {
          userData = await userProfileStore.getUser({ id });
        }

        setAmountGiven(userData?.profileData?.userGood?.alltimeGoodSum || 0);
        setFollowerCount(userData?.profileData?.followerCount);
      } else if (isCompany) {
        let companyData = companyStore.companies.get(+id);

        if (!companyData) {
          companyData = await companyStore.getCompany({
            id,
            userContext,
            silent: true,
          });
        }

        const totals = await cauzeStore.fetchTotals({ userContext });

        setBalance(amounts[0]);
        setAmountGiven(totals.totalCauzes);
        setFollowerCount(companyData?.followerCount || 0);
      } else if (isCharity) {
        let charityData = charityStore.charities.get(+id);

        if (!charityData) {
          charityData = await charityStore.getCharity({
            id,
            userContext,
            silent: true,
          });
        }

        const totals = await cauzeStore.fetchTotals({ userContext });

        setAmountGiven(totals.totalCauzes);
        setBalance(charityData?.donationAmount || 0);
        setFollowerCount(charityData?.followerCount || 0);
      }
    };

    asyncHandler();
  }, []);

  useEffect(() => {
    if (balance === undefined && isUser) {
      setBalance(amounts[0]);
    }
  }, [amounts]);

  let href = '';
  if (entityType === 'CHARITY') {
    href = `/admin/charity/${id}`;
  } else if (entityType === 'COMPANY') {
    href = `/admin/company/${id}`;
  } else {
    href = `/admin/user/${id}/activity`;
  }

  return (
    <Link
      to={href}
      className="text-inherit"
      onMouseDown={(e) => {
        e.preventDefault();

        if (!noSwitch) {
          showLoadingScreen(LOADING_SCREENS.CONTEXT_SWITCH, {
            avatar,
            entityType,
            name,
            id,
            prevContext: activeEntity,
          });

          if (index === 0) {
            profileStore.clearActiveEntity();
          } else {
            profileStore.setActiveEntity(index, true);
          }
        }

        navigate(href);
      }}
    >
      <DashboardCard
        title={name}
        className="cursor-pointer hover:bg-lightgray-f7f active:bg-lightgray-f1f"
        titleSize="text-[16px]"
        left={<Avatar noBorder className="mr-[10px]" avatar={avatar} />}
      >
        <div className="flex justify-between">
          <div>
            <div className="font-agenda-bold text-[22px] h-[22px] leading-none">
              <LargeNumber isCurrency amount={(balance ?? 0) / 100} />
            </div>
            <div className="font-agenda text-sm text-text-light">
              {isUser || isCompany ? 'Balance' : 'Raised'}
            </div>
          </div>
          <div>
            <div className="font-agenda-bold text-[22px] h-[22px] leading-none">
              <LargeNumber
                isCurrency={isUser}
                amount={isUser ? (amountGiven ?? 0) / 100 : amountGiven}
              />
            </div>
            <div className="font-agenda text-sm text-text-light">
              {isUser ? 'Amount Given' : 'Cauzes Started'}
            </div>
          </div>
          <div>
            <div className="font-agenda-bold text-[22px] h-[22px] leading-none">
              {followerCount ?? 0}
            </div>
            <div className="font-agenda text-sm text-text-light">Followers</div>
          </div>
        </div>
      </DashboardCard>
    </Link>
  );
};

export const CauzesSection = ({
  events,
  title,
  loading = false,
  viewAllHref,
  uiStore,
}) => {
  return (
    <div>
      <EventListWithArrowsAbove
        title={title}
        events={events}
        uiStore={uiStore}
        loading={loading}
        viewAllHref={viewAllHref}
      />
    </div>
  );
};

export const MobileTabs = ({ tabIndex = 0, setTabIndex }) => {
  const buttonClassName =
    'uppercase font-agenda-black text-[17px] h-[33px] bg-transparent leading-none border-0 p-0 border-b-4 border-solid';

  return (
    <div className="flex justify-center gap-x-6">
      <button
        onClick={() => {
          setTabIndex(0);
        }}
        className={cx(buttonClassName, {
          'text-cauze-primary border-cauze-primary': tabIndex === 0,
          'text-gray-3d3 border-transparent': tabIndex !== 0,
        })}
      >
        DASHBOARD
      </button>
      <button
        onClick={() => {
          setTabIndex(1);
        }}
        className={cx(buttonClassName, {
          'text-cauze-primary border-cauze-primary border-b-solid':
            tabIndex === 1,
          'text-gray-3d3 border-transparent': tabIndex !== 1,
        })}
      >
        FEED
      </button>
    </div>
  );
};
