import React, { useEffect } from 'react';

import CreateCauzeView from './CreateCauzeView';
import useCreateCauze from 'hooks/useCreateCauze';
import withAuthRequired from 'behaviors/withAuthRequired';
import useDashboardStore from 'stores/DashboardStore';

const CompanyCreateCauzeView = ({ companyId, eventId }) => {
  const { setProps } = useDashboardStore();
  useCreateCauze({ userContext: { companyId }, eventId });

  useEffect(() => {
    setProps({
      size: 'screen',
    });
  }, []);

  return <CreateCauzeView userContext={{ companyId }} eventId={eventId} />;
};

export default withAuthRequired(CompanyCreateCauzeView, {
  entityType: 'COMPANY',
});
