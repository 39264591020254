import gql from 'graphql-tag';

const checkoutDetailsQuery = gql`
  query checkoutDetails($charityIds: [Int!], $eventId: Int) {
    checkoutDetails(charityIds: $charityIds, eventId: $eventId) {
      donationAmounts
      charities {
        name
        id
        currentEntityIsFollowing
        category
        avatarUrls {
          sm
          md
          lg
        }
        image
      }
      event {
        id
        currentEntityIsFollowing
        name
        shareLink
        image {
          sm
          md
          lg
          full
        }
        externalImageUrl
      }
      matches {
        active
        matchType
        paymentTypes
        description
        currentEntityRemaining
        totalRemaining
        id
        multiplier
        matchLimit
        matchAdmin {
          isCurrentEntity
          isSelfFollowing
          name
          id
          type
          avatarUrls {
            sm
            md
            lg
          }
        }
        matchSponsors {
          isCurrentEntity
          isSelfFollowing
          name
          id
          subtitle
          remaining
          type
          avatarUrls {
            sm
            md
            lg
          }
        }
      }
      paymentOptions {
        balance {
          ... on Balance {
            id
            allowNegative
            total
          }
          ... on CompanyBalance {
            id
            allowNegative
            total
          }
        }
        paymentMethods {
          accountType
          id
          last4
          brand
          nickname
          stripeCardId
          isDefault
        }
        paymentType
      }
    }
  }
`;

const startDonationCheckoutMutation = gql`
  mutation startDonationCheckout($request: StartDonationCheckoutRequest!) {
    startDonationCheckout(request: $request) {
      token
      paymentIntentId
      clientSecret
      splitAmount {
        fee
        remaining
        total
      }
      donationAmount
      createMatchDetails {
        matchTotal
      }
    }
  }
`;

const editDonationCheckoutMutation = gql`
  mutation editDonationCheckout($request: EditDonationCheckoutRequest!) {
    editDonationCheckout(request: $request) {
      paymentIntentId
      clientSecret
      splitAmount {
        fee
        remaining
        total
      }
      donationAmount
    }
  }
`;

const completeDonationCheckout = gql`
  mutation completeDonationCheckout(
    $request: CompleteDonationCheckoutRequest!
  ) {
    completeDonationCheckout(request: $request) {
      cardLast4
      cardBrand
      matchSponsors {
        avatarUrls {
          sm
          md
        }
        type
        name
      }
      purchase {
        id
        shareLink
        userAmount
        matchAmount
        actor {
          type
          name
          avatarUrls {
            md
          }
        }
        image {
          md
        }
        donations {
          matchAmount
          projectAvatarUrls {
            sm
            md
            lg
          }
          donationAmount
          userAmount
          project {
            id
            name
            defaultThanks
            currentEntityIsFollowing
          }
        }
        referrer {
          avatarUrls {
            sm
            md
          }
          type
          currentEntityIsFollowing
          id
          name
        }
      }
    }
  }
`;

const unauthCompleteDonationCheckout = gql`
  mutation completeDonationCheckout(
    $request: CompleteDonationCheckoutRequest!
  ) {
    completeDonationCheckout(request: $request) {
      cardLast4
      cardBrand
      matchSponsors {
        avatarUrls {
          sm
          md
        }
        type
        name
      }
      purchase {
        id
        userAmount
        matchAmount
        actor {
          type
          name
          avatarUrls {
            md
          }
        }
        image {
          md
        }
        donations {
          matchAmount
          projectAvatarUrls {
            sm
            md
            lg
          }
          donationAmount
          userAmount
          project {
            id
            name
            defaultThanks
            currentEntityIsFollowing
          }
        }
        referrer {
          avatarUrls {
            sm
            md
          }
          type
          currentEntityIsFollowing
          id
          name
        }
      }
    }
  }
`;

const checkoutQuery = gql`
  query checkoutToken($uuid: String) {
    checkoutToken(uuid: $uuid) {
      redeemed
    }
  }
`;

const unauthedCheckoutDetailsQuery = gql`
  query checkoutDetails(
    $charity_ids: ID
    $event_id: ID
    $user_context: UserContext
    $user_token: String
  ) {
    checkoutDetails(
      charityIds: $charity_ids
      eventId: $event_id
      userContext: $user_context
      userToken: $user_token
    ) {
      donationAmounts
      charities {
        name
        id
        city
        state
        bio
        category
        avatar {
          sm
          md
          lg
        }
        image {
          full
        }
      }
      event {
        name
        shareLink
        description
        hosts {
          name
        }
        image {
          sm
          md
        }
      }
      matches {
        active
        matchType
        paymentTypes
        description
        totalRemaining
        id
        userMatchLimit
        multiplier
        matchSponsors {
          name
          id
          subtitle
          entityType
          avatar {
            sm
            md
            lg
          }
        }
      }
      paymentOptions {
        balance {
          total
        }
        cards {
          id
          last4
          brand
          nickname
          accountType
          feeDescription
        }
        paymentType
        feeDescription
      }
      notificationPreferences {
        active
        channel
        category
      }
    }
  }
`;

const unauthedStartDonationCheckoutMutation = gql`
  mutation startDonationCheckout(
    $donation_amount: Int!
    $charity_id: ID
    $charity_ids: [ID]
    $comment: String
    $comment_image_id: ID
    $event_id: ID
    $event_name: String
    $join_purchase_id: ID
    $match_ids: [ID]
    $payment_type: PaymentType!
    $preview_url: String
    $referral_link: String
    $referral_path: String
    $user_context: UserContext
    $user_token: String
    $referrer_context: UserContext
    $user_details: UserDetails
    $platform: PlatformType
    $email_is_private: Boolean
    $card_brand: String
  ) {
    startDonationCheckout(
      donationAmount: $donation_amount
      charityId: $charity_id
      charityIds: $charity_ids
      comment: $comment
      commentImageId: $comment_image_id
      eventId: $event_id
      eventName: $event_name
      joinPurchaseId: $join_purchase_id
      matchIds: $match_ids
      paymentType: $payment_type
      previewUrl: $preview_url
      referralLink: $referral_link
      referralPath: $referral_path
      userContext: $user_context
      userToken: $user_token
      referrerContext: $referrer_context
      userDetails: $user_details
      platform: $platform
      emailIsPrivate: $email_is_private
      cardBrand: $card_brand
    ) {
      token
      splitAmount {
        fee
        forCauze
        total
      }
    }
  }
`;

const unauthedCompleteDonationCheckout = gql`
  mutation completeDonationCheckout(
    $card_id: ID
    $stripe_token: String
    $token: String!
    $nickname: String
    $paypal_data: PaypalDataInput
    $user_token: String
    $payment_data: PaymentDataInput
    $recaptcha_token: String
  ) {
    completeDonationCheckout(
      token: $token
      stripeToken: $stripe_token
      cardId: $card_id
      nickname: $nickname
      paypalData: $paypal_data
      userToken: $user_token
      paymentData: $payment_data
      recaptchaToken: $recaptcha_token
    ) {
      matchSponsors {
        avatar {
          sm
          md
        }
        entityType
        name
        matchAmount
      }
      rewardAmount
      rewardSponsor {
        name
        id
        subtitle
        entityType
        avatar {
          sm
          md
          lg
        }
      }
      purchase {
        id
        shareLink
        userAmount
        matchAmount
        donations {
          matchAmount
          charityAvatar {
            sm
            md
            lg
          }
          donationAmount
          userAmount
          project {
            name
            defaultThanks
          }
        }
      }
    }
  }
`;

const createPaymentIntentMutation = gql`
  mutation createPaymentIntent(
    $amount: Int!
    $paymentMethod: String
    $savePaymentMethod: Boolean
    $customerId: String
  ) {
    createPaymentIntent(
      amount: $amount
      paymentMethod: $paymentMethod
      savePaymentMethod: $savePaymentMethod
      customerId: $customerId
    ) {
      id
      clientSecret
    }
  }
`;

const updatePaymentIntentMutation = gql`
  mutation updatePaymentIntent(
    $amount: Int!
    $intentId: String!
    $paymentMethod: String
    $customerId: String
  ) {
    updatePaymentIntent(
      amount: $amount
      intentId: $intentId
      paymentMethod: $paymentMethod
      customerId: $customerId
    ) {
      id
      clientSecret
    }
  }
`;

const getPaymentAmountQuery = gql`
  query getPaymentAmountQuery(
    $amount: Int!
    $paymentType: PaymentType!
    $cardBrand: String
  ) {
    paymentAmount(
      amount: $amount
      paymentType: $paymentType
      cardBrand: $cardBrand
    ) {
      total
      fee
      prepayFee
      remaining
    }
  }
`;

export {
  checkoutDetailsQuery,
  getPaymentAmountQuery,
  startDonationCheckoutMutation,
  completeDonationCheckout,
  checkoutQuery,
  unauthedCheckoutDetailsQuery,
  unauthedStartDonationCheckoutMutation,
  unauthedCompleteDonationCheckout,
  createPaymentIntentMutation,
  updatePaymentIntentMutation,
  unauthCompleteDonationCheckout,
  editDonationCheckoutMutation,
};
