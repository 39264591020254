import { navigate } from '@reach/router';
import { getEntityUrl } from 'util/navHelpers';

const getLegacyCardNav = (item, navigation, markNotificationRead) => {
  const eventId = item.event?.id;
  const entity = item.actor;
  const entityUrl = getEntityUrl({
    id: entity.id,
    entityType: entity.type || entity.__typename,
  });

  markNotificationRead();

  switch (item.type) {
    case 'USER_FOLLOWER': {
      return navigate(entityUrl);
    }
    case 'CAUZE_PURCHASE':
      if (eventId) {
        return navigate(`/event/${eventId}`);
      }
      return;
    case 'USER_GIFT':
      return navigate(entityUrl);
    case 'EMPLOYEE_GIFT':
      return navigate(entityUrl);

    default:
      return null;
  }
};

const getLegacyVerbByType = (type) => {
  switch (type) {
    case 'USER_FOLLOWER':
      return 'followed you';

    case 'CAUZE_PURCHASE':
      return 'supported';

    case 'CAUZE_COMMENT':
      return 'commented on your post for';

    case 'USER_GIFT':
      return 'gifted you';

    case 'EMPLOYEE_GIFT':
      return 'gifted you';

    default:
      return '';
  }
};

export { getLegacyCardNav, getLegacyVerbByType };
