import React from 'react';
import { inject, observer } from 'mobx-react';

import { ReactComponent as CauzeLogo } from 'assets/images/brand/cauze-logo.svg';
import Avatar from 'components/Avatar/Avatar';
import useCheckoutStore from 'stores/CheckoutStoreV2';

const CheckoutCompleteContent = ({ uiStore, authStore }) => {
  const checkoutStore = useCheckoutStore();
  const joinedEntity = checkoutStore?.joinedEntity;

  return (
    <div className="flex-1 w-full px-5 pt-5 flex flex-col justify-between">
      <div className="flex flex-col gap-y-5 mb-5">
        <div className="flex justify-center">
          <CauzeLogo />
        </div>
        <div className="text-center text-gray-1b2 font-agenda-black text-[24px]">
          CONFIRMED!
        </div>
        <div className="text-center text-gray-1b2 font-agenda text-[20px] leading-tight">
          Your generous donation is on its way! Keep the good vibes going and
          inspire the next changemaker.{' '}
        </div>
        {joinedEntity && !authStore.isAuthenticated && (
          <div className="flex gap-x-2.5 mx-auto">
            <Avatar className="my-auto" avatarUrls={joinedEntity?.avatarUrls} />
            <div className="my-auto font-agenda-bold leading-none text-[14px]">
              Create an account to follow {joinedEntity?.name}.
            </div>
          </div>
        )}
      </div>
      <div className="mt-auto flex flex-col gap-y-2.5">
        {!authStore.isAuthenticated && (
          <a href="/signup" className="cz-btn-brand text-center">
            CREATE AN ACCOUNT
          </a>
        )}
        <button
          className="cz-btn-outline black"
          onClick={() => {
            uiStore.closeModal();
            checkoutStore.reset();
          }}
        >
          {authStore.isAuthenticated ? 'CLOSE' : 'NO THANKS'}
        </button>
      </div>
    </div>
  );
};

export default inject(
  'uiStore',
  'authStore',
)(observer(CheckoutCompleteContent));
