import { useMemo } from 'react';

import useCheckoutStore from 'stores/CheckoutStoreV2';
import CauzeSwitch from 'components/CauzeSwitch/CauzeSwitch';
import Currency from 'components/Currency/Currency';

const EmployeeMatchToggle = () => {
  const checkoutStore = useCheckoutStore();

  const employerMatch = useMemo(() => {
    return checkoutStore.matches?.find(
      (match) => match.matchType === 'COMPANY' && match.active,
    );
  }, [checkoutStore.matches]);

  if (!employerMatch?.currentEntityRemaining) return <></>;

  return (
    <div className="flex justify-between font-agenda mt-2.5">
      <div className="flex gap-x-2.5 my-auto">
        <CauzeSwitch
          isSelected={checkoutStore.matchIds?.includes(employerMatch.id)}
          onChange={(e) => {
            if (e.target.checked) {
              checkoutStore.update({
                matchIds: [...checkoutStore.matchIds, employerMatch.id],
              });
            } else {
              checkoutStore.update({
                matchIds: checkoutStore.matchIds.filter(
                  (id) => id !== employerMatch.id,
                ),
              });
            }
          }}
        />
        <div className="leading-none my-auto">Use Employer Match</div>
      </div>
      <div className="my-auto font-agenda-bold leading-none">
        <Currency amount={employerMatch.currentEntityRemaining} /> Available
      </div>
    </div>
  );
};

export default EmployeeMatchToggle;
