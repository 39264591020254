import React from 'react';
import { ExpressCheckoutElement } from '@stripe/react-stripe-js';

const PaymentRequestButton = ({ store }) => {
  const onClick = ({ resolve }) => {
    const options = {
      emailRequired: true,
    };
    resolve(options);
  };

  return (
    <div className="apple-pay-container">
      <ExpressCheckoutElement
        onClick={onClick}
        onConfirm={store.onApplePayOrderConfirm}
      />
    </div>
  );
};

export default PaymentRequestButton;
