import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer, inject } from 'mobx-react';

import withUserContext from 'behaviors/withUserContext';
import EntityCauzesView from './EntityCauzesView';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const CompanyCauzesView = ({
  authStore,
  uiStore,
  profileStore,
  activeEntity,
  matchStore,
  companyStore,
}) => {
  const companyId = activeEntity?.id;

  const [isCauzesLoading, setIsCauzesLoading] = useState(false);
  const cauzes = companyStore.events.get(+companyId) || [];
  const joinedEvents = companyStore.joinedEvents.get(+companyId) || [];

  const activeCauzes = useMemo(() => {
    return cauzes.filter((cauze, index) => {
      cauze.index = index;
      return cauze.eventState === 'ACTIVE';
    });
  }, [cauzes, JSON.stringify(cauzes)]);

  const endedCauzes = useMemo(() => {
    return cauzes.filter((cauze, index) => {
      cauze.index = index;
      return cauze.eventState === 'INACTIVE';
    });
  }, [cauzes, JSON.stringify(cauzes)]);

  const draftCauzes = useMemo(() => {
    return cauzes.filter((cauze, index) => {
      cauze.index = index;
      return cauze.eventState === 'UNPUBLISHED';
    });
  }, [cauzes, JSON.stringify(cauzes)]);

  const fetchCauzes = useCallback(
    (id) => {
      setIsCauzesLoading(true);
      Promise.all([
        companyStore.getCompanyEvents({ id: id || activeEntity?.id }),
        companyStore.getCompanyJoinedEvents({ id: id || activeEntity?.id }),
      ]).finally(() => {
        setIsCauzesLoading(false);
      });
    },
    [activeEntity],
  );

  useEffect(() => {
    fetchCauzes(activeEntity?.id);
  }, [activeEntity]);

  return (
    <>
      {isCauzesLoading ? (
        <div className="h-full">
          <CauzeSpinner marginTop="80px" />
        </div>
      ) : (
        <EntityCauzesView
          loading={
            companyStore.eventsLoading || companyStore.joinedEventsLoading
          }
          isAuthenticated={authStore.isAuthenticated}
          profileStore={profileStore}
          matchStore={matchStore}
          activeCauzes={activeCauzes}
          endedCauzes={endedCauzes}
          draftCauzes={draftCauzes}
          joinedCauzes={joinedEvents}
          uiStore={uiStore}
          updatePlannedEventState={companyStore.updatePlannedEventState}
          userContext={activeEntity?.userContext}
        />
      )}
    </>
  );
};

export default withUserContext(
  inject(
    'authStore',
    'matchStore',
    'uiStore',
    'companyStore',
  )(observer(CompanyCauzesView)),
);
