import React from 'react';
import classnames from 'classnames';

import smile from '../../assets/images/brand/smile.svg';

const CauzeSpinner = ({
  fullscreen = false,
  appSpinner = false,
  style,
  size,
  grey = false,
  marginTop = '0px',
}) => (
  <div
    style={{
      ...style,
      marginTop,
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
    className={`cauze-spinner-container ${classnames({
      'push-footer': fullscreen,
    })}`}
  >
    <div className={classnames('cauze-spinner', { fullscreen })}>
      <div
        className={classnames('cauze-loader', { appSpinner })}
        style={{ width: size }}
      >
        <svg className="circular" viewBox="25 25 50 50">
          <circle
            className={classnames('path', { 'grey-path': grey })}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="4"
            strokeMiterlimit="20"
          />
        </svg>
        <img className="smile" src={smile} />
      </div>
    </div>
  </div>
);

export default CauzeSpinner;
