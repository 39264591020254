import React, { Fragment, useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';

import useCompanyAdmin from 'hooks/useCompanyAdmin';
import withUserContext from 'behaviors/withUserContext';
import withAuthRequired from 'behaviors/withAuthRequired';

import MatchTable from 'components/MatchTable/MatchTable';
import ConfirmModal from 'components/modals/ConfirmModal/ConfirmModal';
import Button from 'components/Button/Button';
import MultiStepModal from 'components/modals/MultiStepModal';

import {
  SelectAnnualMatchDetails,
  ShowMatchDetails,
  SelectEventAmounts,
  Summary,
  Advanced,
} from 'components/modals/CreateEditMatchModal/Steps';
import { convertEntityTypeToId } from '../../util/contextUtils';
import { navigate } from '@reach/router';
import useDashboardStore from 'stores/DashboardStore';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const CompanyMatchingView = ({
  profileStore,
  companyAdminStore,
  createMatchStore,
  uiStore,
  cauzeStore,
  _pageContext,
  companyId,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalAction] = useState(() => {});
  const [showAnnualMatchModal, setShowAnnualMatchModal] = useState(false);
  const [showEventMatchModal, setShowEventMatchModal] = useState(false);
  const [creatingMatch, setCreatingMatch] = useState(false);
  const { setProps } = useDashboardStore();

  useCompanyAdmin(companyId);

  const isGroup = profileStore.getActiveEntity().groupType !== 'COMPANY';

  const closeModals = () => {
    setShowAnnualMatchModal(false);
    setShowEventMatchModal(false);
  };

  const onCreateMatch = () => {
    const isEditing = !!createMatchStore.editingMatchId;
    const isAnnual = createMatchStore.currentMatch.matchCharities?.length === 0;
    let balanceDifference = 0;

    if (isEditing && !isAnnual) {
      balanceDifference =
        createMatchStore.currentMatch.matchTotal -
        createMatchStore.previousMatchTotal -
        profileStore.activeEntity.balance.total;
    } else if (!isEditing) {
      balanceDifference =
        createMatchStore.currentMatch.matchTotal -
        profileStore.activeEntity.balance.total;
    }

    const matchMutation = createMatchStore.editingMatchId
      ? () => createMatchStore.editMatch(createMatchStore.editingMatchId)
      : () =>
          createMatchStore.createCompanyMatch({
            companyId,
          });

    if (
      (balanceDifference > 0 &&
        !profileStore.activeEntity.balance.allowNegative &&
        !isEditing) ||
      (balanceDifference > 0 &&
        !profileStore.activeEntity.balance.allowNegative &&
        isEditing &&
        !isAnnual)
    ) {
      closeModals();

      uiStore.openModal('SEND_GIFTS', {
        defaultAmount: balanceDifference,
        giftType: 'SELF_GIFT',
        ...convertEntityTypeToId({
          entityType: profileStore.activeEntity.entityType,
          id: profileStore.activeEntity.id,
        }),
        onSuccess: matchMutation,
        onCancel: createMatchStore.onUnmount,
      });
    } else {
      setCreatingMatch(true);
      matchMutation().then(() => {
        companyAdminStore.getCompanyMatchDetails({
          id: companyId,
        });
        setCreatingMatch(false);
        closeModals();
      });
    }
  };

  const onLoadAnnualMatchModal = async () => {
    await createMatchStore.createAnnualMatchInit({
      restrictMatchAfterBalance:
        profileStore.activeEntity?.restrictMatchAfterBalance,
    });
    setShowAnnualMatchModal(true);
  };

  const onNavToCreateCauze = () => {
    navigate(`/admin/company/${companyId}/cauzes/create`);
  };

  const onEdit = async (match) => {
    // event match, not annual
    if (match.charityCount > 0) {
      // Navigate to the edit cauze view if there's an associated event
      if (match.events && match.events.length > 0) {
        cauzeStore.navToEditCauze({ companyId }, match.events[0].id);
      } else {
        await createMatchStore.loadExistingMatch(match.id);
        setShowEventMatchModal(true);
      }
    } else {
      await createMatchStore.loadExistingMatch(match.id);
      await createMatchStore.getMatchDates();
      setShowAnnualMatchModal(true);
    }
  };

  const onDownloadMatchLedger = (matchId) => {
    companyAdminStore.downloadMatchLedger({ matchId, companyId });
  };

  useEffect(() => {
    setProps({
      size: 'screen',
      title: 'Matching',
      actions: (
        <div className="flex gap-2">
          <Button
            className="is-small !font-agenda-semibold flex justify-center items-center  leading-none !rounded !text-[14px]"
            spanClassName="translate-y-[1px]"
            onClick={onLoadAnnualMatchModal}
          >
            Create {isGroup ? 'A Member' : 'an Employee'} Match
          </Button>
          <Button
            className="is-small !font-agenda-semibold flex justify-center items-center  leading-none !rounded !text-[14px]"
            spanClassName="translate-y-[1px]"
            onClick={onNavToCreateCauze}
          >
            Create & Match a Cauze
          </Button>
        </div>
      ),
    });
  }, []);

  if (companyAdminStore.loading) {
    return <CauzeSpinner fullscreen />;
  }

  return (
    <div className="company-admin matching-view flex-column">
      <div>
        {showConfirmModal && (
          <ConfirmModal
            isOpen
            message="Are you sure you want to delete selected users?"
            action={confirmModalAction}
            onToggleClose={() => setShowConfirmModal(false)}
          />
        )}
        {showAnnualMatchModal && (
          <MultiStepModal
            isOpen
            setIsOpen={setShowAnnualMatchModal}
            onToggleClose={setShowAnnualMatchModal}
            onCancel={createMatchStore.onUnmount}
            titles={[
              isGroup
                ? createMatchStore.editingMatchId
                  ? 'Edit Member Match'
                  : 'Create a Member Match'
                : createMatchStore.editingMatchId
                ? 'Edit Employee Match'
                : 'Create an Employee Match',
              'Match Summary',
            ]}
            onConfirm={onCreateMatch}
            confirmButtonText={
              createMatchStore.editingMatchId ? 'Save Match' : 'Confirm Match'
            }
            isConfirming={creatingMatch}
            advancedView={
              <Advanced store={createMatchStore} isGroup={isGroup} />
            }
          >
            <SelectAnnualMatchDetails
              store={createMatchStore}
              isGroup={isGroup}
            />
            <Summary
              store={createMatchStore}
              balance={profileStore.activeEntity?.balance}
              isGroup={isGroup}
            />
          </MultiStepModal>
        )}
        {showEventMatchModal && (
          <MultiStepModal
            isOpen
            setIsOpen={setShowEventMatchModal}
            onToggleClose={setShowEventMatchModal}
            onCancel={createMatchStore.onUnmount}
            titles={[
              'Edit Cauze Match',
              'Choose Match Amount',
              'Match Summary',
            ]}
            onConfirm={onCreateMatch}
            confirmButtonText="Save Match"
            isConfirming={creatingMatch}
            advancedView={<Advanced store={createMatchStore} />}
          >
            <ShowMatchDetails store={createMatchStore} />
            <SelectEventAmounts store={createMatchStore} />
            <Summary
              store={createMatchStore}
              balance={profileStore.activeEntity?.balance}
            />
          </MultiStepModal>
        )}
        <section>
          <div>
            <MatchTable
              matchList={companyAdminStore.matchList}
              onEdit={onEdit}
              onDisable={companyAdminStore.disableMatch}
              onDownloadMatchLedger={onDownloadMatchLedger}
              userContext={{ companyId }}
              isGroup={isGroup}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default withAuthRequired(
  withUserContext(
    inject(
      'profileStore',
      'companyAdminStore',
      'createMatchStore',
      'uiStore',
      'cauzeStore',
    )(observer(CompanyMatchingView)),
  ),
  { entityType: 'COMPANY' },
);
