import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { ReactComponent as ArrowCircleIcon } from 'assets/images/icons/svgs/arrow-circle.svg';
import { ReactComponent as CauzeLogo } from 'assets/images/brand/cauze-logotype.svg';
import Avatar from 'components/Avatar/Avatar';
import useDashboardStore from 'stores/DashboardStore';
import { TOAST } from 'util/constants';

const ContextSwitchLoadingScreen = ({
  active,
  id,
  avatar,
  name,
  entityType,
  prevContext,
}) => {
  const { showToast } = useDashboardStore();
  const [visible, setVisible] = useState(active);
  const [cachedState, setCachedState] = useState({
    avatar,
    name,
    entityType,
    id,
    prevContext,
  });

  useEffect(() => {
    if (active) {
      setVisible(true);
      setCachedState({ avatar, name, entityType, id, prevContext });
    } else {
      // delay loading screen so that it doesn't flash
      setTimeout(() => {
        setVisible(false);
        if (cachedState?.id) {
          showToast(TOAST.CONTEXT_SWITCH, cachedState);
        }
        setCachedState({});
      }, 1000);
    }
  }, [active]);

  if (!visible && !active) return <></>;

  return (
    <div
      className={cx(
        'context-switch-loading-screen h-screen w-screen fixed bg-white z-[1003] flex justify-center items-center opacity-0 transition-all duration-1000',
        {
          'opacity-100': active,
        },
      )}
    >
      <div className="flex flex-col items-center gap-y-[42px] p-[42px]">
        <div className="relative h-[130px] w-[130px]">
          <div
            className="h-[130px] w-[130px] animate-[spin_1s_linear_0s_infinite_reverse]"
            style={{
              transformOrigin: '65px 65px',
            }}
          >
            <ArrowCircleIcon height={130} width={130} />
          </div>
          <Avatar
            className="absolute left-[21px] top-[21px]"
            noBorder
            avatar={cachedState.avatar}
            entityType={cachedState.entityType}
          />
        </div>
        <div className="font-agenda-bold text-gray-1b2 leading-normal text-[22px] text-center sm:text-left">
          Switching to {cachedState.name}...
        </div>
        <div>
          <CauzeLogo height={15} />
        </div>
      </div>
    </div>
  );
};

export default ContextSwitchLoadingScreen;
