import { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import GiveStartCauze from 'components/Asides/GiveStartCauze';
import { EventListItemWithActionMenu } from 'components/EventList/EventList';
import useDashboardStore from 'stores/DashboardStore';

const EntityCauzesView = ({
  uiStore,
  matchStore,
  profileStore,
  joinedCauzes = [],
  activeCauzes = [],
  draftCauzes = [],
  endedCauzes = [],
  isAuthenticated,
  loading,
  updatePlannedEventState,
  userContext,
}) => {
  const { setProps } = useDashboardStore();
  const [activeMenuId, setActiveMenuId] = useState(null);

  const renderCauzeSection = (title, cauzes, hideActionMenu = false) => {
    if (!cauzes?.length) return <></>;

    const shouldHideActionMenu = (cauze) => {
      if (hideActionMenu) return true;

      if (userContext?.charityId) {
        if (+cauze?.hosts?.[0]?.id !== +userContext.charityId) {
          return true;
        }
      }

      return hideActionMenu;
    };

    return (
      <>
        <div
          className={classnames(
            'text-left mb-5 font-agenda-bold text-[17px] leading-[17px] text-black ',
            { 'mt-2': title === 'Joined', 'mt-5': title !== 'Joined' },
          )}
        >
          {title} ({cauzes.length})
        </div>
        <div className="grid grid-cols-1 min-[390px]:grid-cols-2 gap-x-2 gap-y-3 event-list !p-0">
          {cauzes &&
            cauzes.length > 0 &&
            cauzes.map((event) => (
              <EventListItemWithActionMenu
                key={event.id}
                event={event}
                uiStore={uiStore}
                loading={false}
                hideActionMenu={shouldHideActionMenu(event)}
                index={event.index}
                activeMenuId={activeMenuId}
                setActiveMenuId={setActiveMenuId}
                matchStore={matchStore}
                profileStore={profileStore}
                userContext={userContext}
                updatePlannedEventState={updatePlannedEventState}
              />
            ))}
        </div>
      </>
    );
  };

  const isEmpty = useMemo(() => {
    return (
      !activeCauzes?.length &&
      !endedCauzes?.length &&
      !draftCauzes?.length &&
      !joinedCauzes?.length
    );
  }, [activeCauzes, endedCauzes, draftCauzes, joinedCauzes]);

  useEffect(() => {
    setProps({
      title: 'Your Cauzes',
      asideChildren: isAuthenticated && <GiveStartCauze />,
      loading,
    });
  }, [isAuthenticated, loading]);

  if (!loading && isEmpty) {
    return <div className="text-center">No cauzes found.</div>;
  }

  return (
    <>
      {renderCauzeSection('Active', activeCauzes)}
      {renderCauzeSection('Joined', joinedCauzes, true)}
      {renderCauzeSection('Drafts', draftCauzes)}
      {renderCauzeSection('Ended', endedCauzes)}
    </>
  );
};

export default EntityCauzesView;
