/* eslint-disable no-console */
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import apolloLogger from 'apollo-link-logger';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { getToken } from './storage';
import config from '../config';
import profileStore from '../stores/ProfileStore';

const cache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: config.API2_GRAPH_ROOT,
});

const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, locations, path }) =>
      global.__DEV__
        ? console.warn(
            `[API2 error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          )
        : null,
    );
  }

  if (networkError && global.__DEV__) {
    console.warn(`[API2 Network error]: ${networkError}`);
  }
});

const activeEntityToHeaders = (activeEntity) => {
  return {
    'x-acting-as': activeEntity?.entityType,
    'x-acting-as-id': activeEntity?.id,
  };
};

const authMiddleware = setContext(async (req, { headers }) => {
  const token = await getToken();
  let actingAsHeaders = {};

  if (
    profileStore?.activeEntity?.entityType &&
    profileStore?.activeEntity?.id
  ) {
    actingAsHeaders = activeEntityToHeaders(profileStore.activeEntity);
  }

  return {
    headers: {
      authorization: token ? `Bearer ${token}` : null,
      ...actingAsHeaders,
      ...headers,
    },
  };
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from(
    config.__DEV__
      ? [errorLink, apolloLogger, authMiddleware.concat(httpLink)]
      : [errorLink, authMiddleware.concat(httpLink)],
  ),
});

export { client, activeEntityToHeaders };
