import React, { useEffect } from 'react';

import useDashboardStore from 'stores/DashboardStore';

const NotFoundView = () => {
  const { setProps } = useDashboardStore();

  useEffect(() => {
    setProps({
      size: 'half',
    });
  }, []);

  return (
    <div className="flex flex-col w-full items-center">
      <div>
        <h2 className="font-bold text-2xl mb-3">
          We couldn't find the page you were looking for. This is either
          because:
        </h2>
        <ul className="list-disc text-left mx-auto">
          <li>
            There is an error in the URL entered into your web browser. Please
            check the URL and try again.
          </li>
          <li>You requested a page you don't have permissions to view.</li>
          <li>The page you are looking for has been moved or deleted.</li>
        </ul>
      </div>
    </div>
  );
};

export default NotFoundView;
