/* eslint-disable no-console */
import { observable, action, toJS, makeObservable } from 'mobx';

import { client as apolloClient } from 'util/apolloClient';
import { client as api2Client } from 'util/api2Client';
import {
  companyActivityByIdQuery,
  companyByIdQuery,
  companyUsersByIdQueryV2,
} from 'graphql/company';
import {
  followMutation,
  unfollowMutation,
  updateFollowerMutation,
} from 'graphql/follow';
import { companyEventsQuery } from 'graphql/event';
import { trackFollow, trackUnfollow } from 'util/tracking/follow';
import { likeMutation, unlikeMutation } from 'graphql/like';
import { companyFeedQuery } from 'graphql/feed';
import { userJoinedCauzesQuery } from 'graphql/user';
import { updatePlannedEventMutationV2 } from 'graphql/cauze';

const LEDGER_PAGE_SIZE = 200;

class CompanyStore {
  @observable loading = true;
  @observable isError = false;
  @observable companies = new Map();
  @observable feeds = new Map();
  @observable events = new Map();
  @observable members = new Map();
  @observable feedLoading = true;
  @observable feedLoadingMore = false;
  @observable eventsLoading = true;
  @observable joinedEventsLoading = true;
  @observable charities = new Map();
  @observable joinedEvents = new Map();
  @observable ledgerFilters = {};
  @observable ledgerHasMore = false;
  @observable ledgerLoading = true;
  @observable ledgerLoadingMore = false;
  @observable currentLedgerPage = 1;

  @action setLedgerFilters = (filters) => {
    this.ledgerFilters = filters;
  };

  @action setCharities = ({ followerId, charities }) => {
    this.charities.set(+followerId, charities);
  };

  updateFollow = async ({ charityId, priority }) => {
    const variables = {
      charityId: parseInt(charityId),
      priority: parseInt(priority),
    };

    const options = {
      variables: variables,
      mutation: updateFollowerMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    await api2Client.mutate(options);
  };

  @action getCompany = async ({ id = null, userContext, silent = false }) => {
    if (!silent) {
      this.loading = true;
    }
    const options = {
      variables: { id, userContext },
      query: companyByIdQuery,
      fetchPolicy: 'no-cache',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const result = await apolloClient.query(options);
      this.companies.set(+id, result.data.data);
      this.charities.set(+id, result.data.data?.featuredProjects);
      this.loading = false;
      this.isError = false;

      return result.data.data;
    } catch (err) {
      this.loading = false;
      this.isError = true;
      this.error = err;
    }

    return {};
  };

  @action clearFeed = async ({ id }) => {
    this.feedLoading = true;
    this.companies.set(+id, []);
  };

  @action getCompanyFeed = async ({ id }) => {
    this.feedLoading = true;
    this.currentPage = 1;

    try {
      const variables = {
        companyId: parseInt(id),
        limit: 20,
        offset: 0,
      };

      const options = {
        variables: variables,
        query: companyFeedQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);

      this.feeds.set(+id, results.data.companyFeed);
    } catch (err) {
      // pass
    } finally {
      this.feedLoading = false;
    }
  };

  @action getCompanyActivity = async ({ id, loadMore = false, filters }) => {
    if (!loadMore) {
      this.currentLedgerPage = 1;
      this.ledgerLoading = true;
      this.ledgerHasMore = true;
    } else {
      this.currentLedgerPage += 1;
      this.ledgerLoadingMore = true;
    }

    const options = {
      variables: {
        companyID: parseInt(id),
        offset: (this.currentLedgerPage - 1) * LEDGER_PAGE_SIZE,
        limit: LEDGER_PAGE_SIZE,
        ...filters,
      },
      query: companyActivityByIdQuery,
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    try {
      const result = await api2Client.query(options);
      const companyActivity =
        result?.data?.company?.companyBalanceCreditsAndDebits || [];

      if (
        !companyActivity?.length ||
        companyActivity.length < LEDGER_PAGE_SIZE
      ) {
        this.ledgerHasMore = false;
      }

      return companyActivity;
    } catch (err) {
      // ignore
    } finally {
      this.ledgerLoading = false;
      this.ledgerLoadingMore = false;
    }
  };

  @action getCompanyEvents = async ({ id }) => {
    this.eventsLoading = true;

    try {
      const variables = {
        companyId: parseInt(id),
        pinnedOnly: false,
      };

      const options = {
        variables,
        query: companyEventsQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);
      this.events.set(+id, results.data.companyEvents);
      this.eventsLoading = false;
      return results.data.companyEvents;
    } catch (err) {
      // pass
    } finally {
      this.eventsLoading = false;
    }

    return [];
  };

  @action getCompanyJoinedEvents = async ({ id }) => {
    this.joinedEventsLoading = true;

    try {
      const variables = {};

      const options = {
        variables,
        query: userJoinedCauzesQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);
      this.joinedEvents.set(+id, results.data.joinedCauzes);
      this.joinedEventsLoading = false;
      return results.data.joinedCauzes;
    } catch (err) {
      // pass
    }

    return [];
  };

  @action getCompanyMembers = async ({ id }) => {
    this.membersLoading = true;

    try {
      const variables = {
        companyId: parseInt(id),
      };

      const options = {
        variables,
        query: companyUsersByIdQueryV2,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);
      this.members.set(+id, results.data.companyUsers);
      this.membersLoading = false;
    } catch (err) {
      // pass
    }
  };

  @action loadMore = async ({ id }) => {
    this.currentPage = this.currentPage + 1;
    this.feedLoadingMore = true;
    const currentFeedData = toJS(this.feeds.get(+id));

    if (this.currentPage > this.totalPages) {
      return;
    }

    try {
      const variables = {
        companyId: parseInt(id),
        limit: 20,
        offset: (this.currentPage - 1) * 20,
      };

      const options = {
        variables: variables,
        query: companyFeedQuery,
        fetchPolicy: 'no-cache',
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };

      const results = await api2Client.query(options);

      this.feeds.set(+id, currentFeedData.concat(results.data.companyFeed));
    } catch (err) {
      // pass
    }

    this.feedLoadingMore = false;
  };

  @action follow = ({ follow = true, actorContext, companyId }) => {
    const targetContext = { companyId };
    this.setFollow({ companyId, follow });
    this.followEntity({ follow, actorContext, targetContext });
  };

  @action followEntity = ({
    follow = true,
    actorContext,
    targetContext,
    companyId,
  }) => {
    if (follow) {
      trackFollow({ actorContext, targetContext });
    } else {
      trackUnfollow({ actorContext, targetContext });
    }

    if (targetContext?.userId && companyId) {
      const members = this.members.get(+companyId);
      const memberIndex = members.findIndex(
        (member) => `${member.id}` === targetContext?.userId,
      );
      members[memberIndex] = {
        ...members[memberIndex],
        isSelfFollowing: follow,
      };
      this.members.set(+companyId, members);
    }

    const options = {
      variables: {
        targetContext,
        actorContext,
      },
      mutation: follow ? followMutation : unfollowMutation,
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    apolloClient.mutate(options).catch((err) => {
      if (global.IS_LOCAL_OR_DEV) {
        console.log(err);
      }
    });
  };

  @action setFollow = ({ companyId, follow }) => {
    const companyData = this.companies.get(+companyId);
    companyData.isSelfFollowing = follow;
    companyData.followerCount = companyData.followerCount + (follow ? 1 : -1);
    this.companies.set(+companyId, companyData);
  };

  @action like = ({ like = true, id, purchaseId, companyId, userContext }) => {
    const currentFeed = toJS(this.feeds.get(+companyId));
    const feedItem = currentFeed.find(
      (feedItem) => feedItem?.activityPurchase?.id === id,
    );
    if (!feedItem) return;
    feedItem.currentEntityLiked = like;
    feedItem.likeCount = like ? feedItem.likeCount + 1 : feedItem.likeCount - 1;
    this.feeds.set(+companyId, currentFeed);

    const updatedUserContext = { ...userContext };
    if (updatedUserContext.influencerId) {
      updatedUserContext.userId = updatedUserContext.influencerId;
      delete updatedUserContext.influencerId;
    }

    const options = {
      variables: { id: purchaseId, userContext: updatedUserContext },
      mutation: like ? likeMutation : unlikeMutation,
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };
    apolloClient.mutate(options).catch(() => {
      feedItem.currentEntityLiked = !like;
      feedItem.likeCount = like
        ? feedItem.likeCount - 1
        : feedItem.likeCount + 1;
      this.feeds.set(+companyId, currentFeed);
    });
  };

  @action updatePlannedEventState = async ({
    eventId,
    eventState,
    userContext,
  }) => {
    try {
      const companyId = +userContext?.companyId;
      const events = this.events.get(+companyId) || [];
      const foundIndex = events.findIndex((event) => event.id === +eventId);

      if (foundIndex > -1) {
        events[foundIndex].eventState = eventState;
        this.events.set(+companyId, [...events]);
      }
      await api2Client.mutate({
        variables: {
          eventId,
          eventState,
        },
        mutation: updatePlannedEventMutationV2,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
    } catch (err) {
      this.error = true;
    }
    this.savingCauze = false;
  };

  constructor() {
    makeObservable(this);
  }
}

const companyStore = new CompanyStore();
export default companyStore;
