import gql from 'graphql-tag';

const charityByIdQuery = gql`
  query CharityByIdQuery($id: ID!, $user_context: UserContext) {
    data: charityById(id: $id, userContext: $user_context) {
      id
      name
      bio
      avatar {
        sm
        md
        lg
        xl
      }
      hero {
        md
        lg
        full
      }
      image {
        full
      }
      facebookName
      instagramName
      twitterName
      youtubeUrl
      webUrl
      city
      state
      street
      ein
      zip
      isSelf
      isSelfFollowing
      defaultThanks
      shareLink
      followerCount
      followingCount
      donationAmount
      featuredEvents {
        id
        image {
          md
        }
        name
      }
      balance {
        total
      }
    }
  }
`;

const charityLedger = gql`
  query charityLedger($id: ID!, $pageSize: Int, $page: Int) {
    charityLedger(id: $id, pageSize: $pageSize, page: $page) {
      items {
        actor {
          name
          id
          entityType
          avatar {
            sm
          }
        }
        sponsors {
          name
          id
          entityType
          avatar {
            sm
          }
        }
        donationDate
        donationAmount
        processedDate
        purchaseId
        netAmount
        donationAmount
        eventId
        matchAmount
        granted
        grantType
        comment
        charityCommented
        email
      }
    }
  }
`;

const charityLedgerV2 = gql`
  query charityLedger(
    $limit: Int
    $offset: Int
    $sort: String
    $sort_direction: String
  ) {
    outstandingThanksPurchases {
      id
    }
    charityLedger(
      limit: $limit
      offset: $offset
      sort: $sort
      sortDirection: $sort_direction
    ) {
      netAmount
      items {
        sponsorName
        donationDate
        donationAmount
        userAmount
        processedDate
        purchaseId
        netAmount
        eventId
        matchAmount
        granted
        grantType
        giftId
        comment
        charityCommented
        email
        actorName
      }
    }
  }
`;

const addPurchaseCommentMutation = gql`
  mutation addPurchaseComment(
    $purchase_ids: [ID]
    $user_context: UserContext
    $body: String
  ) {
    addPurchaseComment(
      purchaseIds: $purchase_ids
      userContext: $user_context
      body: $body
    ) {
      id
    }
  }
`;

const updateCharityMutation = gql`
  mutation updateCharity(
    $charity_id: ID!
    $description: String
    $facebook_name: String
    $instagram_name: String
    $twitter_name: String
    $youtube_url: String
    $web_url: String
    $default_thanks: String
  ) {
    updateCharity(
      charityId: $charity_id
      description: $description
      facebookName: $facebook_name
      instagramName: $instagram_name
      twitterName: $twitter_name
      youtubeUrl: $youtube_url
      webUrl: $web_url
      defaultThanks: $default_thanks
    ) {
      id
    }
  }
`;

const pendingGrantsJobStatusQuery = gql`
  query jobStatus($jobId: String!) {
    jobStatus(jobId: $jobId) {
      jobId
      progress
      status
      jobOutput
    }
  }
`;

const generatePendingGrantsReportMutation = gql`
  mutation generatePendingGrantsReport($excludeCurrentMonth: Boolean) {
    generatePendingGrantsReport(excludeCurrentMonth: $excludeCurrentMonth) {
      jobId
      progress
      status
      jobOutput
    }
  }
`;

const processPendingGrantsMutation = gql`
  mutation processPendingGrants {
    processPendingGrants
  }
`;

const charitiesQuery = gql`
  query charitiesWithBalance {
    charitiesWithBalance {
      id
      name
      description
      avatar {
        sm
      }
      balance {
        total
      }
      ein
      street
      city
      state
      zip
    }
  }
`;

const topCharitiesQuery = gql`
  query topProjects($zip: String) {
    topProjects(zipcode: $zip, ignoreZip: true) {
      name
      isCurrentEntity
      currentEntityIsFollowing
      id
      type
      avatarUrls {
        sm
        md
      }
      heroUrls {
        md
      }
      project {
        id
        state
        city
        totalFollowers
        description
        currentEntityIsSubscribed
        ein
        topSubscriptionEntities {
          name
          avatarUrls {
            sm
            md
          }
        }
      }
    }
  }
`;

const SEARCH_ALL_DEFAULT_LIMIT = 20;

const searchAllProjectsQuery = gql`
  query searchAllProjects($query: String!, $filters: SearchFilters) {
    searchProjects(query: $query, limit: ${SEARCH_ALL_DEFAULT_LIMIT}, offset: 0, filters: $filters) {
      processedQuery
      projects {
        name
        id
        avatarUrls {
          sm
          md
        }
        currentEntityIsFollowing
        isCurrentEntity
        type
        project {
          city
          state
          totalFollowers
          description
          id
          currentEntityIsSubscribed
        }
      }
      resultCount
    }
  }
`;

const getCharityLedgerCsvExportQuery = gql`
  query getCharityLedgerCSVQuery($projectId: Int!) {
    charityLedgerCsv(projectId: $projectId) {
      csv
    }
  }
`;

export {
  charityByIdQuery,
  charityLedger,
  charityLedgerV2,
  addPurchaseCommentMutation,
  updateCharityMutation,
  processPendingGrantsMutation,
  charitiesQuery,
  generatePendingGrantsReportMutation,
  pendingGrantsJobStatusQuery,
  topCharitiesQuery,
  searchAllProjectsQuery,
  getCharityLedgerCsvExportQuery,
};
