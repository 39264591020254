import React from 'react';
import { inject, observer } from 'mobx-react';

import PaymentMethodSelect from 'components/PaymentMethodSelect/PaymentMethodSelect';
import CheckoutSummary from 'components/checkout/CheckoutSummary';
import SubmitButton from './SubmitButton';

import useCheckoutStore from 'stores/CheckoutStoreV2';
import Avatar from 'components/Avatar/Avatar';
import MatchList from './MatchList';
import EmployeeMatchToggle from './EmployeeMatchToggle';
import JoinedEntity from './JoinedEntity';

const ConfirmationContentSummary = ({ loading, uiStore }) => {
  const checkoutStore = useCheckoutStore();

  return (
    <div>
      <PaymentMethodSelect
        store={checkoutStore}
        activePaymentMethod={checkoutStore.activePaymentMethod}
        onClick={() => {
          checkoutStore.setFrame(1);
        }}
        disabled={loading}
      />
      <EmployeeMatchToggle />
      <hr className="my-2 h-[2px] bg-lightgray-b2b" />
      <CheckoutSummary
        store={checkoutStore}
        uiStore={uiStore}
        loading={loading}
        activePaymentMethod={checkoutStore.activePaymentMethod}
      />
    </div>
  );
};

const ConfirmationContent = ({ uiStore, authStore, loading, onSubmit }) => {
  const checkoutStore = useCheckoutStore();

  return (
    <div className="flex-1 w-full px-5 flex flex-col justify-between">
      <div>
        <div className="flex justify-center w-full">
          <div className="font-agenda-bold text-[20px] mx-auto py-2">
            Checkout Summary
          </div>
        </div>
        {checkoutStore.checkoutType === 'gift' &&
          checkoutStore.giftType !== 'SELF_GIFT' &&
          !checkoutStore.isEmails && (
            <div className="gift-avatar-container w-full flex flex-col items-center justify-center mt-3 mb-5">
              <Avatar
                md
                avatarUrls={checkoutStore.userProfileData?.avatarUrls}
              />
              <div className="font-agenda-semibold text-[14px] mt-2.5">
                {checkoutStore.userProfileData?.firstName}{' '}
                {checkoutStore.userProfileData?.lastName}
              </div>
            </div>
          )}
        {!authStore.isAuthenticated && (
          <ConfirmationContentSummary loading={loading} uiStore={uiStore} />
        )}
      </div>
      <div>
        <div>
          {authStore.isAuthenticated && (
            <ConfirmationContentSummary loading={loading} uiStore={uiStore} />
          )}
          <SubmitButton onSubmit={onSubmit} isMobile />
        </div>
        <div>
          <MatchList matches={checkoutStore.matches} />
          <JoinedEntity entity={checkoutStore.joinedEntity} />
        </div>
      </div>
    </div>
  );
};

export default inject('uiStore', 'authStore')(observer(ConfirmationContent));
