import gql from 'graphql-tag';

const paymentOptionsQuery = gql`
  query paymentOptions($user_context: UserContext, $include_pending: Boolean) {
    paymentOptions(
      userContext: $user_context
      includePending: $include_pending
    ) {
      balance {
        total
        recurringAmount
        recurringDepositDay
        recurringCardId
      }
      cards {
        id
        brand
        last4
        nickname
        accountType
        feeDescription
        autopayEnabled
        stripeCardId
      }
      feeDescription
      paymentType
    }
  }
`;

const paymentOptionsQueryV2 = gql`
  query paymentOptions($include_pending: Boolean) {
    paymentOptions(includePending: $include_pending) {
      paymentType
      feeDescription
      paymentMethods {
        id
        brand
        last4
        nickname
        accountType
        feeDescription
        autopayEnabled
        stripeCardId
        isDefault
      }
      balance {
        ... on Balance {
          id
          allowNegative
          total
        }
        ... on CompanyBalance {
          id
          allowNegative
          total
        }
      }
    }
  }
`;

const removeCardMutation = gql`
  mutation removeCard($card_id: ID!, $user_context: UserContext) {
    removeCard(cardId: $card_id, userContext: $user_context) {
      id
    }
  }
`;

const disassociatePaymentMethodMutation = gql`
  mutation disassociatePaymentMethod($cardId: Int!) {
    disassociatePaymentMethod(paymentMethodId: $cardId) {
      id
    }
  }
`;

const createSetupIntentMutation = gql`
  mutation createSetupIntent($email: String) {
    createSetupIntent(email: $email) {
      clientSecret
    }
  }
`;

const associatePaymentMethodMutation = gql`
  mutation associatePaymentMethod($setupIntentId: String!) {
    associatePaymentMethod(setupIntentId: $setupIntentId) {
      id
    }
  }
`;

const addCardMutation = gql`
  mutation addCard(
    $stripe_token: ID!
    $nickname: String
    $user_context: UserContext
  ) {
    addCard(
      stripeToken: $stripe_token
      nickname: $nickname
      userContext: $user_context
    ) {
      id
    }
  }
`;

const editRecurringDeposit = gql`
  mutation editRecurringDeposit(
    $amount: Int
    $recurring: Boolean
    $user_context: UserContext
    $card_id: ID
    $recurring_deposit_day: Int
  ) {
    editRecurringDeposit(
      amount: $amount
      recurring: $recurring
      userContext: $user_context
      cardId: $card_id
      recurringDepositDay: $recurring_deposit_day
    ) {
      recurringAmount
      recurringDepositDay
      recurringCardId
    }
  }
`;

const verifyBankAccountMutation = gql`
  mutation verifyBankAccount(
    $amount1: Int
    $amount2: Int
    $user_context: UserContext
    $card_id: ID
  ) {
    verifyBankAccount(
      amount1: $amount1
      amount2: $amount2
      userContext: $user_context
      cardId: $card_id
    ) {
      id
      accountType
    }
  }
`;

const setAutopayMutation = gql`
  mutation setAutopay(
    $autopay_enabled: Boolean!
    $card_id: ID!
    $user_context: UserContext
  ) {
    setAutopay(
      autopayEnabled: $autopay_enabled
      cardId: $card_id
      userContext: $user_context
    ) {
      id
    }
  }
`;

const addBankAccountMutation = gql`
  mutation addBankAccount(
    $nickname: String
    $plaid_token: String
    $user_context: UserContext
    $account_id: String
  ) {
    addBankAccount(
      nickname: $nickname
      plaidToken: $plaid_token
      userContext: $user_context
      accountId: $account_id
    ) {
      id
      brand
      nickname
    }
  }
`;

const updateDefaultPaymentMethodMutation = gql`
  mutation updateDefaultPaymentMethod($cardId: Int!) {
    updateDefaultCard(cardId: $cardId) {
      id
    }
  }
`;

const stripePaymentMethodQuery = gql`
  query stripePaymentMethod($paymentMethodId: String!) {
    stripePaymentMethod(paymentMethodId: $paymentMethodId) {
      last4
      brand
      feeDescription
      customerId
    }
  }
`;

export {
  paymentOptionsQuery,
  paymentOptionsQueryV2,
  removeCardMutation,
  addCardMutation,
  editRecurringDeposit,
  verifyBankAccountMutation,
  setAutopayMutation,
  addBankAccountMutation,
  disassociatePaymentMethodMutation,
  createSetupIntentMutation,
  associatePaymentMethodMutation,
  updateDefaultPaymentMethodMutation,
  stripePaymentMethodQuery,
};
