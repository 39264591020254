import React, { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import cx from 'classnames';
import { toJS } from 'mobx';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import withUserContext from 'behaviors/withUserContext';
import { normalizeContext } from 'util/contextUtils';

import EntityList from 'components/modals/EntityListModal/EntityList/EntityList';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';

const TABS = {
  FOLLOWERS: 'followers',
  FOLLOWING: 'following',
};

const FollowFollowingModal = ({
  isOpen,
  buttonLabel,
  userContext,
  followFollowingStore,
  pageContext,
  isSelf,
  onToggleClose,
  isFollowers,
  entityName,
  followerCount,
  followingCount,
  isAuthenticated,
}) => {
  const [_followerCount, setFollowerCount] = useState(followerCount || 0);
  const [_followingCount, setFollowingCount] = useState(followingCount || 0);
  const _userContext = normalizeContext(userContext);
  const _pageContext = normalizeContext(pageContext);
  const [activeTab, setActiveTab] = useState(
    isFollowers ? TABS.FOLLOWERS : TABS.FOLLOWING,
  );

  useEffect(() => {
    followFollowingStore.getInitial({
      userContext: _userContext,
      pageContext: _pageContext,
      isSelf: isSelf,
      isFollowers: activeTab === TABS.FOLLOWERS,
    });
    return () => followFollowingStore.onUnmount();
  }, [activeTab]);

  useEffect(() => {
    setFollowerCount(followerCount || 0);
    setFollowingCount(followingCount || 0);
  }, [followerCount, followingCount]);

  useEffect(() => {
    if (
      activeTab === TABS.FOLLOWERS &&
      followFollowingStore.entitiesList.length &&
      _followerCount !== followFollowingStore.entitiesList.length
    ) {
      setFollowerCount(followFollowingStore.entitiesList.length);
    }

    if (
      activeTab === TABS.FOLLOWING &&
      followFollowingStore.entitiesList.length &&
      _followingCount !== followFollowingStore.entitiesList.length
    ) {
      setFollowingCount(followFollowingStore.entitiesList.length);
    }
  }, [followFollowingStore.entitiesList]);

  const setUnfollow = ({ onfollowCallback }) => {
    onfollowCallback();
  };

  const getModalTitle = () => {
    if (isSelf) {
      return 'Your Followers / Following';
    }

    if (entityName) {
      return `${entityName.trim()}'s Followers / Following`;
    }

    return 'Followers / Following';
  };

  const getTabName = (tabType) => {
    if (tabType === TABS.FOLLOWERS) {
      if (_followerCount) {
        return `${_followerCount} FOLLOWER${
          _followerCount.toString() !== '1' ? 'S' : ''
        }`;
      }

      return 'FOLLOWERS';
    }

    if (tabType === TABS.FOLLOWING) {
      if (_followingCount) {
        return `${_followingCount} FOLLOWING`;
      }

      return 'FOLLOWING';
    }
  };

  return (
    <div
      className={cx('modal follow-following-modal', {
        'is-active': isOpen,
      })}
    >
      <div
        className="modal-background"
        onClick={() => {
          onToggleClose(false);
        }}
      />
      <div className="modal-card">
        <div className="modal-card-body">
          <div className="follow-following-title">
            <div className="follow-following-close" onClick={onToggleClose}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <div className="follow-following-title-text">{getModalTitle()}</div>
          </div>
          <div class="tabs is-boxed">
            <ul>
              <li
                className={cx('', {
                  'is-active': activeTab === TABS.FOLLOWERS,
                })}
              >
                <a onClick={() => setActiveTab(TABS.FOLLOWERS)}>
                  <span>{getTabName(TABS.FOLLOWERS)}</span>
                </a>
              </li>
              <li
                className={cx('', {
                  'is-active': activeTab === TABS.FOLLOWING,
                })}
              >
                <a onClick={() => setActiveTab(TABS.FOLLOWING)}>
                  <span>{getTabName(TABS.FOLLOWING)}</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="tabbed-content">
            {followFollowingStore.loadingEntities && (
              <CauzeSpinner className="follow-following-spinner" />
            )}
            {!followFollowingStore.loadingEntities && (
              <EntityList
                showFollow
                entities={toJS(followFollowingStore.entitiesList)}
                buttonLabel={buttonLabel}
                store={followFollowingStore}
                userContext={_userContext}
                pageContext={_pageContext}
                setUnfollow={setUnfollow}
                isAuthenticated={isAuthenticated}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserContext(
  inject('followFollowingStore', 'uiStore')(observer(FollowFollowingModal)),
);
