/* eslint-disable no-console */
import { observable, action, toJS, makeObservable } from 'mobx';
import { client as apolloClient } from '../util/apolloClient';
import { client as api2Client } from '../util/api2Client';
import { isEmpty } from 'lodash';
import {
  getActiveEntity as getActiveEntityFromStorage,
  setActiveEntity as setActiveEntityToStorage,
} from '../util/storage';
import { createCompanyMutation } from '../graphql/company';
import { entitiesMatch, convertEntityTypeToId } from '../util/contextUtils';
import mixpanel from 'mixpanel-browser';

import authStore from './AuthStore';

import {
  profileQuery,
  mySupportedCharitiesQuery,
  companyEntity,
  charityEntity,
  influencerEntity,
  emailUsubscribeMutation,
  balanceQuery,
  emailAnnualReceiptMutation,
  sendContactEmailMutation,
} from '../graphql/profile.js';
import {
  getUserCauzesQuery,
  updatePlannedEventMutationV2,
} from 'graphql/cauze';
import { navigate } from '@reach/router';
import uiStore from './UiStore';

class ProfileStore {
  @observable hasFetchedInitial = false;
  @observable loading = true;
  @observable feedLoading = true;
  @observable isError = false;
  @observable data;
  @observable supportedCharities;
  @observable contextSelectorActive = false;
  @observable showContextReminder = true;
  @observable emailUnsubscribeResponse = '';
  @observable cauzes = [];
  @observable savingCauze = false;

  // This is an array of companies and groups the user is a member/employee of
  @observable groupRoles = [];

  // activeEntity is what shows up as the currently logged in user name and avatar
  @observable activeEntity = {};

  // availableUserEntities contains all available context for user. It is constructed from the server call
  // to get the currently logged in user's company admin, charity admin, and system admin lists
  @observable availableUserEntities = [];

  @action getProfile = async (stashedPathDetails = {}) => {
    if (!authStore.isAuthenticated) {
      return;
    }

    console.log('getting profile');
    this.loading = true;
    const options = {
      query: profileQuery,
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };
    let contextIndexToLoad = 0;

    try {
      const queryResult = await apolloClient.query(options);
      const savedEntity = await getActiveEntityFromStorage();
      this.availableUserEntities = [];

      this.data = queryResult.data.data;
      this.availableUserEntities[0] = {
        avatar: this.data.avatar,
        name: this.data.name,
        firstName: this.data.firstName,
        lastName: this.data.lastName,
        username: this.data.username,
        zip: this.data.zip,
        id: this.data.id,
        entityType: this.data.isInfluencer ? 'INFLUENCER' : 'USER',
        balance: {
          total: this.data.balance.total,
        },
        userContext: null,
        sysAdmin: this.data.sysAdmin,
      };
      if (this.data.roles) {
        let adminRoles = [];
        let groupRoles = [];
        this.data.roles.forEach((role) => {
          if (role.name === 'admin' && role.entity.entityType === 'USER') {
            return;
          }
          if (role.name === 'employee') {
            groupRoles.push({
              name: role.entity.name,
              role: role.name,
              username: role.entity.username,
              avatar: role.entity.avatar,
              entityType: role.entity.entityType,
              id: role.entity.id,
              shareLink: role.entity.shareLink,
              balance: role.balance,
            });
          } else {
            const isInfluencer =
              role.name === 'influencer' || role.name === 'influencer_admin';
            if (
              isInfluencer &&
              role.entity.id === this.availableUserEntities[0].id
            ) {
              return;
            }
            adminRoles.push({
              name: role.entity.name,
              role: role.name,
              username: role.entity.username,
              avatar: role.entity.avatar,
              entityType: isInfluencer ? 'INFLUENCER' : role.entity.entityType,
              id: role.entity.id,
              shareLink: role.entity.shareLink,
              balance: role.balance,
              canDonate: role.charity?.canDonate,
              prepayFees: role.company?.prepayFees,
              groupType: role.company?.groupType,
              modules: role.company?.modules ? role.company.modules : [],
              userContext: convertEntityTypeToId({
                id: role.entity.id,
                entityType: isInfluencer
                  ? 'INFLUENCER'
                  : role.entity.entityType,
              }),
            });
          }
        });

        // Remove group roles where the user is also an admin of that role to avoid duplicates in the userDropDown list
        this.groupRoles = groupRoles.filter((groupRole) => {
          if (
            adminRoles.filter((adminRole) => adminRole.id === groupRole.id)
              .length > 0
          ) {
            return false;
          }
          return true;
        });

        this.availableUserEntities = [
          ...this.availableUserEntities,
          ...adminRoles,
          ...this.groupRoles,
        ];
      }

      if (stashedPathDetails?.path) {
        const { companyId, charityId, userId } = stashedPathDetails;
        if (companyId || charityId || userId) {
          this.availableUserEntities.forEach((availableUserEntity, index) => {
            const { id, entityType } = availableUserEntity;

            // Compare the entityTypes only to ids of valid entities
            if (id === companyId && entityType === 'COMPANY') {
              contextIndexToLoad = index;
            }
            if (id === charityId && entityType === 'CHARITY') {
              contextIndexToLoad = index;
            }
            if (id === userId && entityType === 'USER') {
              contextIndexToLoad = index;
            }
          });
        }
      } else if (!isEmpty(savedEntity)) {
        if (
          savedEntity.id === this.data.id &&
          savedEntity.entityType === 'USER'
        ) {
          contextIndexToLoad = 0;
        } else {
          this.availableUserEntities.forEach((availableUserEntity, index) => {
            if (entitiesMatch(availableUserEntity, savedEntity)) {
              global.IS_LOCAL_OR_DEV &&
                console.log('entity retrieved from storage', toJS(savedEntity));
              contextIndexToLoad = index;
            }
          });
        }
      }
      this.setActiveEntity(contextIndexToLoad, true);
      this.hasFetchedInitial = true;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.isError = true;
      this.error = err;
    }

    console.log('available contexts:', toJS(this.availableUserEntities));
  };

  @action getBalance = async () => {
    const options = {
      query: balanceQuery,
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      const queryResult = await apolloClient.query(options);
      this.ledger = queryResult.data.myBalance;
    } catch (err) {
      this.error = err;
    }
  };

  @action getCauzes = async (force = false) => {
    if (this.loading && this.cauzes.length && !force) {
      this.loading = false;
      return this.cauzes;
    }
    this.loading = true;

    const options = {
      query: getUserCauzesQuery,
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_DEV ? 'all' : 'none',
    };

    try {
      const queryResult = await api2Client.query(options);
      this.cauzes = queryResult.data.userCauzes;
      this.loading = false;
      return queryResult.data.userCauzes;
    } catch (err) {
      this.isError = true;
      this.error = err;
    } finally {
      this.loading = false;
    }

    return [];
  };
  @action updatePlannedEventState = async ({ eventId, eventState, index }) => {
    try {
      if (this.cauzes[index]) {
        this.cauzes[index].eventState = eventState;
      }
      await api2Client.mutate({
        variables: {
          eventId,
          eventState,
        },
        mutation: updatePlannedEventMutationV2,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      });
    } catch (err) {
      this.error = true;
      console.log(err);
    }
    this.savingCauze = false;
  };

  getCauzeUrl = (userContext) => {
    if (userContext.companyId) {
      return `/admin/company/${userContext.companyId}/cauzes`;
    } else if (userContext.charityId) {
      return `/admin/charity/${userContext.charityId}/cauzes`;
    } else if (userContext.influencerId) {
      return `/admin/influencer/${userContext.influencerId}/cauzes`;
    } else if (userContext.userId) {
      return `/admin/influencer/${userContext.userId}/cauzes`;
    } else {
      return `/admin/cauzes`;
    }
  };

  @action navToEditCauze = (userContext, cauzeId) => {
    const url = this.getCauzeUrl(userContext);
    console.log(userContext.influencerId, 'xuserContext.influencerId');
    navigate(`${url}/${cauzeId}/edit`);
  };
  @action updateActiveEntityTotal = (amount) => {
    if (this.activeEntity.balance) {
      this.activeEntity.balance.total =
        this.activeEntity.balance.total + amount;
    } else {
      this.activeEntity.balance = { total: amount };
    }
  };

  @action getSupportedCharities = async () => {
    const options = {
      query: mySupportedCharitiesQuery,
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      const queryResult = await apolloClient.query(options);
      this.supportedCharities = queryResult.data.data;
    } catch (err) {
      this.error = err;
    }
  };

  @action fetchAll = () => {
    console.log('fetching all');
    this.getProfile();
    this.getSupportedCharities();
    this.getFeed({ page: 1 });
  };

  @action toggleContextSelector = () => {
    this.contextSelectorActive = !this.contextSelectorActive;
  };

  @action hideContextReminder = () => {
    this.showContextReminder = false;
  };

  @action setActiveEntity = async (
    index,
    update = false,
    _checkSaved = true,
  ) => {
    this.activeEntity = {
      ...this.availableUserEntities[index],
      balance: this.availableUserEntities[index]?.balance
        ? this.availableUserEntities[index].balance
        : null,
      userContext: convertEntityTypeToId(this.availableUserEntities[index]),
    };
    setActiveEntityToStorage(toJS(this.activeEntity));
    if (!update) {
      this.contextSelectorActive = false;
      this.showContextReminder = true;
    }
  };

  getActiveEntity = () => toJS(this.activeEntity);

  getUserContext = () =>
    !isEmpty(this.activeEntity)
      ? {
          userContext: toJS(this.activeEntity.userContext),
        }
      : {};

  @action userCreateCompany = async ({ name, phoneNumber, creatorRole }) => {
    try {
      const options = {
        variables: {
          name,
          phoneNumber,
          creatorRole,
          email: this.data.email,
          userContext: { userId: this.data.id },
        },
        mutation: createCompanyMutation,
        errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
      };
      const res = await apolloClient.mutate(options);
      return res.data.createCompany.id;
    } catch (err) {
      console.log(err);
    }
  };

  @action getAdminEntity = async ({ entityType, id }) => {
    let options = {
      variables: { id },
      fetchPolicy: 'network-only',
      errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
    };

    try {
      if (entityType === 'COMPANY') {
        options.query = companyEntity;
      }
      if (entityType === 'CHARITY') {
        options.query = charityEntity;
      }
      if (entityType === 'INFLUENCER') {
        options.query = influencerEntity;
      }

      if (entityType === 'USER') {
        options.query = influencerEntity;
      }

      const { data } = await apolloClient.query(options);
      if (data) {
        this.availableUserEntities.push({
          ...data.data,
          userContext: { entityType, id },
          entityType,
        });
        this.setActiveEntity(this.availableUserEntities.length - 1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  @action setEmailUnsubscribeResponse = (status) => {
    this.emailUnsubscribeResponse = status;
  };

  @action setEmailUnsubscribe = async (email) => {
    if (email && email.length !== 0) {
      mixpanel.track('Email Manual Unsub', {
        email,
      });
      try {
        const options = {
          variables: { email },
          mutation: emailUsubscribeMutation,
          errorPolicy: global.IS_LOCAL_OR_DEV ? 'all' : 'none',
        };
        const res = await api2Client.mutate(options);
        this.setEmailUnsubscribeResponse(res.data.emailUnsubscribe?.status);
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log('Error in email address forwarding');
    }
  };

  @action clearActiveEntity = () => {
    this.contextSelectorActive = false;
    this.showContextReminder = false;
    console.log('clearing active entity', this.data);
    this.activeEntity = {
      avatar: toJS(this.data.avatar),
      name: this.data.name,
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      username: this.data.username,
      id: this.data.id,
      entityType: 'USER',
      userGood: toJS(this.data.userGood),
      balance: this.data.balance,
      userContext: convertEntityTypeToId({
        id: this.data.id,
        entityType: 'USER',
      }),
      sysAdmin: this.data.sysAdmin,
    };
    setActiveEntityToStorage(toJS(this.activeEntity));
  };

  @action getAnnualReciept = async ({ year }) => {
    this.annualReceiptRequested = true;
    try {
      const options = {
        variables: { year },
        mutation: emailAnnualReceiptMutation,
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      };
      await api2Client.mutate(options);
      this.annualReceiptRequested = false;
      this.annualReceiptRequestSuccess = true;
    } catch (err) {
      this.annualReceiptRequested = false;
      this.annualReceiptRequestSuccess = false;
    }
  };

  @action sendContactMessage = async ({ name, email, message }) => {
    try {
      const options = {
        variables: { name, email, message },
        mutation: sendContactEmailMutation,
        errorPolicy: global.IS_DEV ? 'all' : 'none',
      };
      const res = await api2Client.mutate(options);

      if (!res.data.sendContactEmail.success) {
        throw new Error();
      }
    } catch (err) {
      // show error popup
      uiStore.showNotification({
        body: 'Unable to send message. Try again later.',
        type: 'ERROR',
      });
    }
  };

  @action onUnmount = () => {
    this.feedSubscription && this.feedSubscription.unsubscribe();
    this.feedQuery = null;
    this.activeEntity = {};
    this.availableUserEntities = [];
    this.loading = false;
    this.hasFetchedInitial = false;
    this.emailUnsubscribeResponse = '';
    setActiveEntityToStorage({});
  };

  constructor() {
    makeObservable(this);
  }
}

const profileStore = new ProfileStore();

export default profileStore;
