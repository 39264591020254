import React, { useState, useRef, useEffect } from 'react';
import { navigate } from '@reach/router';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';
import mixpanel from 'mixpanel-browser';

import { onLogoutClick } from 'util/userUtils';
import { LOADING_SCREENS } from 'util/constants';
import useKeyboardEvent from 'hooks/useKeyboardEvent.js';

import { ReactComponent as ArrowCircleIcon } from 'assets/images/icons/svgs/arrow-circle.svg';
import { ReactComponent as DownChevronIcon } from 'assets/images/icons/chevron/down-chevron.svg';
import Currency from 'components/Currency/Currency';
import Avatar from 'components/Avatar/Avatar';
import { getLedgerUrl, getProfileUrl } from 'util/stringUtils';
import useMobile from 'hooks/useMobile';
import useDashboardStore from 'stores/DashboardStore';
import Tooltip from 'components/Tooltip/Tooltip';
import { getEntityHomeUrl } from 'util/navHelpers';

const ContextSwitchEntity = ({
  isActive,
  isHidden,
  onMouseDown,
  onTouchEnd,
  avatar,
  name,
  entityType,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(undefined);

  const getEntityDesc = (entityType) => {
    if (entityType === 'PERSONAL' || entityType === 'USER') {
      return 'Your Personal Account';
    }

    if (entityType === 'COMPANY') {
      return 'Your Company Account';
    }

    if (entityType === 'CHARITY') {
      return 'Your Charity';
    }

    return 'Your Influencer Account';
  };

  useEffect(() => {
    const scrollContainer = document.querySelector('#user-dropdown-content');
    if (scrollContainer) {
      const handleScroll = () => {
        setTooltipVisible(false);

        setTimeout(() => {
          setTooltipVisible(undefined);
        }, 1);
      };

      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  if (isHidden) {
    return <></>;
  }

  const content = (
    <div
      className={classnames(
        'text-black py-[5px] px-[10px] flex gap-x-[10px] cursor-pointer rounded-[10px] hover:bg-lightgray-f1f group',
        {
          'bg-lightgray-f1f': isActive,
        },
      )}
      onMouseDown={onMouseDown}
      onTouchEnd={onTouchEnd}
    >
      <div className="flex justify-between items-center w-full gap-x-2.5">
        <div
          className={classnames('flex gap-2.5', {
            'flex-col items-center w-full text-center py-[7px] is-active':
              isActive,
          })}
        >
          <div className={classnames('relative', { 'h-14 w-14': isActive })}>
            {!isActive && (
              <div className="group-hover:animate-[spinHalf_300ms_linear_0s_1_reverse] h-[50px] w-[50px]">
                <ArrowCircleIcon />
              </div>
            )}
            <Avatar
              className={classnames('', {
                'absolute left-2 top-2 pointer-events-none': !isActive,
              })}
              noBorder
              avatar={avatar}
            />
          </div>
          <div
            className={classnames('text-gray-1b2 my-auto flex flex-col', {
              'gap-y-1': isActive,
            })}
          >
            <div className="font-agenda-bold text-[17px] leading-tight">
              {name}
            </div>
            <div className="font-agenda text-[14px] leading-tight">
              {getEntityDesc(entityType)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (!isActive) {
    return (
      <Tooltip
        containerClassName={classnames(
          'context-switch-entity-tooltip transition-all',
        )}
        text={`Switch to ${name}`}
        includeArrow={false}
        config={{
          placement: 'bottom-start',
          trigger: 'hover',
          offset: [0, 0],
          visible: tooltipVisible,
        }}
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};

const UserDropDown = ({
  authStore,
  profileStore,
  eventStore,
  className,
  activeEntity,
  id,
  hideContextSelector = false,
}) => {
  const { showLoadingScreen } = useDashboardStore();
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useMobile();

  useKeyboardEvent('Escape', () => {
    setIsOpen(false);
  });

  const activeUserId =
    ['USER', 'INFLUENCER'].includes(activeEntity.entityType) && activeEntity.id;

  let triggerRef = useRef(null);

  useEffect(() => {
    if (!isOpen && triggerRef.current) {
      triggerRef.current.blur();
    }
  }, [isOpen]);

  if (!profileStore.hasFetchedInitial) {
    return null;
  }

  const _setIsOpen = (open) => {
    mixpanel.track(`User Drop Down ${open ? 'opened' : 'closed'}`);
    setIsOpen(open);
  };

  const handleLogOut = () => {
    onLogoutClick(authStore, profileStore, eventStore);
    navigate('/login');
  };

  const handleNavigateToAccountLedger = () => {
    const accountLedgerUrl = getLedgerUrl(
      activeEntity.id,
      activeEntity.entityType,
    );
    navigate(accountLedgerUrl);
  };

  return (
    <div
      id={id}
      className={classnames(
        'dropdown',
        'user-dropdown',
        'is-right',
        { 'is-active': isOpen },
        className,
      )}
    >
      <button
        className="cz-btn-outline !font-agenda capitalize h-10 my-auto min-w-[121px] mr-[19px] hidden lg:flex"
        onClick={handleNavigateToAccountLedger}
      >
        <div className="my-auto !leading-none text-[16px]">
          Balance:{' '}
          <Currency
            className="font-agenda-bold"
            amount={activeEntity.balance.total}
          />
        </div>
      </button>
      <div className="flex h-8 w-8 xl:h-[50px] xl:w-[50px]">
        <button
          ref={triggerRef}
          onBlur={() => {
            _setIsOpen(false);
          }}
          onClick={() => {
            _setIsOpen(!isOpen);
          }}
          className="border-none bg-transparent my-auto user-avatar-btn relative !p-0"
          aria-haspopup="true"
          aria-controls="user-dropdown-menu"
        >
          <Avatar
            noBorder
            avatar={
              activeEntity.avatar
                ? activeEntity.avatar
                : activeEntity?.id === profileStore?.data?.id &&
                  profileStore?.data?.avatar
            }
          />
          {profileStore.availableUserEntities.length > 1 && (
            <div class="absolute bottom-0 -right-1.5 cursor-pointer">
              <div className="w-4 h-4 rounded-full bg-lightgray-f1f grid place-items-center text-gray-1b2">
                <DownChevronIcon height={4} width={8} />
              </div>
            </div>
          )}
        </button>
      </div>
      <div
        className={classnames('dropdown-menu user-dropdown-menu', {
          '!left-[11px] !right-[11px] !top-[50px] lg:!top-[76px] !fixed !pt-0':
            isMobile,
        })}
        id="dropdown-menu"
        role="menu"
      >
        <div
          className={classnames('backdrop !mt-[108px] lg:!mt-[76px]', {
            'backdrop-lowered': !!activeUserId,
          })}
          onTouchEnd={() => {
            _setIsOpen(false);
          }}
        />
        <div className="dropdown-content rounded-[10px] overflow-hidden !px-0 !pt-2 !pb-0 w-full lg:w-[337px]">
          {!hideContextSelector &&
            (profileStore.availableUserEntities.length >= 1 ||
              profileStore.groupRoles.length >= 1) && (
              <div
                id="user-dropdown-content"
                className="flex flex-col gap-y-[10px] px-2 max-h-96 overflow-y-auto pb-3"
              >
                <ContextSwitchEntity
                  isActive
                  avatar={activeEntity.avatar}
                  name={activeEntity.name}
                  entityType={activeEntity.entityType}
                  onMouseDown={(_e) => {
                    _e.stopPropagation();
                    const url = getProfileUrl(
                      activeEntity.id,
                      activeEntity.entityType,
                    );
                    navigate(url);
                    _setIsOpen(false);
                  }}
                />

                {profileStore.availableUserEntities.length > 1 && (
                  <ContextSwitchEntity
                    isHidden={
                      +activeEntity.id ===
                        +profileStore.availableUserEntities[0].id &&
                      ['USER', 'INFLUENCER'].includes(activeEntity.entityType)
                    }
                    onMouseDown={async (_e) => {
                      const context = profileStore.availableUserEntities[0];
                      showLoadingScreen(LOADING_SCREENS.CONTEXT_SWITCH, {
                        avatar: context.avatar,
                        entityType: context.entityType,
                        name: context.name,
                        id: context.id,
                        prevContext: activeEntity,
                      });
                      await navigate('loading');
                      profileStore.clearActiveEntity();
                      navigate(
                        getEntityHomeUrl({
                          id: context.id,
                          entityType: context.entityType,
                        }),
                      );
                      _setIsOpen(false);
                    }}
                    avatar={profileStore.data.avatar}
                    name={profileStore.data.firstName}
                    entityType={'PERSONAL'}
                  />
                )}

                {profileStore.availableUserEntities.map((context, index) =>
                  index === 0 ? null : (
                    <ContextSwitchEntity
                      isHidden={
                        (+activeEntity.id === +context.id &&
                          context.entityType === activeEntity.entityType) ||
                        context.role?.toLowerCase() === 'employee'
                      }
                      activeEntity={activeEntity}
                      key={`${index}-${context.id}`}
                      onMouseDown={async (_e) => {
                        showLoadingScreen(LOADING_SCREENS.CONTEXT_SWITCH, {
                          avatar: context.avatar,
                          entityType: context.entityType,
                          name: context.name,
                          id: context.id,
                          prevContext: activeEntity,
                        });
                        await navigate('loading');
                        profileStore.setActiveEntity(index, true);
                        navigate(
                          getEntityHomeUrl({
                            id: context.id,
                            entityType: context.entityType,
                          }),
                        );
                        _setIsOpen(false);
                      }}
                      avatar={context.avatar}
                      name={context.name}
                      entityType={context.entityType}
                    />
                  ),
                )}

                {/* {profileStore.groupRoles.map((entity, index) => (
                  <ContextSwitchEntity
                    isActive={
                      activeEntity.id === entity.id &&
                      activeEntity.entityType === entity.entityType
                    }
                    onButtonClick={(_e) => {
                      _e.stopPropagation();
                      navigate(`/company/${entity.id}`);
                      _setIsOpen(false);
                    }}
                    activeEntity={activeEntity}
                    key={`${index}-${entity.id}`}
                    name={entity.name}
                    avatar={entity.avatar}
                    entityType={entity.entityType}
                    onMouseDown={(_e) => {
                      _setIsOpen(false);
                    }}
                  />
                ))} */}
              </div>
            )}
          {authStore.isAuthenticated && (
            <button
              className="underline font-agenda-bold text-sm leading-loose w-full bg-white border-none cursor-pointer hover:bg-lightgray-f1f h-12"
              type="button"
              onMouseDown={handleLogOut}
              onClick={handleLogOut}
            >
              Log Out
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default inject(
  'authStore',
  'uiStore',
  'profileStore',
  'eventStore',
  'companyReportingStore',
)(observer(UserDropDown));
