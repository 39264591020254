import React, { useEffect, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

const Tooltip = ({
  text,
  children,
  className,
  containerClassName = 'tooltip-container-checkout-summary',
  config = { placement: 'bottom', trigger: 'hover' },
  includeArrow = true,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(config);

  return (
    <>
      <div
        className={className}
        ref={setTriggerRef}
        style={{ display: 'inline-block' }}
      >
        {children}
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `tooltip-container ${containerClassName}`,
          })}
        >
          {includeArrow && (
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          )}
          <div className="tooltip-body">
            <div className="tooltip-title !mb-0">{text}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tooltip;
