import React, { useEffect } from 'react';

import CreateCauzeView from './CreateCauzeView';
import withAuthRequired from 'behaviors/withAuthRequired';
import useCreateCauze from 'hooks/useCreateCauze';
import useDashboardStore from 'stores/DashboardStore';

const InfluencerCreateCauzeView = ({ influencerId, eventId }) => {
  const { setProps } = useDashboardStore();
  useCreateCauze({ userContext: { userId: influencerId }, eventId });

  useEffect(() => {
    setProps({
      size: 'screen',
    });
  }, []);

  return (
    <CreateCauzeView userContext={{ userId: influencerId }} eventId={eventId} />
  );
};

export default withAuthRequired(InfluencerCreateCauzeView, {
  entityType: 'INFLUENCER',
});
