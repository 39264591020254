import React from 'react';
import matchSorter from 'match-sorter';
import { TableCurrency } from '../UserTable/components';
import { Actor, ActorSort } from '../Actor/Actor';
import Button from 'components/Button/Button';

const companiesColumns = (onAddToBalance) => [
  {
    id: 'company',
    Header: 'Company',
    accessor: (row) => ({ name: row.name, entity: row }),
    Cell: Actor,
    className: 'col-left col-wrap',
    headerClassName: 'col-left',
    minWidth: 150,
    sortMethod: ActorSort,
    filterAll: true,
    filterMethod: (filter, rows) =>
      matchSorter(rows, filter.value, {
        keys: ['company.name'],
        threshold: matchSorter.rankings.CONTAINS,
      }),
  },
  {
    id: 'id',
    Header: 'Id',
    accessor: 'id',
    className: 'is-hidden-mobile',
    headerClassName: 'is-hidden-mobile',
    minWidth: 35,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'balance',
    Header: 'Balance',
    accessor: 'balance',
    minWidth: 50,
    Cell: TableCurrency,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'shareLink',
    Header: 'Share Link',
    accessor: 'shareLink',
    minWidth: 100,
    className: 'col-left col-wrap',
    headerClassName: 'col-left',
    style: { wordWrap: 'break-word', cursor: 'auto' },
  },
  {
    id: 'url',
    Header: 'Company URL',
    accessor: 'url',
    minWidth: 100,
    className: 'col-left col-wrap is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'col-left is-hidden-mobile is-hidden-tablet-only',
  },
  {
    id: 'emailDomains',
    Header: 'Email Domains',
    accessor: 'emailDomains',
    minWidth: 100,
    className: 'col-left col-wrap is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'col-left is-hidden-mobile is-hidden-tablet-only',
  },
  {
    id: 'employeeCount',
    Header: 'Employees',
    accessor: 'employeeCount',
    className: 'col-left is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'col-leftis-hidden-mobile is-hidden-tablet-only',
    minWidth: 50,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'invitedCount',
    Header: 'Invites',
    accessor: 'invitedCount',
    className: 'col-left is-hidden-mobile is-hidden-tablet-only',
    headerClassName: 'col-leftis-hidden-mobile is-hidden-tablet-only',
    minWidth: 50,
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 'addToBalance',
    accessor: 'id',
    minWidth: 100,
    headerClassName: 'is-hidden-mobile',
    Cell: (props) => (
      <div className="flex-column">
        <Button
          onClick={() => onAddToBalance(props.value)}
          className="is-small !font-agenda-semibold flex justify-center items-center  leading-none !rounded !text-[14px]"
          spanClassName="translate-y-[1px]"
        >
          Add to Balance
        </Button>
      </div>
    ),
    style: { textAlign: 'right' },
  },
];

export default companiesColumns;
