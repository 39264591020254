import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import cx from 'classnames';
import useClipboard from 'react-use-clipboard';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import set from 'lodash/set';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as SearchIcon } from 'assets/images/icons/search/search-dark.svg';
import { ReactComponent as FilterIcon } from 'assets/images/icons/filter/filter.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/icons/download/download.svg';
import { ReactComponent as DownChevron } from 'assets/images/icons/chevron/down-chevron-small.svg';

import UserLedgerTable from 'components/UserLedgerTable/UserLedgerTable';
import Currency from 'components/Currency/Currency';
import Avatar from 'components/Avatar/Avatar';
import SavedPaymentMethods from 'components/SavedPaymentMethods/SavedPaymentMethods';
import ElementsWrapper from 'components/ElementsWrapper';

import useCheckoutStore from 'stores/CheckoutStoreV2';
import useDashboardStore from 'stores/DashboardStore';
import { navigate, useLocation, useParams } from '@reach/router';
import { getQueryParam } from 'util/stringUtils';
import useWalletStore from 'stores/WalletStoreV2';
import config from '../../config/index';

const CREDIT = 'CREDIT';
const DEBIT = 'DEBIT';

const getTitleAndSubtitle = ({
  description,
  eventId,
  subscriptionChargeId,
}) => {
  let title = description;
  let subtitle = '';
  const descriptionPreface = description?.split(' ').slice(0, 2).join(' ');
  const descriptionRemainder = description?.split(' ').slice(2).join(' ');
  const isEvent = Boolean(eventId);

  if (descriptionPreface === 'Payroll deduction') {
    title = 'Payroll Deposit';
    subtitle = 'payroll';
  } else if (subscriptionChargeId) {
    title = 'Monthly Recur Donation';
    subtitle = 'Taxable Contribution';
  } else if (descriptionPreface === 'Donation to') {
    title = descriptionRemainder;

    if (isEvent) {
      title = (
        <a href={`/event/${eventId}`}>
          Cauze: {title.replace('and others', '')}
        </a>
      );
    }

    subtitle = 'Grant';
  } else if (descriptionPreface === 'Gift to') {
    title = `Gift to: ${descriptionRemainder}`;
    subtitle = 'Gift';
  } else if (descriptionPreface === 'Gift from') {
    title = `From: ${descriptionRemainder}`;
    subtitle = 'Gift Received';
  } else if (descriptionPreface === 'Payment processing') {
    title = 'Transaction Fee';
    subtitle = 'Bank Fee';
  } else if (
    descriptionPreface === 'Refund of' &&
    descriptionRemainder?.includes('gift')
  ) {
    const email = descriptionRemainder.split(' ').at(-1);
    title = `Gift to: ${email}`;

    if (descriptionRemainder.includes('expired')) {
      subtitle = 'Gifts Unused';
    } else {
      subtitle = 'Gifts Revoked';
    }
  } else if (descriptionPreface === 'Transfer to') {
    title = `Serving ${descriptionRemainder}`;
    subtitle = 'Grant';
  } else if (
    descriptionPreface === 'Fund own' ||
    descriptionPreface === 'Fund donation' ||
    descriptionPreface === 'Reward for' ||
    descriptionPreface === 'Fund user'
  ) {
    title = 'Contribution';
    subtitle = 'Taxable Contribution';
  }

  return { title, subtitle };
};

const transformBalanceDebitsToLedger = (userBalanceCreditsAndDebits) => {
  const mapped = userBalanceCreditsAndDebits
    .map((data) => ({
      ...data,
      createdAt: moment(
        data.createdAt?.replace(/\.\d+/, '') ||
          data.insertedAt?.replace(/\.\d+/, ''),
      ),
    }))
    .map((data) => ({
      ...data,
      key: `${data.type}${data.id}`,
      ...getTitleAndSubtitle(data),
    }))
    .sort((a, b) => {
      const unixA = a.createdAt.unix();
      const unixB = b.createdAt.unix();

      if (
        unixA === unixB &&
        a.balanceType === DEBIT &&
        b.balanceType === DEBIT
      ) {
        return a.endingBalance - b.endingBalance;
      }

      if (
        unixA === unixB &&
        a.balanceType === CREDIT &&
        b.balanceType === CREDIT
      ) {
        return b.endingBalance - a.endingBalance;
      }

      if (unixA === unixB && a.balanceType !== b.balanceType) {
        return a.balanceType === CREDIT ? 1 : -1;
      }

      return unixB - unixA;
    });
  return mapped;
};

const CompanyActivityFilterInput = ({
  type,
  name,
  label,
  filters,
  setFilters,
  options,
}) => {
  const inputRef = useRef();
  let value = get(filters, name);

  if (type.includes('date') && value) {
    value = moment(value, 'YYYY-MM-DD').format('MM/DD/YY');
  }

  if (type === 'select') {
    return (
      <div>
        <select
          value={value || ''}
          onChange={(e) => {
            setFilters({ ...set(filters, name, e.target.value) });
          }}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  if (type === 'range') {
    return (
      <div className="range-input-container">
        <div className="dollar-sign">$</div>
        <input
          className="range-input"
          placeholder={label}
          onChange={(e) => {
            setFilters({ ...set(filters, name, e.target.value) });
          }}
          value={value || ''}
        />
      </div>
    );
  }

  return (
    <div>
      <button className="small" onClick={() => inputRef.current.showPicker()}>
        <span className={value ? 'has-value' : undefined}>
          {value || label}
        </span>
        <DownChevron />
      </button>
      <input
        style={{ visibility: 'hidden', position: 'absolute', bottom: 0 }}
        name={name}
        type="date"
        ref={inputRef}
        onChange={(e) => {
          setFilters({ ...set(filters, name, e.target.value) });
        }}
      />
    </div>
  );
};

const CompanyActivityFilter = ({
  name,
  label,
  type,
  filters,
  setFilters,
  options,
}) => {
  const inputs = useMemo(() => {
    if (type === 'date_range') {
      return (
        <>
          <CompanyActivityFilterInput
            type={type}
            label="From"
            name={`${name}.from`}
            filters={filters}
            setFilters={setFilters}
          />
          <CompanyActivityFilterInput
            type={type}
            label="To"
            name={`${name}.to`}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      );
    }

    if (type === 'range') {
      return (
        <>
          <CompanyActivityFilterInput
            type={type}
            label="From"
            name={`${name}.from`}
            filters={filters}
            setFilters={setFilters}
          />
          <CompanyActivityFilterInput
            type={type}
            label="To"
            name={`${name}.to`}
            filters={filters}
            setFilters={setFilters}
          />
        </>
      );
    }

    if (type === 'select') {
      return (
        <CompanyActivityFilterInput
          type={type}
          options={options}
          name={name}
          filters={filters}
          setFilters={setFilters}
        />
      );
    }
  }, [type, filters, setFilters, name]);

  return (
    <div className="user-activity-filter">
      <div className="user-activity-filter-name">{label}</div>
      <div className="user-activity-filter-inputs">{inputs}</div>
    </div>
  );
};

const CompanyActivityFilterGroup = ({
  filters,
  setFilters,
  clearFilters,
  _setFilters = { _setFilters },
}) => {
  return (
    <div className="user-activity-filter-group">
      <CompanyActivityFilter
        name="date_range"
        label="date range"
        type="date_range"
        filters={filters}
        setFilters={setFilters}
      />
      <CompanyActivityFilter
        name="credit_debit"
        label="credit / debit"
        type="select"
        options={[
          {
            label: 'Any',
            value: '',
          },
          {
            label: 'Credit',
            value: CREDIT,
          },
          {
            label: 'Debit',
            value: DEBIT,
          },
        ]}
        filters={filters}
        setFilters={setFilters}
      />
      <CompanyActivityFilter
        name="amount"
        label="amount"
        type="range"
        filters={filters}
        setFilters={setFilters}
      />
      <CompanyActivityFilter
        name="type"
        label="type"
        type="select"
        options={[
          {
            label: 'Any',
            value: '',
          },
          {
            label: 'Grant',
            value: 'Grant',
          },
          {
            label: 'Taxable Contribution',
            value: 'Taxable Contribution',
          },
          {
            label: 'Gifts Given',
            value: 'Gifts Given',
          },
          {
            label: 'Bank Fee',
            value: 'Bank Fee',
          },
          {
            label: 'Payroll',
            value: 'payroll',
          },
        ]}
        filters={filters}
        setFilters={setFilters}
      />
      <div className="btn-container">
        <button
          className="clear-filters-btn"
          onClick={() => {
            clearFilters();
          }}
        >
          Clear Filters
        </button>
        <button
          className="see-results-btn"
          onClick={() => {
            _setFilters(filters);
          }}
        >
          See Results
        </button>
      </div>
    </div>
  );
};

const CompanyActivityFilters = ({
  isMobile,
  filters: _filters,
  setFilters: _setFilters,
  activeTab,
}) => {
  const inputRef = useRef();
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [showSearch, setShowSearch] = useState(false);

  const clearFilters = () => {
    setFilters({
      search: filters.search,
    });

    if (!isEqual(filters, _filters)) {
      _setFilters({
        search: _filters.search,
      });
    }
  };

  useEffect(() => {
    if (showSearch && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showSearch]);

  if (isMobile) {
    return (
      <div>
        <div
          className={cx('user-activity-search-mobile', {
            hidden: activeTab !== 0,
          })}
        >
          <button
            className="search-mobile"
            onClick={() => {
              if (!showSearch === false) {
                setFilters({ ...filters, search: '' });
                _setFilters({ ...filters, search: '' });
              }

              setShowSearch(!showSearch);
            }}
          >
            <span>Search</span>
            <SearchIcon width={15} height={15} />
          </button>
          {false && (
            <button className="download-mobile">
              <span>Download</span>
              <DownloadIcon height={15} width={15} />
            </button>
          )}
        </div>
        {showSearch && (
          <div className="user-activity-search-filters">
            <div className="user-activity-search">
              <div className="user-activity-search-bar">
                <SearchIcon
                  width={15}
                  height={15}
                  onClick={() => {
                    _setFilters(filters);
                  }}
                />
                <form action="." onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="search"
                    autoComplete="off"
                    ref={inputRef}
                    placeholder="Search transactions"
                    onChange={(e) =>
                      setFilters({ ...filters, search: e.target.value })
                    }
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        _setFilters(filters);
                        inputRef.current.blur();
                      }
                    }}
                    value={filters.search || ''}
                  />
                </form>
                {Boolean(filters.search) && (
                  <button
                    className="close-button"
                    onClick={() => {
                      setFilters({ ...filters, search: '' });
                      _setFilters({ ...filters, search: '' });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}
              </div>
            </div>
            <CompanyActivityFilterGroup
              filters={filters}
              setFilters={setFilters}
              _setFilters={_setFilters}
              clearFilters={clearFilters}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="user-activity-filters">
      <div className="user-activity-search">
        <div className="user-activity-search-bar">
          <SearchIcon
            width={15}
            height={15}
            onClick={() => {
              _setFilters(filters);
            }}
          />
          <input
            type="search"
            ref={inputRef}
            placeholder="Search transactions"
            onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                _setFilters(filters);
              }
            }}
            value={filters.search || ''}
          />
          {Boolean(filters.search) && (
            <button
              className="close-button"
              onClick={() => {
                setFilters({ ...filters, search: '' });
                _setFilters({ ...filters, search: '' });
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
        <button
          className={cx('user-activity-filter-btn', {
            active: showFilters,
          })}
          onClick={() => {
            if (!showFilters === false) {
              clearFilters();
            }

            setShowFilters(!showFilters);
          }}
        >
          <FilterIcon />
        </button>
        {false && (
          <button className="user-activity-download-btn">
            <span>Download</span>
            <DownloadIcon height={15} width={15} />
          </button>
        )}
      </div>
      {showFilters && (
        <CompanyActivityFilterGroup
          filters={filters}
          setFilters={setFilters}
          _setFilters={_setFilters}
          clearFilters={clearFilters}
        />
      )}
    </div>
  );
};

const CompanyActivitySection = ({ children, title, className }) => {
  return (
    <div className={`user-activity-section ${className}`}>
      <div className="user-activity-section-title">{title}</div>
      {children}
    </div>
  );
};

const CompanyActivityAccountDetails = ({
  matches,
  userId,
  isMobile,
  checkoutStore,
}) => {
  const walletStore = useWalletStore();

  const filteredMatches = matches.filter(
    (match) => match.active && match.currentEntityRemaining > 0,
  );

  return (
    <div className="user-activity-details">
      <CompanyActivitySection
        className="user-activity-payment-details"
        title="Saved Payment Methods"
      >
        <ElementsWrapper
          elementOptions={{
            mode: 'setup',
            setupFutureUsage: 'off_session',
            amount: undefined,
          }}
        >
          <SavedPaymentMethods
            walletStore={walletStore}
            cards={walletStore.cards}
            userId={userId}
            checkoutStore={checkoutStore}
          />
        </ElementsWrapper>
      </CompanyActivitySection>
      {filteredMatches.length > 0 && (
        <CompanyActivitySection
          className="user-activity-eligible-matches"
          title="Eligible Matches"
        >
          <div className="user-activity-matches-list">
            {filteredMatches.map((match, index) => (
              <div
                className={cx('user-activity-match', {
                  'has-border': index <= matches.length - 1,
                })}
              >
                <Avatar avatarUrls={match.matchAdmin.avatarUrls} />
                <div className="user-activity-match-container">
                  <div className="user-activity-match-remaining">
                    <span className="user-activity-match-current">
                      <Currency amount={match.currentEntityRemaining} />
                    </span>{' '}
                    of{' '}
                    <span>
                      <Currency amount={match.matchLimit} />
                    </span>
                  </div>
                  <div className="user-activity-match-name">
                    {match.matchAdmin.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </CompanyActivitySection>
      )}
    </div>
  );
};

const CompanyActivityView = ({
  profileStore,
  uiStore,
  companyStore,
  companyReportingStore,
}) => {
  const walletStore = useWalletStore();
  const location = useLocation();
  const [ledger, setLedger] = useState([]);
  const [isMobile, setIsMobile] = useState(document.body.clientWidth <= 768);
  const [activeTab, setActiveTab] = useState(0);
  const [filters, setFilters] = useState({});
  const { updateProps, showLoadingScreen } = useDashboardStore();
  const stripe = useStripe();
  const elements = useElements();

  const { companyId } = useParams();
  const activeEntity = useMemo(() => {
    return (
      profileStore.availableUserEntities?.find(
        (entity) => entity.entityType === 'COMPANY' && entity.id === companyId,
      ) || {}
    );
  }, [profileStore.availableUserEntities, companyId]);

  const userProfile = companyStore.companies.get(
    +companyId || +activeEntity.id,
  );
  const checkoutStore = useCheckoutStore();

  const fetchLedger = async (loadMore = false) => {
    const data = await companyStore.getCompanyActivity({
      id: activeEntity.id,
      loadMore,
      filters: companyStore.ledgerFilters,
    });
    const balanceLedger = transformBalanceDebitsToLedger(data);
    return balanceLedger;
  };

  const handleAddFundsClick = () => {
    checkoutStore.chooseDefaultPaymentMethod();
    uiStore.openModal('CHECKOUT', {
      stripe,
      elements,
      activeEntity,
      uiStore,
      userProfile,
      profileStore,
      checkoutType: 'gift',
      giftType: 'SELF_GIFT',
      amount: 500,
      onCheckoutSuccess: async (state) => {
        if (state.giftType === 'SELF_GIFT') {
          uiStore.showNotification({
            body: `$${state.amount / 100} was added to your account`,
            type: 'SUCCESS',
          });
        } else {
          uiStore.showNotification({
            body: `${state.amount === 0 ? 'Invites' : 'Gifts'} Sent`,
            type: 'SUCCESS',
          });
        }

        walletStore.getPaymentMethods({
          userContext: { userId: activeEntity.id },
        });
        profileStore.getProfile();
        fetchLedger().then((newLedger) => {
          setLedger(newLedger);
        });
      },
    });
  };

  useEffect(() => {
    companyStore.getCompany({
      id: activeEntity.id,
      userContext: { companyId: activeEntity.id },
    });
  }, [activeEntity]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 425 && !isMobile) {
        setIsMobile(true);
      } else if (window.innerWidth > 425 && isMobile) {
        setIsMobile(false);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    fetchLedger().then((newLedger) => {
      setLedger(newLedger);
    });
  }, [companyId]);

  useEffect(() => {
    updateProps({
      noPaddingLedger: true,
      size: 'half',
      title: 'Account Activity',
    });
    walletStore.setStripeObject(stripe);
    walletStore.getInitial({
      userContext: { companyId: activeEntity.id },
    });
    walletStore.generatePlaidLinkToken();
  }, []);

  useEffect(() => {
    const ledgerFilters = {};

    if (filters.date_range) {
      const { from, to } = filters.date_range;

      if (from) {
        ledgerFilters['date_from'] = moment(from, 'YYYY-MM-DD').format(
          'YYYY-MM-DD 00:00:00',
        );
      }

      if (to) {
        ledgerFilters['date_to'] = moment(to, 'YYYY-MM-DD').format(
          'YYYY-MM-DD 23:59:59',
        );
      }
    }

    if (filters.credit_debit) {
      ledgerFilters['balance_type'] = filters.credit_debit;
    }

    if (filters.type) {
      ledgerFilters['item_type'] = filters.type;
    }

    if (filters.amount) {
      if (filters.amount.from) {
        const fromAmount = parseFloat(filters.amount.from) * 100;
        ledgerFilters['amount_from'] = fromAmount;
      }

      if (filters.amount.to) {
        const toAmount = parseFloat(filters.amount.to) * 100;
        ledgerFilters['amount_to'] = toAmount;
      }
    }

    if (filters.search) {
      ledgerFilters.search = filters.search;
    }

    companyStore.setLedgerFilters(ledgerFilters);
    fetchLedger(false).then((newLedger) => {
      setLedger(newLedger);
    });
  }, [filters]);

  useEffect(() => {
    const tabFromUrl = getQueryParam(location.search, 'active-tab');
    if (tabFromUrl !== null) {
      setActiveTab(Number(tabFromUrl));
    }
  }, [location.search]);

  useEffect(() => {
    if (!companyStore.ledgerLoading) {
      showLoadingScreen('');
    }
  }, [companyStore.ledgerLoading]);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);

    // Remove the 'active-tab' parameter from the URL
    const params = new URLSearchParams(location.search);
    params.delete('active-tab');
    const newSearch = params.toString();
    navigate(`${location.pathname}${newSearch ? `?${newSearch}` : ''}`, {
      replace: true,
    });
  };

  return (
    <div className="user-activity-view ">
      <div className="user-activity-body">
        <div className="user-activity-funds">
          <div className="user-activity-current-balance">
            <div className="user-activity-current-balance-amount">
              <Currency
                showCents
                showDollarSign
                amount={activeEntity?.balance?.total}
              />
            </div>
            <div className="user-activity-current-balance-text">
              Current Balance
            </div>
          </div>
          <div className="user-activity-fund-buttons">
            <button
              type="button"
              className="black"
              onClick={handleAddFundsClick}
            >
              ADD FUNDS
            </button>
            <button
              type="button"
              className="outlined"
              onMouseDown={(ev) => {
                ev.preventDefault();
              }}
              onClick={() => {
                companyReportingStore
                  .getDownloadToken(companyId, 'company/ledger')
                  .then((token) =>
                    navigate(
                      `${config.API_ROOT}/company/${companyId}/ledger/${token}`,
                    ),
                  );
              }}
            >
              DOWNLOAD LEDGER
            </button>
          </div>
        </div>
        {isMobile && (
          <CompanyActivityFilters
            isMobile={isMobile}
            filters={filters}
            setFilters={setFilters}
            activeTab={activeTab}
          />
        )}
        <div className="user-activity-tabs">
          <button
            className={cx('', {
              active: activeTab === 0,
            })}
            onClick={() => handleTabChange(0)}
          >
            Transactions
          </button>
          <button
            className={cx('', {
              active: activeTab === 1,
            })}
            onClick={() => handleTabChange(1)}
          >
            Account Details
          </button>
        </div>
        {!isMobile && activeTab === 0 && (
          <CompanyActivityFilters
            isMobile={isMobile}
            filters={filters}
            setFilters={setFilters}
          />
        )}
        <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
          <UserLedgerTable
            ledger={ledger}
            isMobile={isMobile}
            loadMore={
              companyStore.ledgerHasMore &&
              (async () => {
                const moreLedgers = await fetchLedger(true);
                setLedger(ledger.concat(moreLedgers));
              })
            }
            isFetching={companyStore.ledgerLoadingMore}
            loading={companyStore.ledgerLoading}
          />
        </div>
        <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
          <CompanyActivityAccountDetails
            cards={walletStore.cards}
            matches={[userProfile?.profileData?.companyMatch].filter((m) => m)}
            userId={activeEntity.id}
            elements={elements}
            walletStore={walletStore}
            isMobile={isMobile}
            checkoutStore={checkoutStore}
          />
        </div>
      </div>
    </div>
  );
};

export default inject(
  'profileStore',
  'uiStore',
  'companyStore',
  'companyReportingStore',
)(observer(CompanyActivityView));
