import React from 'react';
import { Link, navigate } from '@reach/router';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';

import { getAppLink } from 'util/userAgent';
import { headerDownloadLinkPress } from 'util/events/nav';
import withUserContext from 'behaviors/withUserContext';

import Currency from 'components/Currency/Currency';
import CauzeButton from 'components/CauzeButton/CauzeButton';
import UserDropDown from './UserDropDown';

import { ReactComponent as CauzeLogo } from 'assets/images/brand/cauze-logo.svg';
import { ReactComponent as MenuLogo } from 'assets/images/icons/svgs/menu.svg';
import giftIcon from 'assets/images/icons/gift/gift-box-color.png';
import logo from 'assets/images/brand/cauze-logotype-light.svg';
import CharitySearch from 'components/CharitySearch/GlobalCharitySearch';
import ActivityDropDown from './ActivityDropDown/ActivityDropDown';
import useMobile from 'hooks/useMobile';

const GiftCard = ({ amount }) => (
  <Link className="gift-card" to="/donate">
    <img className="gift-icon" src={giftIcon} />
    {amount && (
      <Currency amount={amount} superCents className="text-title gift-amount" />
    )}
  </Link>
);

const NavbarMobile = ({
  expanded,
  setExpanded,
  noSidebar,
  renderGiftCard,
  isLaptop,
  searchStore,
  isAuthenticated,
  activeEntity,
  redirectAfterContextSelect,
  hideContextSelector,
  hideLoginPrompt,
  uiStore,
}) => {
  if (!isLaptop) return <></>;

  return (
    <>
      <div
        className={classNames('flex gap-x-2', {
          invisible: noSidebar,
        })}
      >
        <button
          className="bg-transparent border-none text-white h-8 w-8 my-auto !p-0"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <MenuLogo height="32px" width="32px" />
        </button>
        {renderGiftCard()}{' '}
      </div>
      <div className="flex justify-center">
        <a onClick={headerDownloadLinkPress} href={'/donate'} className="flex">
          <img src={logo} className="app-logo my-auto h-8" alt="Cauze" />
        </a>
      </div>
      <div className="flex justify-end gap-x-4">
        {isAuthenticated ? (
          <>
            <ActivityDropDown
              id="user-activity-mobile"
              activeEntity={activeEntity}
            />
            <UserDropDown
              id="user-dropdown-mobile"
              redirectAfterContextSelect={redirectAfterContextSelect}
              hideContextSelector={hideContextSelector}
              activeEntity={activeEntity}
            />
          </>
        ) : (
          <>
            <div className="flex items-center">
              {!hideLoginPrompt ? (
                <div className="flex-row flex-center">
                  <button
                    className="cz-btn-outline h-10"
                    id="nav-login-button-mobile"
                    support
                    onClick={() => navigate('/login')}
                  >
                    Login
                  </button>
                  <button
                    className="cz-btn-brand h-10 hidden sm:block"
                    onClick={() => navigate('/signup')}
                  >
                    Signup
                  </button>
                </div>
              ) : (
                <CauzeButton
                  onClick={headerDownloadLinkPress}
                  href={getAppLink()}
                >
                  {uiStore.hasApp ? 'Open in Cauze' : 'Download App'}
                </CauzeButton>
              )}
            </div>
          </>
        )}
      </div>
      {!hideLoginPrompt && (
        <div className="flex col-span-3">
          <CharitySearch isLaptop={isLaptop} searchStore={searchStore} />
        </div>
      )}
    </>
  );
};

const NavbarDesktop = ({
  isLaptop,
  searchStore,
  isAuthenticated,
  activeEntity,
  redirectAfterContextSelect,
  hideContextSelector,
  renderGiftCard,
  hideLoginPrompt,
  uiStore,
  isCobrand,
  cobrand,
}) => {
  if (isLaptop) return <></>;

  return (
    <>
      <div className="flex items-center">
        <a
          onClick={headerDownloadLinkPress}
          href={'/donate'}
          className="content-center justify-start"
        >
          {isCobrand && cobrand.logo ? (
            <div className="cobrand">
              <img
                src={cobrand.logo.original}
                className="app-logo app-logo-cobrand"
                alt={cobrand.name}
              />
              <div className="powered-by">
                Powered by
                <img src={logo} className="app-logo" alt="Cauze" />
              </div>
            </div>
          ) : (
            <CauzeLogo color="#ffffff" />
          )}
        </a>
      </div>
      <div className="my-auto">
        {!hideLoginPrompt && (
          <CharitySearch isLaptop={isLaptop} searchStore={searchStore} />
        )}
      </div>
      {isAuthenticated ? (
        <div className="flex justify-end gap-x-[14px]">
          <ActivityDropDown
            id="user-activity-mobile"
            activeEntity={activeEntity}
          />
          <UserDropDown
            id="user-dropdown-mobile"
            redirectAfterContextSelect={redirectAfterContextSelect}
            hideContextSelector={hideContextSelector}
            activeEntity={activeEntity}
          />
        </div>
      ) : (
        <div className="flex gap-x-2 justify-end">
          <div className="my-auto"> {renderGiftCard()} </div>
          <div className="flex items-center justify-end my-auto">
            {!hideLoginPrompt ? (
              <div className="flex-row flex-center gap-x-2">
                <button
                  className="cz-btn-outline h-10"
                  id="nav-login-button-mobile"
                  support
                  onClick={() => navigate('/login')}
                >
                  Login
                </button>
                <button
                  className="cz-btn-brand hidden sm:block h-10"
                  onClick={() => navigate('/signup')}
                >
                  Signup
                </button>
              </div>
            ) : (
              <CauzeButton
                onClick={headerDownloadLinkPress}
                href={getAppLink()}
              >
                {uiStore.hasApp ? 'Open in Cauze' : 'Download App'}
              </CauzeButton>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const Navbar = ({
  authStore,
  searchStore,
  hideLoginPrompt,
  noSidebar,
  uiStore,
  cobrand, // this is a company object
  activeEntity = {},
  hideContextSelector = false,
  redirectAfterContextSelect = true,
  expanded,
  setExpanded,
}) => {
  const isCobrand = cobrand && cobrand.id !== '1';
  const isAuthenticated = authStore.isAuthenticated;
  const { isLaptop } = useMobile();
  const renderGiftCard = () => {
    if (uiStore.savedUiState.userToken?.gift) {
      return (
        <div className="gift-container h-10">
          <GiftCard
            amount={
              !uiStore.savedUiState.userToken.gift.hideAmount &&
              uiStore.savedUiState.userToken.gift?.amount
            }
          />
        </div>
      );
    }

    return null;
  };

  // TODO: find a more appropiate fix for this problem
  console.log(uiStore?.savedUiState?.userToken?.gift);

  return (
    <nav
      className="w-screen bg-black py-[10px] lg:py-[14px] px-4 lg:px-[62px] gap-y-1.5 shadow-deep lg:h-[76px] grid dashboard-navbar"
      role="navigation"
      aria-label="main navigation"
    >
      {global.IS_DEV ? <div className="env-indicator dev">DEV</div> : null}
      {global.IS_LOCAL ? (
        <div className="env-indicator local">LOCAL</div>
      ) : null}

      <NavbarDesktop
        isLaptop={isLaptop}
        searchStore={searchStore}
        isAuthenticated={isAuthenticated}
        activeEntity={activeEntity}
        redirectAfterContextSelect={redirectAfterContextSelect}
        hideContextSelector={hideContextSelector}
        renderGiftCard={renderGiftCard}
        hideLoginPrompt={hideLoginPrompt}
        uiStore={uiStore}
        isCobrand={isCobrand}
        cobrand={cobrand}
      />
      <NavbarMobile
        expanded={expanded}
        setExpanded={setExpanded}
        noSidebar={noSidebar}
        renderGiftCard={renderGiftCard}
        isLaptop={isLaptop}
        searchStore={searchStore}
        isAuthenticated={isAuthenticated}
        activeEntity={activeEntity}
        redirectAfterContextSelect={redirectAfterContextSelect}
        hideContextSelector={hideContextSelector}
        hideLoginPrompt={hideLoginPrompt}
        uiStore={uiStore}
      />
    </nav>
  );
};

export default withUserContext(
  inject(
    'uiStore',
    'authStore',
    'eventStore',
    'profileStore',
    'searchStore',
  )(observer(Navbar)),
);
