import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Link, navigate } from '@reach/router';
import cx from 'classnames';
import { ReactComponent as EllipsisIcon } from 'assets/images/icons/dots/three-dots-hollow.svg';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import Avatar from 'components/Avatar/Avatar';

import { shorten, showEndedText } from 'util/stringUtils';
import LargeNumber from 'components/LargeNumber/LargeNumber';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import CauzePinButton from 'components/CauzePinButton/CauzePinButton';
import useMobile from 'hooks/useMobile';
import ActionMenu from 'components/ActionMenu/ActionMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';

const EventListItemWithActionMenu = ({
  event,
  onPin,
  isSelf,
  uiStore,
  matchStore,
  profileStore,
  activeMenuId,
  userContext,
  setActiveMenuId,
  index,
  hideActionMenu = false,
  updatePlannedEventState,
}) => {
  const [pinned, setPinned] = useState(true);

  const eventItemImageClassName = useMemo(() => {
    const baseClasssName = 'event-item-image';

    if (event.image?.md) {
      return baseClasssName;
    }

    const i = event.name?.length % 6;
    return `${baseClasssName} background-gradient-${i + 1}`;
  }, [event]);

  const giverCount = useMemo(() => {
    return Math.max(
      event.purchaserCount || 0,
      event.topPurchasers?.length || 0,
    );
  }, [event]);

  let ended = showEndedText(event);
  let match =
    event.matchSummaries &&
    event.matchSummaries.find((match) => match.matchAdmin.isSelf);

  const handlePin = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!pinned) {
      setPinned(!pinned);
      onPin(e);
    } else {
      // show confirmation modal on unpin
      uiStore.openModal('CONFIRM', {
        message: 'Unpin cauze?',
        action: () => {
          setPinned(!pinned);
          onPin(e);
        },
        confirmText: 'Confirm',
        bodyMessage:
          'Unpinning this cauze will cause it no longer appear on your profile.',
      });
    }
  };

  const eventHost = event.initiator || event.hosts?.[0];

  if (!pinned) return <></>;

  return (
    <div
      className="event-item"
      key={`${event.name}-${event.id}`}
      onClick={() => navigate(`/event/${event.id}`)}
    >
      <div className="event-item-header">
        {isSelf && <CauzePinButton pinned={pinned} onPin={handlePin} />}
        <div className="event-item-overlay" />
        <div
          className={eventItemImageClassName}
          style={{
            backgroundImage: event.image?.md
              ? `url('${event.image?.md}')`
              : undefined,
          }}
        />
      </div>
      <div className="event-item-body">
        <div className="flex justify-between gap-1">
          <div className="event-item-name">{shorten(event.name, 60)}</div>
          {!hideActionMenu && (
            <ActionMenu
              isSmaller
              className=" -mr-2 h-fit"
              onToggleOpen={(ev) => {
                ev.stopPropagation();
                setActiveMenuId(event.id);
              }}
              onToggleClose={(ev) => {
                ev?.stopPropagation();
                setActiveMenuId(null);
              }}
              isActive={event.id === activeMenuId}
              actions={[
                {
                  name: 'View',
                  action: () => window.open(event.shareLink, '_blank'),
                },
                {
                  name: 'Edit',
                  action: (ev) => {
                    ev.stopPropagation();
                    console.log('edit');
                    profileStore.navToEditCauze(userContext, event.id);
                  },
                },
                ...(!ended && match
                  ? [
                      {
                        name: match.active ? 'End Match' : 'Restart Match',
                        action: () =>
                          matchStore.toggleMatch({
                            matchId: match.id,
                            active: !match.active,
                          }),
                      },
                    ]
                  : []),
                event.eventState !== 'INACTIVE' && {
                  name: 'End event',
                  action: async (ev) => {
                    setActiveMenuId(null);
                    ev.stopPropagation();
                    await updatePlannedEventState({
                      eventId: event.id,
                      eventState: 'INACTIVE',
                      index,
                      userContext,
                    });
                  },
                },
                {
                  name:
                    event.eventState === 'UNPUBLISHED' ||
                    event.eventState === 'INACTIVE'
                      ? 'Show to Public'
                      : 'Hide from Public',
                  action: async (ev) => {
                    ev.stopPropagation();
                    setActiveMenuId(null);
                    await updatePlannedEventState({
                      eventId: event.id,
                      eventState:
                        event.eventState === 'UNPUBLISHED'
                          ? 'ACTIVE'
                          : 'UNPUBLISHED',
                      index,
                      userContext,
                    });
                  },
                },
              ].filter((opt) => opt)}
            />
          )}
        </div>
        <div className="event-item-host">
          <Avatar avatarUrls={eventHost?.avatarUrls} />
          <div className="event-item-host-text">
            <div>by: </div>
            <div className="event-item-host-name">{eventHost?.name}</div>
          </div>
        </div>
        <div className="event-item-givers">
          {Boolean(giverCount) && Boolean(event.topPurchasers?.length) && (
            <div className="event-item-giver-count">
              <LargeNumber amount={giverCount} /> Giver
              {giverCount !== 1 && 's'}:
            </div>
          )}
          <div className="event-item-avatar-group">
            {event.topPurchasers?.slice(0, 5).map((topPurchaser) => (
              <Avatar xsm avatarUrls={topPurchaser.actor.avatarUrls} />
            ))}
            {giverCount >= 5 && (
              <EllipsisIcon className="event-item-ellipsis" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const EventListItem = ({ event = {}, onPin, isSelf, uiStore, loading }) => {
  const [pinned, setPinned] = useState(true);

  const eventItemImageClassName = useMemo(() => {
    const baseClasssName = 'event-item-image';

    if (event.image?.md) {
      return baseClasssName;
    }

    const i = event.name?.length % 6;
    return `${baseClasssName} background-gradient-${i + 1}`;
  }, [event]);

  const giverCount = useMemo(() => {
    return Math.max(
      event.purchaserCount || 0,
      event.topPurchasers?.length || 0,
    );
  }, [event]);

  const handlePin = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!pinned) {
      setPinned(!pinned);
      onPin(e);
    } else {
      // show confirmation modal on unpin
      uiStore.openModal('CONFIRM', {
        message: 'Unpin cauze?',
        action: () => {
          setPinned(!pinned);
          onPin(e);
        },
        confirmText: 'Confirm',
        bodyMessage:
          'Unpinning this cauze will cause it no longer appear on your profile.',
      });
    }
  };

  const eventHost = event.initiator || event.hosts?.[0];

  if (!pinned) return <></>;

  const content = (
    <div
      className="event-item"
      key={`${event.name}-${event.id}`}
      onClick={() => navigate(`/event/${event.id}`)}
    >
      <div className="event-item-header">
        {isSelf && <CauzePinButton pinned={pinned} onPin={handlePin} />}
        <div className="event-item-overlay" />
        <div
          className={eventItemImageClassName}
          style={{
            backgroundImage: event.image?.md
              ? `url('${event.image?.md}')`
              : undefined,
          }}
        />
      </div>
      <div className="event-item-body">
        <div className="event-item-name">{shorten(event.name, 60)}</div>
        <div className="event-item-host">
          <Avatar avatarUrls={eventHost?.avatarUrls} />
          <div className="event-item-host-text">
            <div>by: </div>
            <div className="event-item-host-name">{eventHost?.name}</div>
          </div>
        </div>
        <div className="event-item-givers">
          {Boolean(giverCount) && Boolean(event.topPurchasers?.length) && (
            <div className="event-item-giver-count">
              <LargeNumber amount={giverCount} /> Giver
              {giverCount !== 1 && 's'}:
            </div>
          )}
          <div className="event-item-avatar-group">
            {event.topPurchasers?.slice(0, 5).map((topPurchaser) => (
              <Avatar xsm avatarUrls={topPurchaser.actor.avatarUrls} />
            ))}
            {giverCount >= 5 && (
              <EllipsisIcon className="event-item-ellipsis" />
            )}
          </div>
        </div>
      </div>
    </div>
  );

  if (!loading) return content;

  return (
    <div className="relative rounded-[10px] overflow-hidden">
      <div className="absolute w-full h-full bg-lightgray-e8e animate-pulse" />
      <div className="invisible">{content}</div>
    </div>
  );
};

const EventList = ({ events, loading, onPin, isSelf, uiStore }) => {
  const [listEvents, setListEvents] = useState(events);
  const { isMobile } = useMobile();

  useEffect(() => {
    setListEvents(events);
  }, [events]);

  const displayEvents = useMemo(() => {
    if (loading) {
      return Array.from({ length: 3 }).map((id) => ({ id }));
    }

    return listEvents;
  }, [listEvents, loading]);

  const swiper = useSwiper();
  // Custom Prev and Next icons with better styling
  const customPrevIcon = (
    <FontAwesomeIcon
      icon={faChevronLeft}
      style={{ fontSize: '1.5rem', color: '#333' }}
    />
  );
  const customNextIcon = (
    <FontAwesomeIcon
      icon={faChevronRight}
      style={{ fontSize: '1.5rem', color: '#333' }}
    />
  );

  // Handle slide change to hide/show arrows
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleSlideChange = (swiper) => {
    setIsAtBeginning(swiper.isBeginning);

    setIsAtEnd(swiper.isEnd);
  };
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);

  if (loading) {
    return (
      <div className="event-list">
        <CauzeSpinner />
      </div>
    );
  }

  return (
    <Swiper
      className={cx('event-list', {
        'show-arrows': listEvents && listEvents.length > 2,
      })}
      modules={[Navigation]}
      spaceBetween={8}
      slidesPerView={isMobile ? 1.5 : 2.3}
      onSlideChange={handleSlideChange}
      navigation={{
        prevEl: 'swipe-button-prev',
        nextEl: 'swipe-button-next',
      }}
      onInit={(swiper) => {
        setSwiperRef(swiper);
        console.log(swiper, 'swiper');
        swiper.prevEl = prevRef.current;
        swiper.nextEl = nextRef.current;
        swiper.navigation.update();
      }}
      onSwiper={setSwiperRef}
    >
      {displayEvents.map((event) => (
        <SwiperSlide key={event.id}>
          <EventListItem
            event={event}
            isSelf={isSelf}
            uiStore={uiStore}
            loading={loading}
            onPin={(e) => {
              e.stopPropagation();
              e.preventDefault();

              if (event.currentEntityHasPinned) {
                setListEvents(listEvents.filter((evt) => evt.id !== event.id));
              }

              onPin?.({
                eventId: event.id,
                pinned: !event.currentEntityHasPinned,
              });
            }}
          />
        </SwiperSlide>
      ))}
      {/* Custom Navigation Buttons */}
      <div
        ref={prevRef}
        onClick={() => swiperRef.slidePrev()}
        className={`swipe-button-prev ${isAtBeginning ? '!hidden' : ''}`}
      >
        {customPrevIcon}
      </div>
      <div
        ref={nextRef}
        onClick={() => {
          console.log(swiperRef, swiper, 'swiperRef');
          swiperRef.slideNext();
        }}
        className={`swipe-button-next ${isAtEnd ? '!hidden' : ''}`}
      >
        {customNextIcon}
      </div>
    </Swiper>
  );
};

const EventListWithArrowsAbove = ({
  events,
  loading,
  onPin,
  isSelf,
  uiStore,
  viewAllHref,
  title,
  className = '',
  titleClasses = '',
  iconSize = 'md',
  bleeding = false,
}) => {
  const [listEvents, setListEvents] = useState(events);
  const { isMobile } = useMobile();

  useEffect(() => {
    setListEvents(events);
  }, [events]);

  const displayEvents = useMemo(() => {
    if (loading) {
      return Array.from({ length: 3 }).map((id) => ({ id }));
    }

    return listEvents;
  }, [listEvents, loading]);

  // Handle slide change to hide/show arrows
  const [isAtBeginning, setIsAtBeginning] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleSlideChange = (swiper) => {
    setIsAtBeginning(swiper.isBeginning);

    setIsAtEnd(swiper.isEnd);
  };
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiperRef, setSwiperRef] = useState(null);

  if (!loading && !displayEvents?.length) {
    return <></>;
  }

  return (
    <>
      <div
        className={`flex justify-between items-center font-agenda-bold mb-5 ${className}`}
      >
        <h2
          className={cx(titleClasses, {
            'text-[22px] leading-none': !titleClasses,
          })}
        >
          {title}
        </h2>
        {swiperRef && swiperRef.allowSlideNext && !isMobile && (
          <div className="flex gap-2 items-center justify-center">
            {/* Custom Navigation Buttons */}
            <div
              ref={prevRef}
              onClick={() => swiperRef.slidePrev()}
              className={`py-1.5 px-2.5  flex items-center justify-center border border-solid border-[#adadb6] cursor-pointer rounded-full  swiper-arrows-above-button-prev ${
                isAtBeginning ? '!cursor-default opacity-40' : ''
              }`}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                size={iconSize}
                style={{ color: '#2a2a33', transform: 'translateX(-1px)' }}
              />
            </div>
            <div
              ref={nextRef}
              onClick={() => {
                swiperRef.slideNext();
              }}
              className={`py-1.5 px-2.5  flex items-center justify-center border border-solid border-[#adadb6] cursor-pointer rounded-full  swiper-arrows-above-button-next ${
                isAtEnd ? '!cursor-default opacity-40' : ''
              }`}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                size={iconSize}
                style={{ color: '#2a2a33', transform: 'translateX(1px)' }}
              />
            </div>
          </div>
        )}
        {isMobile && viewAllHref && listEvents && listEvents.length === 5 && (
          <Link
            className="text-black text-[17px] my-auto underline"
            to={viewAllHref}
          >
            View All
          </Link>
        )}
      </div>

      <Swiper
        className={cx(
          '',

          {
            'show-arrows': listEvents && listEvents.length > 2,
            'event-list': !bleeding,
            'bleeding-list': bleeding,
          },
        )}
        modules={[Navigation]}
        spaceBetween={8}
        slidesPerView={isMobile ? 1.5 : 2.3}
        slidesPerGroup={isMobile ? 1 : 2}
        onSlideChange={handleSlideChange}
        navigation={{
          prevEl: 'swiper-arrows-above-button-prev',
          nextEl: 'swiper-arrows-above-button-next',
        }}
        onInit={(swiper) => {
          setSwiperRef(swiper);
          swiper.prevEl = prevRef.current;
          swiper.nextEl = nextRef.current;
          swiper.navigation.update();
        }}
        onSwiper={setSwiperRef}
      >
        {displayEvents.map((event) => (
          <SwiperSlide key={event.id}>
            <EventListItem
              event={event}
              isSelf={isSelf}
              loading={loading}
              uiStore={uiStore}
              onPin={(e) => {
                e.stopPropagation();
                e.preventDefault();

                if (event.currentEntityHasPinned) {
                  setListEvents(
                    listEvents.filter((evt) => evt.id !== event.id),
                  );
                }

                onPin?.({
                  eventId: event.id,
                  pinned: !event.currentEntityHasPinned,
                });
              }}
            />
          </SwiperSlide>
        ))}
        {viewAllHref && !isMobile && listEvents && listEvents.length === 5 && (
          <SwiperSlide key={viewAllHref} className="self-center">
            <Link
              className="bg-white border-cauze-dark border border-solid text-cauze-dark rounded-xl mx-10 px-6 py-3 flex items-center gap-2 justify-center font-semibold text-[17px] hover:bg-cauze-dark hover:text-white transition-all duration-200  shadow-sm"
              to={viewAllHref}
            >
              View All
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </SwiperSlide>
        )}
      </Swiper>
    </>
  );
};
export { EventListItem, EventListItemWithActionMenu, EventListWithArrowsAbove };
export default EventList;
