import React, { useState, useRef, useEffect } from 'react';
import ReactTable from 'react-table';
import classnames from 'classnames';
import {
  getUnwrappedFilteredRows,
  getCharityTotals,
} from '../../util/tableUtils';
import { debounce } from 'lodash';
import useKeyboardEvent from '../../hooks/useKeyboardEvent.js';
import CharityDetailsModal from 'components/modals/CharityDetailsModal';
import columns from './CharityTableColumns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faTimes,
  faCaretUp,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

const SupportedCharitiesTable = ({
  charities,
  totalGood,
  totalMatchedPortion,
  totalDonations,
  keyField = 'id',
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(charities.length);
  const [searchActive, setSearchActive] = useState(false);
  const [showCharityDetail, setShowCharityDetail] = useState(null);
  const [mobileSortActive, setMobileSortActive] = useState(false);
  const [mobileSort, setMobileSort] = useState({
    id: 'totalDonated',
    desc: true,
  });

  const MAX_PAGE_SIZE = 200;

  let charitiesTableRef = useRef(null);
  let searchInputRef = useRef(null);
  const debouncedSetSearchTerm = debounce(setSearchTerm, 1000);
  useEffect(() => {
    if (searchActive) {
      searchInputRef.current.focus();
    }
  }, [searchActive]);

  useKeyboardEvent('Escape', () => {
    toggleSearch(false);
  });

  const clearSearch = () => {
    setSearchTerm('');
    searchInputRef.current.value = '';
  };

  const toggleSearch = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(false);
      setSearchActive(true);
      searchInputRef.current.focus();
      return;
    }
    setSearchActive(false);
    searchInputRef.current.blur();
    clearSearch();
  };

  const toggleMobileSort = (isOpen = true) => {
    if (isOpen) {
      setMobileSortActive(true);
      return;
    }
    setMobileSortActive(false);
  };

  const rowFn = (_state, rowInfo, _column, _instance) => ({
    onClick: (_e, handleOriginal) => {
      setShowCharityDetail(rowInfo.original.id);
      if (handleOriginal) {
        handleOriginal();
      }
    },
  });

  const { filteredTotalGood, filteredMatched, filteredDonations } =
    getCharityTotals(getUnwrappedFilteredRows(charitiesTableRef));

  const charitiesColumns = columns({
    totalGood: filteredTotalGood || totalGood,
    totalMatchedPortion: filteredMatched || totalMatchedPortion,
    totalDonations: filteredDonations || totalDonations,
  });

  return (
    <div className="rt-container">
      {showCharityDetail && (
        <CharityDetailsModal
          isOpen
          charity={charities.find(
            (charity) => charity.id === showCharityDetail,
          )}
          onToggleClose={() => setShowCharityDetail(null)}
          charityId={showCharityDetail}
        />
      )}
      <div className="action-bar flex-row">
        <div className="responsive-row">
          <div className={classnames('search-input')}>
            <div className="input-container">
              <div
                tabIndex="0"
                onFocus={() => toggleSearch(true)}
                onClick={searchTerm !== '' ? clearSearch : toggleSearch}
                className="action-button-search"
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className={classnames('', {
                    orange: searchTerm !== '',
                  })}
                />
              </div>
              <input
                tabIndex={0}
                autoFocus={searchActive}
                className="input"
                placeholder="Search Charities&#8230;"
                ref={searchInputRef}
                onChange={(e) => debouncedSetSearchTerm(e.target.value)}
                onFocus={toggleSearch}
                onBlur={() => setSearchActive(false)}
              />
              <div
                alt="clear all"
                className="action-button close-button flex-column"
                disabled={!(searchActive || searchTerm !== '')}
                onClick={() => {
                  toggleSearch(false);
                  toggleMobileSort(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          </div>
        </div>
        <div className="responsive-row flex-expand mr-2.5">
          <div className="table-info">
            Supported Non-Profits ({charities.length})
          </div>
          <div className="flex-row flex-expand is-hidden-tablet"></div>
          <div className="flex-row">
            <div
              className={classnames(
                'action-button sort-action flex-column is-hidden-tablet',
              )}
              onClick={
                mobileSortActive
                  ? () =>
                      setMobileSort({ ...mobileSort, desc: !mobileSort.desc })
                  : () => {
                      setMobileSort({ ...mobileSort, desc: !mobileSort.desc });
                      toggleMobileSort(true);
                    }
              }
            >
              <FontAwesomeIcon
                icon={faCaretUp}
                className={classnames('flex items-end translate-y-[3px]', {
                  orange: mobileSort.desc,
                })}
              />
              <FontAwesomeIcon
                icon={faCaretDown}
                className={classnames('flex items-start translate-y-[-3px]', {
                  orange: !mobileSort.desc,
                })}
              />
            </div>
          </div>
        </div>
      </div>
      <ReactTable
        filterable
        className="charity-table"
        resizable={false}
        keyField={keyField}
        ref={charitiesTableRef}
        selectType="checkbox"
        columns={charitiesColumns}
        data={charities}
        getTrProps={rowFn}
        getTheadProps={() => ({ className: 'is-hidden-mobile' })}
        pageSize={MAX_PAGE_SIZE}
        minRows={0}
        filtered={[{ id: 'name', value: searchTerm }]}
        defaultSorted={[{ id: 'totalDonated', desc: true }]}
        showPaginationBottom={pageSize > MAX_PAGE_SIZE}
        defaultPageSize={MAX_PAGE_SIZE}
        onFiltersChange={(filtered, columns) => console.log(filtered, columns)}
        onFilterUpdateLength={(len) => setPageSize(len)}
        {...(mobileSortActive > 0 && { sorted: [mobileSort] })}
      />
    </div>
  );
};

export default SupportedCharitiesTable;
