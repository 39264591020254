import React, { useEffect } from 'react';

import CreateCauzeView from './CreateCauzeView';
import useCreateCauze from 'hooks/useCreateCauze';
import withAuthRequired from 'behaviors/withAuthRequired';
import useDashboardStore from 'stores/DashboardStore';

const CharityCreateCauzeView = ({ charityId, eventId }) => {
  const { setProps } = useDashboardStore();
  useCreateCauze({ userContext: { charityId }, eventId });

  useEffect(() => {
    setProps({
      size: 'screen',
    });
  }, []);

  return <CreateCauzeView userContext={{ charityId }} eventId={eventId} />;
};

export default withAuthRequired(CharityCreateCauzeView, {
  entityType: 'CHARITY',
});
