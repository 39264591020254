import React from 'react';
import { observer, inject } from 'mobx-react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

import { ReactComponent as HelpIcon } from 'assets/images/icons/svgs/help.svg';

import Currency from 'components/Currency/Currency';

import { getProcessingChargeMessage } from 'util/checkoutUtils';
import useCheckoutStore from 'stores/CheckoutStoreV2';

const HelpIconWithTooltip = ({ paymentType }) => {
  const state = useCheckoutStore();
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ placement: 'top' });

  return (
    <>
      <HelpIcon ref={setTriggerRef} />
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container tooltip-container-checkout-summary',
          })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          <div className="tooltip-body">
            <div className="tooltip-title">Processing Fee</div>
            {getProcessingChargeMessage({
              ...state,
              paymentType: paymentType || state.paymentType,
            })}
          </div>
        </div>
      )}
    </>
  );
};

const CheckoutSummary = ({ uiStore, loading }) => {
  const store = useCheckoutStore();
  const amount =
    store.splitAmount?.forCauze ??
    store.splitAmount?.remaining ??
    store.amount ??
    0;
  const feeAmount = store.splitAmount?.fee ?? 0;
  const totalAmount = store.splitAmount?.total ?? amount ?? 0;
  const paymentType = store.getPaymentType().toLowerCase();

  return (
    <div className="checkout-summary mb-2">
      {!loading && paymentType !== 'balance' && (
        <>
          <div className="line-item">
            <div className="line-item-name">
              <span>Your Donation</span>
            </div>
            <div className="line-item-amount">
              <Currency showCents amount={amount} />
            </div>
          </div>
          {Boolean(feeAmount) && (
            <div className="line-item">
              <div className="line-item-name">
                <span className="processing-fee">Processing Fee </span>
                <HelpIconWithTooltip
                  uiStore={uiStore}
                  paymentType={paymentType}
                />
              </div>
              <div className="line-item-amount">
                <Currency showCents amount={feeAmount} />
              </div>
            </div>
          )}
          <div className="line-item total">
            <div className="font-agenda-bold h-4">Total Due</div>
            <div className="line-item-amount">
              <Currency showCents amount={totalAmount} />
            </div>
          </div>
        </>
      )}
      {!loading && paymentType === 'balance' && (
        <>
          <div className="line-item">
            <div>Your Donation: </div>
            <div>
              <Currency showCents amount={totalAmount} />
            </div>
          </div>
          <div className="line-item total">
            <div className="font-agenda-bold h-4">Total Due</div>
            <div className="line-item-amount">
              <Currency showCents amount={totalAmount} />
            </div>
          </div>
        </>
      )}
      {loading && (
        <>
          <div className="line-item animate-pulse bg-lightgray-e8e" />
          <div className="line-item animate-pulse bg-lightgray-e8e" />
          <div className="line-item animate-pulse bg-lightgray-e8e" />
        </>
      )}
    </div>
  );
};

export default inject('uiStore')(observer(CheckoutSummary));
