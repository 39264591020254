import React, { useMemo } from 'react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import Avatar from 'components/Avatar/Avatar';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import LargeNumber from 'components/LargeNumber/LargeNumber';
import useMobile from 'hooks/useMobile';

const CharityListItem = ({
  charity,
  onSelect,
  onLearnMore,
  showLink = false,
  disabled = false,
}) => {
  const handleClick = (ev) => {
    if (showLink) {
      return;
    }
    ev.preventDefault();
    if (disabled) {
      return;
    }
    if (!!onSelect) {
      onSelect(charity.id);
    } else if (!!onLearnMore) {
      onLearnMore(charity.id);
    }
  };

  const hero = charity.hero?.md || charity.heroUrls?.md;
  const heroClassName = useMemo(() => {
    const baseClassName = 'charity-item-hero';

    if (hero) {
      return baseClassName;
    }

    const i = charity.name.length % 6;

    return `${baseClassName} background-gradient-${i + 1}`;
  }, [charity]);

  const getCity = () => {
    return (charity.project?.city || charity.city)?.toLowerCase();
  };

  const getState = () => {
    return (charity.project?.state || charity.state)?.toLowerCase();
  };

  const getFollowerCount = () => {
    return charity.project?.totalFollowers ?? charity.followerCount ?? 0;
  };

  return (
    <a
      href={showLink ? `/charity/${charity.id}` : ''}
      className="charity-item"
      onClick={handleClick}
    >
      <div className="charity-item-hero-container">
        <div
          className={heroClassName}
          style={{
            backgroundImage: hero ? `url('${hero}')` : undefined,
          }}
        />
        <div className="charity-item-hero-backdrop" />
        <Avatar
          md
          noBorder
          className="avatar"
          type="charity"
          avatar={charity.avatar || charity.avatarUrls}
        />
      </div>
      <div className="charity-item-body">
        <div className="charity-item-name">{charity.name}</div>
        <div className="charity-item-sub">
          <span className="charity-item-city">{getCity()}</span>
          {Boolean(getCity() && getState()) && <span>, </span>}
          <span className="charity-item-state">{getState()}</span>
        </div>
        <div className="charity-item-sub">
          <LargeNumber amount={getFollowerCount()} /> Follower
          {getFollowerCount() !== 1 && 's'}
        </div>
      </div>
    </a>
  );
};

const CharityList = ({
  charities,
  onLearnMore,
  onSelect,
  selected = [],
  showLink,
  disabled = false,
  hideShadow,
  rounded,
  loading,
  mobileSlidePerView = 2.5,
  slidesPerView = 3.5,
}) => {
  const { isMobile } = useMobile();

  if (loading) {
    return (
      <div className="event-list">
        <CauzeSpinner />
      </div>
    );
  }

  return (
    <Swiper
      className={cx('charity-list', {
        'show-arrows': charities && charities.length > 2,
      })}
      navigation
      modules={[Navigation]}
      spaceBetween={8}
      slidesPerView={isMobile ? mobileSlidePerView : slidesPerView}
      slidesPerGroup={2}
    >
      {charities.map((charity) => (
        <SwiperSlide key={charity.id}>
          <CharityListItem
            charity={charity}
            onSelect={onSelect}
            isSelected={selected.includes(charity.id)}
            onLearnMore={onLearnMore}
            disabled={disabled}
            hideShadow={hideShadow}
            showLink={showLink}
            rounded={rounded}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export { CharityListItem };
export default CharityList;
