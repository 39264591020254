import gql from 'graphql-tag';

const notificationsQuery = gql`
  query notificationsQuery($page: Int!, $pageSize: Int!) {
    notificationFeed(page: $page, pageSize: $pageSize) {
      items {
        id
        read
        type
        date
        purchaseName
        event {
          id
          name
          image {
            md
          }
        }
        purchase {
          id
        }
        comment {
          body
        }
        actor {
          id
          name
          type
          avatarUrls {
            sm
            md
          }
        }
        subject {
          id
          uuid
          name
          type
        }
        comment {
          body
        }
        verbMeta {
          amount
          comment
          recipientId
          recipientName
        }
      }
      unreadCount
    }
  }
`;

const unreadNotificationCountQuery = gql`
  query unreadNotificationCountQuery($page: Int!, $pageSize: Int!) {
    notificationFeed(page: $page, pageSize: $pageSize) {
      unreadCount
    }
  }
`;

const markNotificationReadMutation = gql`
  mutation markNotificationRead($id: ID!) {
    markNotificationRead(id: $id)
  }
`;

const markAllNotificationsReadMutation = gql`
  mutation markAllNotificationsRead($user_context: UserContext) {
    markAllNotificationsRead(userContext: $user_context)
  }
`;

const markAllNotificationsReadV2Mutation = gql`
  mutation markAllNotificationsRead {
    markAllNotificationsRead {
      success
    }
  }
`;

export {
  notificationsQuery,
  unreadNotificationCountQuery,
  markNotificationReadMutation,
  markAllNotificationsReadMutation,
  markAllNotificationsReadV2Mutation,
};
