import React from 'react';
import cx from 'classnames';
import { useStripe, useElements } from '@stripe/react-stripe-js';

import AddCard from 'components/SavedPaymentMethods/AddCard';
import useCheckoutStore from 'stores/CheckoutStoreV2';
import SubmitButton from './SubmitButton';

const AddNewCardContent = ({ onSubmit, authStore }) => {
  const stripe = useStripe();
  const elements = useElements();
  const checkoutStore = useCheckoutStore();

  const labelClass = cx(
    'font-agenda-bold font-[16px] text-black leading-tight',
  );
  const inputClass = cx(
    'mt-1 w-full rounded-lg pl-2 text-[17px] font-agenda border-lightgray-c4c border-solid border-[1px] h-[45px]',
  );

  return (
    <div className="flex-1 w-full px-5 flex flex-col justify-between">
      {authStore.isAuthenticated && (
        <div>
          <div className="flex justify-center w-full">
            <div className="font-agenda-bold text-[20px] mx-auto py-2">
              Credit Card
            </div>
          </div>
        </div>
      )}
      <div
        className={cx('h-full flex flex-col', {
          'pt-5': !authStore.isAuthenticated,
        })}
      >
        {!authStore.isAuthenticated && (
          <div className="grid grid-cols-2 gap-x-2.5 gap-y-4">
            <div>
              <label className={labelClass}>First Name</label>
              <input
                className={inputClass}
                value={checkoutStore.firstName}
                onChange={(e) => {
                  checkoutStore.update({
                    firstName: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <label className={labelClass}>Last Name</label>
              <input
                className={inputClass}
                value={checkoutStore.lastName}
                onChange={(e) => {
                  checkoutStore.update({
                    lastName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="col-span-2">
              <label className={labelClass}>Email Address*</label>
              <input
                className={inputClass}
                value={checkoutStore.email}
                onChange={(e) => {
                  checkoutStore.update({
                    email: e.target.value,
                  });
                }}
              />
              <div className="text-[12px] mt-2 font-agenda leading-none mb-5 lg:mb-[30px]">
                We need this to send you your receipt we won’t send you
                marketing emails.
              </div>
            </div>
          </div>
        )}
        {!['APPLE_PAY', 'GOOGLE_PAY'].includes(
          checkoutStore.activePaymentMethod?.paymentType,
        ) && <AddCard showAddCard defaultOpen isCheckout />}
        <div className="mt-auto">
          <SubmitButton
            disabled={!checkoutStore.email || !checkoutStore.addCardIsComplete}
            onSubmit={() => onSubmit({ stripe, elements })}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewCardContent;
