import React from 'react';
import { observer } from 'mobx-react';
import Avatar from 'components/Avatar/Avatar';
import linkifyHtml from 'linkify-html';
import { getPrettyDate } from '../../util/dateUtils';
import { normalizeContext } from '../../util/contextUtils';
import heartRed from '../../assets/images/icons/heart/heart-red.svg';
import heartGrey from '../../assets/images/icons/heart/heart-grey.svg';

import { convertMarkdownToFormatted, sanitizer } from '../../util/stringUtils';

// This should probably be refactored into a component that calls this and also returns
// a div with the html dangerouslySetInner already applied since we do this in multiple places
const prepareFeedItemBody = (body) => {
  let transformed = linkifyHtml(body, { defaultProtocol: 'https' });
  transformed = convertMarkdownToFormatted(transformed);
  transformed = sanitizer(transformed, {
    ADD_ATTR: ['target'],
  });
  return transformed;
};

const FeedItem = ({
  cauzePurchase: p,
  purchaseLikeCount: likes,
  onLike,
  isAuthenticated,
  index,
  userContext,
  onUnauthedLike,
}) => (
  <div className="flex-row flex-align-center fundraiser-feed-item" key={index}>
    <Avatar md avatar={p.actor.avatarUrls} />
    <div className="flex-column item-content">
      <p className="flex-row invested">
        <p className="item-actor">{p.actor.name}</p>
        &nbsp;invested
      </p>
      {p.commentBody && (
        <div
          className="item-comment-body"
          dangerouslySetInnerHTML={{
            __html: prepareFeedItemBody(p.commentBody),
          }}
        />
      )}
      <div className="flex-row flex-align-center item-meta">
        <p className="item-date">{getPrettyDate(p.insertedAt)}</p>
        <div
          className="likes-container flex-row flex-align-center"
          onClick={() => {
            if (!isAuthenticated) {
              onUnauthedLike();
            } else {
              onLike &&
                onLike({
                  id: p.id,
                  userContext: normalizeContext(userContext),
                  like: !p.currentEntityLiked,
                  index,
                });
            }
          }}
        >
          {p.currentEntityLiked ? (
            <img src={heartRed} />
          ) : (
            <img src={heartGrey} />
          )}
          <p className={`item-likes ${p.currentEntityLiked && 'liked'}`}>
            {likes} likes
          </p>
        </div>
      </div>
    </div>
  </div>
);

const FundraiserFeed = ({
  items,
  onLike,
  onUnauthedLike,
  isAuthenticated,
  userContext,
  uiStore,
}) => {
  if (!items) return null;

  return (
    <div className="fundraiser-feed-container">
      {items.map((item, index) => (
        <FeedItem
          item={item}
          onLike={onLike}
          onUnauthedLike={onUnauthedLike}
          isAuthenticated={isAuthenticated}
          index={index}
          userContext={userContext}
          uiStore={uiStore}
        />
      ))}
    </div>
  );
};

export default observer(FundraiserFeed);
