import { useEffect, useState } from 'react';
import cx from 'classnames';

import getCardIcon from 'util/getCardIcon';

import Currency from 'components/Currency/Currency';
import AppleButton from 'components/Button/AppleButton';
import AddCard from 'components/SavedPaymentMethods/AddCard';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import useCheckoutStore from 'stores/CheckoutStoreV2';
import GoogleButton from 'components/Button/GoogleButton';
import PaypalButton from 'components/Button/PaypalButton';
import VenmoButton from 'components/Button/VenmoButton';
import useWalletStore from 'stores/WalletStoreV2';
import SubmitButton from './SubmitButton';

const PaymentMethodOption = ({ card, isActive, onClick }) => {
  const containerClassName = cx(
    'font-agenda flex justify-between px-2.5 py-[15px] rounded-[10px] capitalize cursor-pointer hover:bg-lightgray-e8e',
    {
      'bg-lightgray-e8e': isActive,
    },
  );

  return (
    <div className={containerClassName} onClick={onClick}>
      <div className="text-sm h-[14px] my-auto">{card.name || card.brand}</div>
      <div className="flex gap-x-[5px] text-sm">
        {card.brand && <img src={getCardIcon(card.brand)} />}
        {card.last4 && (
          <>
            <div className="h-4 my-auto">****</div>
            <div className="h-4 my-auto">{card.last4}</div>
          </>
        )}
        {card.balance && (
          <>
            <div className="flex h-4 my-auto gap-x-1">
              <span className="text-gray-595">Available Balance</span>
              <Currency showCents amount={card.balance} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const PaymentMethodsContent = ({
  cards = [],
  isMobile,
  onAddCard,
  authStore,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const checkoutStore = useCheckoutStore();
  const walletStore = useWalletStore();
  const { FRAME } = checkoutStore;

  const [selectedCard, setSelectedCard] = useState(
    checkoutStore.activePaymentMethod,
  );
  const [showAddCard, setShowAddCard] = useState(false);

  const handleConfirm = async (card) => {
    checkoutStore.update({ activePaymentMethod: card });
    await checkoutStore.updateCheckout();
    checkoutStore.setFrame(isMobile ? FRAME.CHECKOUT_SUMMARY : FRAME.CHECKOUT);
  };

  useEffect(() => {
    setSelectedCard(checkoutStore.activePaymentMethod);
  }, [checkoutStore.activePaymentMethod]);

  return (
    <div className="px-5 flex flex-col h-full">
      <div className="flex justify-center w-full pb-2.5">
        <div className="font-agenda-bold text-[20px] mx-auto py-2">
          Payment Method
        </div>
      </div>
      <div
        className={cx('overflow-auto', {
          'flex-1 pb-2.5 mb-2.5': cards?.length,
        })}
      >
        {walletStore.balance >= checkoutStore.splitAmount?.total &&
          checkoutStore.giftType?.toUpperCase() !== 'SELF_GIFT' && (
            <PaymentMethodOption
              card={{
                name: 'Cauze Balance',
                balance: walletStore.balance,
              }}
              isActive={selectedCard?.paymentType === 'BALANCE'}
              onClick={() => {
                setSelectedCard({
                  paymentType: 'BALANCE',
                });
              }}
            />
          )}
        {cards.map((card) => (
          <PaymentMethodOption
            key={card.id}
            card={card}
            isActive={selectedCard?.id === card.id}
            onClick={() => {
              setSelectedCard(card);
            }}
          />
        ))}
      </div>
      {window.ApplePaySession ? (
        <AppleButton
          className="mb-1.5"
          onClick={() => {
            handleConfirm({ paymentType: 'APPLE_PAY' });
          }}
        />
      ) : (
        <GoogleButton
          className="mb-1.5"
          onClick={() => {
            handleConfirm({ paymentType: 'GOOGLE_PAY' });
          }}
        />
      )}
      <div className="flex gap-x-1.5 mb-1.5">
        <PaypalButton
          className="flex-1"
          onClick={() => {
            checkoutStore.setActivePaymentMethod({
              paymentType: 'paypal',
            });

            checkoutStore.setFrame(
              isMobile ? FRAME.CHECKOUT_SUMMARY : FRAME.CHECKOUT,
            );
          }}
        />
        <VenmoButton
          className="flex-1"
          onClick={() => {
            checkoutStore.setActivePaymentMethod({
              paymentType: 'paypal',
            });

            checkoutStore.setFrame(
              isMobile ? FRAME.CHECKOUT_SUMMARY : FRAME.CHECKOUT,
            );
          }}
        />
      </div>
      <div className="mb-2">
        {isMobile ? (
          <button
            className="cz-btn-outline black w-full !border-gray-949"
            onClick={() => {
              checkoutStore.setFrame(FRAME.ADD_NEW_CARD);
            }}
          >
            {authStore.isAuthenticated ? 'Add New Card' : 'Use Credit Card'}
          </button>
        ) : (
          <AddCard
            showAddCard={showAddCard}
            setShowAddCard={setShowAddCard}
            isCheckout
            onAddCard={() => {
              checkoutStore.setFrame(FRAME.ADD_NEW_CARD);
            }}
          />
        )}
      </div>
      <div className="mt-auto">
        <SubmitButton
          disabled={!selectedCard && !showAddCard}
          onSubmit={async () => {
            let card = selectedCard;

            if (showAddCard) {
              card = await onAddCard({ elements, stripe });
              checkoutStore.update({ activePaymentMethod: card });
              setSelectedCard(card);
              setShowAddCard(false);
            }

            handleConfirm(card);
          }}
        />
      </div>
    </div>
  );
};

export default PaymentMethodsContent;
