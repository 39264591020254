import { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { inject, observer } from 'mobx-react';
import cx from 'classnames';

import SliderModal from '../SliderModal/SliderModal';
import { ReactComponent as ArrowLeftIcon } from 'assets/images/icons/arrows/arrow-left.svg';

import withUserContext from 'behaviors/withUserContext';
import AddFundsContent from './AddFundsContent';
import PaymentMethodsContent from './PaymentMethodsContent';
import ConfirmationContent from './ConfirmationContent';
import AddNewCardContent from './AddNewCardContent';
import ElementsWrapper from 'components/ElementsWrapper';

import useCheckoutStore, { CHECKOUT_TYPE } from 'stores/CheckoutStoreV2';
import CauzeSpinner from 'components/CauzeSpinner/CauzeSpinner';
import useMobile from 'hooks/useMobile';
import useWalletStore from 'stores/WalletStoreV2';
import CheckoutCompleteContent from './CheckoutCompleteContent';
import ErrorContent from './ErrorContent';
import GiftCheckoutContent from './GiftCheckoutContent';
import ConfirmationPopover from 'components/Popover/ConfirmationPopover';
import mixpanel from 'mixpanel-browser';

const CheckoutModal = ({
  onToggleClose,
  isOpen,
  profileStore,
  uiStore,
  authStore,
  duration = 200,
  onCheckoutSuccess,
  ...props
}) => {
  const [closed, setClosed] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const { isMobile } = useMobile();
  const walletStore = useWalletStore();
  const stripe = useStripe();
  const elements = useElements();
  const checkoutStore = useCheckoutStore();
  const { FRAME, frame } = checkoutStore;

  const onToggleCancel = () => {
    onToggleClose();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isMobile && frame !== FRAME.CHECKOUT_SUMMARY) {
      checkoutStore.setFrame(
        checkoutStore.activePaymentMethod
          ? FRAME.CHECKOUT_SUMMARY
          : FRAME.PAYMENT_METHOD,
      );
      return;
    }

    if (!authStore.isAuthenticated && frame === FRAME.CHECKOUT) {
      checkoutStore.setFrame(
        checkoutStore.activePaymentMethod
          ? FRAME.CHECKOUT_SUMMARY
          : FRAME.PAYMENT_METHOD,
      );
      return;
    }

    if (checkoutStore.amount === 0) {
      uiStore.showNotification({
        body: 'Please select an amount.',
        type: 'ERROR',
      });
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    try {
      setModalLoading(true);
      await checkoutStore.completeCheckout();

      if (onCheckoutSuccess) {
        onCheckoutSuccess({ ...checkoutStore });
      }
    } catch (err) {
      mixpanel.track('Checkout Submit Error', {
        error: `${err}`,
      });
      checkoutStore.setFrame(checkoutStore.FRAME.ERROR);
      return;
    } finally {
      setModalLoading(false);
    }

    if (
      [CHECKOUT_TYPE.DONATION, CHECKOUT_TYPE.GIFTED_DONATION].includes(
        checkoutStore.checkoutType,
      )
    ) {
      checkoutStore.setFrame(checkoutStore.FRAME.CHECKOUT_COMPLETE);
    } else {
      setClosed(true);
    }
  };

  const [asideProps] = useSpring(
    () => ({
      from: { right: isMobile ? 0 : -376, bottom: isMobile ? -376 : 0 },
      to: { right: 0, bottom: 0 },
      reverse: closed,
      config: {
        duration,
      },
      onResolve: () => {
        if (closed) {
          onToggleCancel();
        }
      },
    }),
    [isOpen, closed],
  );

  useEffect(() => {
    const asyncHandler = async () => {
      setModalLoading(true);

      let _frame = props.frame ?? 0;

      if (props?.giftToken) {
        _frame = FRAME.CHECKOUT_SUMMARY;
      }

      checkoutStore.update({
        stripe,
        elements,
        frame: _frame,
        onToggleClose,
        onCheckoutSuccess,
        ...props,
      });

      if (props.checkoutType !== checkoutStore.CHECKOUT_TYPE.GIFT) {
        await checkoutStore.getCheckoutDetails();
      }

      await checkoutStore.chooseDefaultPaymentMethod();

      if (props.checkoutType === checkoutStore.CHECKOUT_TYPE.DONATION) {
        await checkoutStore.startCheckout();
      } else if (props.checkoutType === checkoutStore.CHECKOUT_TYPE.GIFT) {
        await checkoutStore.updateCheckout();
      }
    };

    if (isOpen) {
      checkoutStore.reset();
      asyncHandler().finally(() => {
        setModalLoading(false);
        checkoutStore.update({ loading: false });
      });
    }

    return () => {
      checkoutStore.reset();
    };
  }, [isOpen]);

  return (
    <SliderModal
      onToggleClose={onToggleClose}
      isOpen={!closed}
      isActive={isOpen}
    >
      <animated.aside
        style={asideProps}
        className="checkout-modal absolute h-auto lg:h-screen flex flex-col w-screen lg:w-[376px] bg-white rounded-t-[20px] lg:rounded-l-[20px] lg:rounded-tr-none add-funds-sidebar pb-5 pt-5 overflow-y-auto"
      >
        <div className="self-end !absolute top-0 left-0 right-0">
          <div className="flex justify-between w-full pl-2">
            <button
              className={cx(
                'mt-2 bg-transparent border-none text-gray-1b2 cursor-pointer',
                {
                  invisible:
                    [
                      checkoutStore.FRAME.CHECKOUT,
                      checkoutStore.FRAME.CHECKOUT_COMPLETE,
                    ].includes(checkoutStore.frame) || modalLoading,
                },
              )}
              onClick={() => {
                checkoutStore.goBack();
              }}
            >
              <ArrowLeftIcon />
            </button>
            <ConfirmationPopover
              message="Are you sure you want to quit checkout?"
              onConfirm={() => setClosed(true)}
              className={cx('', {
                invisible:
                  [checkoutStore.FRAME.CHECKOUT_COMPLETE].includes(
                    checkoutStore.frame,
                  ) || modalLoading,
              })}
            >
              <div className="delete " />
            </ConfirmationPopover>
          </div>
        </div>
        {modalLoading ? (
          <CauzeSpinner />
        ) : (
          <>
            {frame === FRAME.CHECKOUT && (
              <AddFundsContent
                defaultAmount={checkoutStore.amount}
                uiStore={uiStore}
                profileStore={profileStore}
                authStore={authStore}
                loading={checkoutStore.loading}
                onSubmit={handleSubmit}
                isMobile={isMobile}
              />
            )}

            {frame === FRAME.PAYMENT_METHOD && (
              <ElementsWrapper
                elementOptions={{
                  mode: 'setup',
                  setupFutureUsage: 'off_session',
                  amount: undefined,
                }}
              >
                <PaymentMethodsContent
                  walletStore={walletStore}
                  authStore={authStore}
                  cards={walletStore.cards}
                  isMobile={isMobile}
                  onAddCard={async ({ elements, stripe }) => {
                    const newCard = await walletStore.registerNewCard({
                      elements: elements,
                      stripe: stripe,
                      userContext: checkoutStore.activeEntity?.userContext,
                    });
                    const stripeCardId = newCard.setupIntent.payment_method;
                    const foundCard = walletStore.cards.find(
                      (card) => card.stripeCardId === stripeCardId,
                    );

                    return foundCard;
                  }}
                />
              </ElementsWrapper>
            )}

            {frame === FRAME.CHECKOUT_SUMMARY && (
              <ConfirmationContent
                uiStore={uiStore}
                loading={checkoutStore.loading}
                onSubmit={handleSubmit}
              />
            )}

            {frame === FRAME.ADD_NEW_CARD && (
              <ElementsWrapper
                elementOptions={{
                  mode: 'setup',
                  setupFutureUsage: authStore.isAuthenticated
                    ? 'off_session'
                    : 'on_session',
                  amount: undefined,
                }}
              >
                <AddNewCardContent
                  authStore={authStore}
                  onSubmit={async ({
                    elements: addElements,
                    stripe: addStripe,
                  }) => {
                    checkoutStore.update({ loading: true });

                    try {
                      const cardResult = await walletStore.registerNewCard({
                        userContext: checkoutStore.activeEntity?.userContext,
                        elements: addElements,
                        stripe: addStripe,
                        isAuth: authStore.isAuthenticated,
                        email: checkoutStore.email,
                      });
                      checkoutStore.setActivePaymentMethod({
                        id: true,
                        brand: cardResult.brand,
                        last4: cardResult.last4,
                        nickname: null,
                        accountType: 'CARD',
                        feeDescription: cardResult.feeDescription,
                        autopayEnabled: false,
                        stripeCardId: cardResult.setupIntent.payment_method,
                        isDefault: true,
                      });

                      checkoutStore.update({
                        paymentType: 'stripe_cc',
                        customerId: cardResult.customerId,
                      });

                      if (!authStore.isAuthenticated) {
                        if (!checkoutStore.token) {
                          await checkoutStore.startCheckout();
                          await checkoutStore.updateCheckout();
                        } else {
                          await checkoutStore.updateCheckout();
                        }

                        checkoutStore.setFrame(FRAME.CHECKOUT_SUMMARY);
                      } else {
                        checkoutStore.update({
                          paymentType: 'stripe_cc',
                          customerId: cardResult.customerId,
                        });

                        if (!checkoutStore.token) {
                          await checkoutStore.startCheckout();
                          await checkoutStore.updateCheckout();
                        } else {
                          await checkoutStore.updateCheckout();
                        }

                        checkoutStore.setFrame(
                          isMobile ? FRAME.CHECKOUT_SUMMARY : FRAME.CHECKOUT,
                        );
                      }
                    } catch (err) {
                      // TODO: Handle error
                    } finally {
                      checkoutStore.update({ loading: false });
                    }
                  }}
                />
              </ElementsWrapper>
            )}

            {frame === FRAME.CHECKOUT_COMPLETE && <CheckoutCompleteContent />}

            {frame === FRAME.ERROR && (
              <ErrorContent
                isMobile={isMobile}
                loading={checkoutStore.loading}
              />
            )}

            {frame === FRAME.GIFT_CHECKOUT && (
              <GiftCheckoutContent
                onSubmit={handleSubmit}
                loading={checkoutStore.loading}
              />
            )}
          </>
        )}
      </animated.aside>
    </SliderModal>
  );
};

export default withUserContext(
  inject(
    'profileStore',
    'authStore',
    'uiStore',
    'donationLandingStore',
  )(observer(CheckoutModal)),
);
