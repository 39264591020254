import Avatar from 'components/Avatar/Avatar';

const JoinedEntity = ({ entity }) => {
  if (!entity) return <></>;

  return (
    <div className="flex justify-between pt-[11px] font-agenda text-[15px]">
      <div className="my-auto leading-tight">
        <div>You are joining: </div>
        <div className="font-agenda-bold">{entity.name}</div>
      </div>
      <Avatar className="my-auto" avatarUrls={entity?.avatarUrls} />
    </div>
  );
};

export default JoinedEntity;
