import { getEntityUrl } from './navHelpers';
import dompurify from 'dompurify';
import moment from 'moment';

const sanitizer = dompurify.sanitize;
const markdownRegexGlobal = /@\[([^[]*)]\(([^(^)]*)\)\(([^(^)]*)\)/g;
const markdownRegex = /@\[([^[]*)]\(([^(^)]*)\)\(([^(^)]*)\)/;

const shorten = (string, maxLength, ellipsis = true) => {
  const showEllipsis = string?.length > maxLength && ellipsis;
  return `${string?.substring(0, maxLength)}${showEllipsis ? '…' : ''}`;
};

const shortenRenderedHTML = (htmlString, maxLength, ellipsis = true) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  // const textContent = doc.body.textContent || ''; // Extract plain text from HTML

  // const truncatedText =
  //   textContent.length > maxLength
  //     ? `${textContent.substring(0, maxLength)}${ellipsis ? '…' : ''}`
  //     : textContent;

  // Replace the plain text content with the truncated version while keeping HTML structure intact
  let charCount = 0;
  const truncateNode = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const remaining = maxLength - charCount;
      if (remaining <= 0) {
        node.textContent = '';
      } else if (charCount + node.textContent.length > maxLength) {
        node.textContent =
          node.textContent.substring(0, remaining) + (ellipsis ? '…' : '');
        charCount = maxLength;
      } else {
        charCount += node.textContent.length;
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      [...node.childNodes].forEach(truncateNode);
    }
  };

  const clonedDoc = doc.body.cloneNode(true);
  truncateNode(clonedDoc);

  return clonedDoc.innerHTML;
};

const convertMarkdownToFormatted = (text) =>
  text.replace(markdownRegexGlobal, (str) => {
    let [_full, username, id, entityType] = str.match(markdownRegex);
    return `<a href="${getEntityUrl({ entityType, id })}">@${username}</a>`;
  });

const convertSnakeToSpaces = (text) =>
  text
    .split('_')
    .filter((x) => x.length > 0)
    .join(' ');

const getLedgerUrl = (id, entityType) => {
  if (entityType === 'CHARITY') {
    return `/admin/charity/${id}`;
  } else if (entityType === 'COMPANY') {
    return `/admin/company/${id}/activity`;
  } else {
    return `/admin/user/${id}/activity`;
  }
};
const getYourCauzesUrl = (id, entityType) => {
  if (entityType === 'USER') {
    return `/admin/user/${id}/cauzes`;
  } else if (entityType === 'COMPANY') {
    return `/admin/company/${id}/cauzes`;
  } else if (entityType === 'CHARITY') {
    return `/admin/charity/${id}/cauzes`;
  } else if (entityType === 'INFLUENCER') {
    return `/admin/influencer/${id}/cauzes`;
  } else {
    return '/cauzes';
  }
};
const getProfileUrl = (id, entityType) => {
  if (!id || !entityType) {
    return;
  }
  if (entityType === 'COMPANY') {
    return `/company/${id}`;
  } else if (entityType === 'CHARITY') {
    return `/charity/${id}`;
  } else {
    return `/profile/${id}`;
  }
};

const getQueryParam = (search, key) => {
  const params = new URLSearchParams(search);
  return params.get(key);
};

const showEndedText = (cauze) => {
  if (moment(cauze.endDate).isBefore(moment())) {
    return true;
  }

  if (cauze.eventState === 'INACTIVE') {
    return true;
  }
  return false;
};
export {
  shortenRenderedHTML,
  getProfileUrl,
  getQueryParam,
  getLedgerUrl,
  showEndedText,
  getYourCauzesUrl,
  shorten,
  convertMarkdownToFormatted,
  convertSnakeToSpaces,
  markdownRegex,
  sanitizer,
};
export const linkify = require('linkifyjs');
