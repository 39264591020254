/* eslint-disable no-console */

import React, { Component } from 'react';

import { observer, inject } from 'mobx-react';
import ReactGA from 'react-ga';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import branch from 'branch-sdk';
import { hotjar } from 'react-hotjar';
import { HelmetProvider } from 'react-helmet-async';

import CauzeRouter from './CauzeRouter';
import ModalNotificationProvider from './views/ModalNotificationProvider/ModalNotificationProvider';
import CauzeSpinner from './components/CauzeSpinner/CauzeSpinner';
import mixpanel from 'mixpanel-browser';

import './styles';
import CauzeRoutingProvider from 'hooks/useCauzeRoutingContext';
import ElementsWrapper from 'components/ElementsWrapper';

const helmetContext = {};

@inject('authStore', 'profileStore', 'uiStore')
@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appReady: false,
      isFetchingToken: false,
    };

    this.initializeReactGA();
    this.initializeBranch();
    this.initializeHotJar();
    this.initializeMixPanel();
  }

  componentDidMount() {
    this.handleAppInit();
  }

  initializeMixPanel() {
    if (global.MIXPANEL_PROJECT_TOKEN) {
      mixpanel.init(global.MIXPANEL_PROJECT_TOKEN, {
        debug: global.IS_LOCAL_OR_DEV,
      });
    } else {
      console.warn('Mixpanel key not provided.');
    }
  }

  initializeReactGA() {
    ReactGA.initialize(global.GA_KEY, {
      // debug: global.IS_LOCAL_OR_DEV,
      titleCase: true,
    });
    ReactGA.pageview('/');
  }

  initializeHotJar() {
    hotjar.initialize(global.HOTJAR_ID, global.HOTJAR_SNIPPET_VERSION);
  }

  async initializeBranch() {
    try {
      branch.init(global.BRANCH_KEY, (error, _data) => {
        if (error) throw error;
        // this.props.uiStore.setHasApp(data.has_app);
      });
    } catch (err) {
      console.log(err);
    }
  }

  handleAppInit = async () => {
    await this.props.authStore.onAppLoadFetch();
    await this.props.profileStore.getProfile();
    if (this.props.profileStore.data) {
      mixpanel.identify(this.props.profileStore.data.email.toLowerCase());
    }

    await this.props.uiStore.loadUiState();

    this.setState({ appReady: true });
  };

  render() {
    if (this.state.appReady && !this.props.authStore.isFetchingToken) {
      return (
        <HelmetProvider context={helmetContext}>
          <ElementsWrapper>
            <ModalNotificationProvider>
              <CauzeRoutingProvider uiStore={this.props.uiStore}>
                <CauzeRouter />
              </CauzeRoutingProvider>
            </ModalNotificationProvider>
          </ElementsWrapper>
        </HelmetProvider>
      );
    }
    return <CauzeSpinner fullscreen appSpinner />;
  }
}

export default App;
