import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import useKeyboardEvent from '../../hooks/useKeyboardEvent.js';

// refer to https://bulma.io/documentation/components/dropdown/

const ActionMenu = ({
  actions,
  className,
  _style,
  isActive,
  onToggleOpen,
  onToggleClose,
  isSmaller = false,
}) => {
  const ref = useRef(null);

  useKeyboardEvent('Escape', () => {
    onToggleClose();
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.closest('.dropdown-menu')
      ) {
        onToggleClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onToggleClose, ref]);

  return (
    <div
      className={classnames(
        'dropdown is-right',
        { 'is-active': isActive },
        className,
      )}
    >
      <div className="dropdown-trigger">
        <button
          onClick={onToggleOpen}
          className={classnames(
            ' cursor-pointer rounded-lg border-none text-gray-949',
            isSmaller ? 'pt-1.5 px-1.5 pb-1' : 'pt-2 px-2 pb-1.5',
          )}
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <span
            className={classnames('icon', {
              'is-smaller': isSmaller,
              'is-small': !isSmaller,
            })}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div ref={ref} className="dropdown-content">
          {actions.map((action, index) => (
            <div
              key={`${action.name}-${index}`}
              onClick={(ev) => {
                ev.preventDefault();
                action.action(ev);
              }}
              className="dropdown-item action-menu-item"
            >
              {action.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActionMenu;
